import { TProcessMaturityPayload, TProcessMaturityTable } from "../../models/ProcessMaturity.model";
import { TFetchSubSectionPayload, TSubSectionResponse } from "./LandingPageState";

export const REQUEST_PROCESS_MATURITY = 'REQUEST_PROCESS_MATURITY';
export const PROCESS_MATURITY_SUCCESS = 'PROCESS_MATURITY_SUCCESS';
export const PROCESS_MATURITY_FAILURE = 'PROCESS_MATURITY_FAILURE';

export const REQUEST_PROCESS_MATURITY_SUBSECTION = 'REQUEST_PROCESS_MATURITY_SUBSECTION';
export const PROCESS_MATURITY_SUBSECTION_SUCCESS = 'PROCESS_MATURITY_SUBSECTION_SUCCESS';
export const PROCESS_MATURITY_SUBSECTION_FAILURE = 'PROCESS_MATURITY_SUBSECTION_FAILURE';

export type TProcessMaturityRequest = {
    type: 'REQUEST_PROCESS_MATURITY';
    payload: TProcessMaturityPayload
  }

  export type TProcessMaturitySuccess = {
    type: 'PROCESS_MATURITY_SUCCESS';
    payload: TSubSectionResponse<TProcessMaturityTable>[];
  }

  export type TProcessMaturityFailure = {
    type: 'PROCESS_MATURITY_FAILURE';
    payload: any;
  }

export type TProcessMaturitySubSectionRequest = {
    type: 'REQUEST_PROCESS_MATURITY_SUBSECTION';
    payload: TFetchSubSectionPayload;
  }

  export type TProcessMaturitySubSectionSuccess = {
    type: 'PROCESS_MATURITY_SUBSECTION_SUCCESS';
    payload: TSubSectionResponse<TProcessMaturityTable>;
  }

  export type TProcessMaturitySubSectionFailure = {
    type: 'PROCESS_MATURITY_SUBSECTION_FAILURE';
    payload: any;
  }

  
  export type TProcessMaturityActionTypes = TProcessMaturityRequest | TProcessMaturitySuccess | TProcessMaturityFailure | TProcessMaturitySubSectionRequest | TProcessMaturitySubSectionSuccess | TProcessMaturitySubSectionFailure;