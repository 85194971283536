import { AxiosError } from 'axios';
import {
  GET_PROCESS_SIMPLIFICATION_DETAILS,
  GET_PROCESS_SIMPLIFICATION_DETAILS_ERROR,
  GET_PROCESS_SIMPLIFICATION_DETAILS_SUCCESS,
  RESET_PROCESS_SIMPLIFICATION_DATA,
  TProcessSimpDetailsError,
  TProcessSimpDetailsRequest,
  TProcessSimpDetailsSuccess,
  TResetProcessSimpDetails,
  TSetProcessSimpEditedData,
  TSetProcessSimpExistingData,
} from '../DmapPhase0State/ProcessSimplification.state';
import { TSubSectionResponse } from '../DmapPhase0State/LandingPageState';
import { TProcessSimpDetailsPayload } from '../../models/ProcessSimplification.model';
import { TSubsectionPromptResult } from '../../models/ChatPrompt.model';

export const fetchProcessSimplificationDetails = (payload: TProcessSimpDetailsPayload): TProcessSimpDetailsRequest => ({
  type: GET_PROCESS_SIMPLIFICATION_DETAILS,
  payload,
});

export const fetchProcessSimpDetailsSuccess = (response: TSubSectionResponse<string>[]): TProcessSimpDetailsSuccess => ({
  type: GET_PROCESS_SIMPLIFICATION_DETAILS_SUCCESS,
  payload: response,
});

export const fetchProcessSimplificationDetailsError = (error: AxiosError): TProcessSimpDetailsError => ({
  type: GET_PROCESS_SIMPLIFICATION_DETAILS_ERROR,
  payload: error,
});

export const setProcessSimpExistingData = (payload: TSubsectionPromptResult): TSetProcessSimpExistingData => ({
  type: 'SET_PROCESS_SIMPLIFICATION_EXISTING_DATA',
  payload
})

export const setProcessSimpEditedData = (payload: TSubsectionPromptResult): TSetProcessSimpEditedData => ({
  type: 'SET_PROCESS_SIMPLIFICATION_EDITED_DATA',
  payload
})

export const resetProcessSimpDetails = (): TResetProcessSimpDetails => ({
  type: RESET_PROCESS_SIMPLIFICATION_DATA,
});

