import { TSectionDetails } from "../../models/landingPageModels";
import { TDataState } from "../DmapPhase0State/LandingPageState";
import { FETCH_EXISTING_FILES, FETCH_EXISTING_FILES_FAILURE, FETCH_EXISTING_FILES_SUCCESS, TFileActionTypes, TReviewScreenActionTypes, TReviewScreenState, TSectionsByIdActionTypes } from "../DmapPhase0State/ReviewScreen.state";

const sectionsByIdInitialState: TDataState<TSectionDetails[]> = {
  data: null,
  loading: false,
  error: null,
};


export const sectionsByIdReducer = (state = sectionsByIdInitialState, action: TSectionsByIdActionTypes): TDataState<TSectionDetails[]> => {
  switch (action.type) {
    case 'SECTIONS_BY_ID_REQUEST':
      return { ...state, loading: true, error: null };
    case 'SECTIONS_BY_ID_SUCCESS':
      return { ...state, loading: false, data: action.payload };
    case 'SECTIONS_BY_ID_FAILURE':
      return { ...state, loading: false, error: action.payload };
    case 'RESET_SECTIONS_BY_ID':
      return { ...sectionsByIdInitialState };
    default:
      return state;
  }
};

const existingFilesInitialState: TDataState<TReviewScreenState> = {
    data: null,
    loading: false,
    error: null,
};

export const existingFilesReducer = (
    state = existingFilesInitialState,
    action: TFileActionTypes,
): TDataState<TReviewScreenState> => {
    switch (action.type) {
        case FETCH_EXISTING_FILES:
      return {
        ...state,
        loading: true,
        data: {
            ...(state.data || {}),
            existingFiles: {
                loading: true,
                data: null,
                error: null
            }
        }
      };
    case FETCH_EXISTING_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
            ...(state.data || {}),
            existingFiles: {
                loading: false,
                data: action.payload.files,
                error: null
            }
        }
      };
    case FETCH_EXISTING_FILES_FAILURE:
      return {
        ...state,
        loading: false,
        data: {
            ...(state.data || {}),
            existingFiles: {
                loading: false,
                data: null,
                error: action.payload
            }
        }
      };
        default:
            return { ...state };
    }
};
