import { TKDDGeneratePayload, TKDDSavePayload, TKDDSaveResponse, TKDDTable } from "../../models/KDD.model";
import { GENERATE_KDD_FAILURE, GENERATE_KDD_REQUEST, GENERATE_KDD_SUCCESS, KDD_FAILURE, KDD_SUBSECTION_FAILURE, KDD_SUBSECTION_SUCCESS, KDD_SUCCESS, REQUEST_KDD, REQUEST_KDD_SUBSECTION, SAVE_KDD_FAILURE, SAVE_KDD_REQUEST, SAVE_KDD_SUCCESS, TKDDFailure, TKDDGenerateFailure, TKDDGenerateRequest, TKDDGenerateSuccess, TKDDRequest, TKDDSubSectionFailure, TKDDSubSectionRequest, TKDDSubSectionSuccess, TKDDSuccess, TSaveKDDFailure, TSaveKDDRequest, TSaveKDDSuccess } from "../DmapPhase0State/KDD.state";
import { TFetchSubSectionPayload, TSectionPayload, TSubSectionResponse } from "../DmapPhase0State/LandingPageState";


 // Fetch KDD Data

export const fetchKDD = (payload: TSectionPayload): TKDDRequest => ({
    type: REQUEST_KDD,
    payload
  });
  
  export const fetchKDDSuccess = (payload: TSubSectionResponse<TKDDTable>[]): TKDDSuccess => ({
    type: KDD_SUCCESS,
    payload,
  });
  
  export const fetchKDDError = (error: any): TKDDFailure => ({
    type: KDD_FAILURE,
    payload: error,
  });

  // Fetch KDD Subsection data
  
export const fetchKDDSubSection = (payload: TFetchSubSectionPayload): TKDDSubSectionRequest => ({
    type: REQUEST_KDD_SUBSECTION,
    payload
  });
  
  export const fetchKDDSubSectionSuccess = (payload: TSubSectionResponse<TKDDTable>): TKDDSubSectionSuccess => ({
    type: KDD_SUBSECTION_SUCCESS,
    payload,
  });
  
  export const fetchKDDSubSectionError = (error: any): TKDDSubSectionFailure => ({
    type: KDD_SUBSECTION_FAILURE,
    payload: error,
  });

// Generate KDD Data
export const generateKDDRequest = (payload: TKDDGeneratePayload): TKDDGenerateRequest => ({
  type: GENERATE_KDD_REQUEST,
  payload
}) 

export const generateKDDSuccess = (payload: TSubSectionResponse<TKDDTable>): TKDDGenerateSuccess => ({
  type: GENERATE_KDD_SUCCESS,
  payload
})

export const generateKDDailure = (error: any, subsection: string): TKDDGenerateFailure => ({
  type: GENERATE_KDD_FAILURE,
  payload: {
    error,
    subsection
  }
})

// Save KDD Table
export const saveKDDRequest = (payload: TKDDSavePayload): TSaveKDDRequest => ({
  type: SAVE_KDD_REQUEST,
  payload
})

export const saveKDDSuccess = (payload: TKDDSaveResponse): TSaveKDDSuccess => ({
  type: SAVE_KDD_SUCCESS,
  payload
})

export const saveKDDFailure = (error: any): TSaveKDDFailure => ({
  type: SAVE_KDD_FAILURE,
  payload: error
})