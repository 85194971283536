
  import { AxiosError } from "axios";
import { TPromptResultPayload } from "../../models/ChatPrompt.model";
import { RegeneratePayload, RequestPayload, SaveApiPayload, SaveHeadersPayload } from "../../models/ReviewScreenModels";
import { CreateNewRequestResponse, TProjectType, RequestResponse, UploadFilePayload, RequestDetailsResponse } from "../../models/landingPageModels";
import { ALL_SECTION_DETAILS_FAILURE, ALL_SECTION_DETAILS_REQUEST, ALL_SECTION_DETAILS_SUCCESS, FetchDataFailureAction, FetchDataRequestAction, FetchDataSuccessAction, ProcessRequestGenerateSlideContentRequestAction, PutEditedContentToSaveRequestAction, ResetRequestById, ResetSidenavDataState, TAllSectionDetailsFailure, TAllSectionDetailsPayload, TAllSectionDetailsRequest, TAllSectionDetailsSuccess, TFetchProjectTypesFailure, TFetchProjectTypesRequest, TFetchProjectTypesSuccess, TFetchRequestById, TFetchRequestByIdFailure, TFetchRequestByIdSuccess, TSaveFlagAction, TSetSubsectionDataAction, TTableResponse } from "../DmapPhase0State/LandingPageState";

//for requestTable
  export const fetchAllRequests = (pageNumber?: number, pageSize?: number): FetchDataRequestAction =>
    ({
      type: 'FETCH_ALL_REQUESTS',
      payload: {pageNumber, pageSize}
    });

  export const fetchDataSuccess = (data: TTableResponse<RequestDetailsResponse>): FetchDataSuccessAction =>
    ({
      type: 'FETCH_REQUESTS_SUCCESS',
      payload: data,
    });

  export const fetchDataFailure = (error: any): FetchDataFailureAction =>
    ({
      type: 'FETCH_REQUESTS_FAILURE',
      payload: error,
} as const);
    
export const CREATE_NEW_REQUEST = 'CREATE_REQUEST_REQUEST';
export const CREATE_REQUEST_SUCCESS = 'CREATE_REQUEST_SUCCESS';
export const CREATE_REQUEST_FAILURE = 'CREATE_REQUEST_FAILURE';

export const createNewRequest = (
  requestName: string,
  projectTypeId: number,
  sections: string,
  uploadPayload: UploadFilePayload,
  operation: string,
) =>
  ({
    type: CREATE_NEW_REQUEST,
    payload: { requestName, projectTypeId, sections, uploadPayload, operation },
  } as const);

export const createRequestSuccess = (data: CreateNewRequestResponse) =>
  ({
    type: CREATE_REQUEST_SUCCESS,
    payload: data,
  } as const);

export const createRequestFailure = (error: any) =>
  ({
    type: CREATE_REQUEST_FAILURE,
    payload: error,
} as const);
  
export const UPLOAD_NEW_FILE_REQUEST = 'UPLOAD_NEW_FILE_REQUEST';
export const UPLOAD_NEW_FILE_SUCCESS = 'UPLOAD_NEW_FILE_SUCCESS';
export const UPLOAD_NEW_FILE_FAILURE = 'UPLOAD_NEW_FILE_FAILURE';
export const RESET_UPLOAD_NEW_FILE = 'RESET_UPLOAD_NEW_FILE';

export const uploadFileRequest = (uploadPayload: { files: File[]; prefixes: string[],requestId:string }) =>
  ({
    type: UPLOAD_NEW_FILE_REQUEST,
    payload: uploadPayload,
} as const);
  
export const uploadFileSuccess = (data: CreateNewRequestResponse) =>
  ({
    type: UPLOAD_NEW_FILE_SUCCESS,
    payload: data,
  } as const);

export const uploadFileFailure = (error: any) =>
  ({
    type: UPLOAD_NEW_FILE_FAILURE,
    payload: error,
} as const);

  export const uploadFileReset = () =>
    ({
      type: RESET_UPLOAD_NEW_FILE,
    } as const);
  

export const PROCESS_GENERATE_SLIDE_CONTENT_REQUEST = 'PROCESS_GENERATE_SLIDE_CONTENT_REQUEST';
export const PROCESS_GENERATE_SLIDE_CONTENT_SUCCESS = 'PROCESS_GENERATE_SLIDE_CONTENT_SUCCESS';
export const PROCESS_GENERATE_SLIDE_CONTENT_FAILURE = 'PROCESS_GENERATE_SLIDE_CONTENT_FAILURE';

export const generateSlideContentRequest = (
  requestId: number,
  filePrefixes: string,
  operation: string,
  files: File[],
  sections: string[]
): ProcessRequestGenerateSlideContentRequestAction =>
  ({
    type: PROCESS_GENERATE_SLIDE_CONTENT_REQUEST,
    payload: { requestId, filePrefixes, operation, files, sections },
  } as const);

export const generateSlideContentSuccess = (data: CreateNewRequestResponse) =>
  ({
    type: PROCESS_GENERATE_SLIDE_CONTENT_SUCCESS,
    payload: data,
  } as const);

export const generateSlideContentFailure = (error: any) =>
  ({
    type: PROCESS_GENERATE_SLIDE_CONTENT_FAILURE,
    payload: error,
} as const);

// fetch all section details
export const fetchAllSectionDetailsRequest = (payload: TAllSectionDetailsPayload): TAllSectionDetailsRequest =>
  ({
    type: ALL_SECTION_DETAILS_REQUEST,
    payload
  });

export const fetchAllSectionDetailsSuccess = (data: any): TAllSectionDetailsSuccess =>
  ({
    type: ALL_SECTION_DETAILS_SUCCESS,
    payload: data,
  } as const);

export const fetchAllSectionDetailsFailure = (error: AxiosError): TAllSectionDetailsFailure =>
  ({
    type: ALL_SECTION_DETAILS_FAILURE,
    payload: error,
} as const);

// Project Type
export const GET_PROJECT_TYPE_REQUEST = 'GET_PROJECT_TYPE_REQUEST';
export const GET_PROJECT_TYPE_SUCCESS = 'GET_PROJECT_TYPE_SUCCESS';
export const GET_PROJECT_TYPE_FAILURE = 'GET_PROJECT_TYPE_FAILURE';

export const fetchProjectTypeRequest = (): TFetchProjectTypesRequest =>
  ({
    type: GET_PROJECT_TYPE_REQUEST,
  });

export const fetchProjectTypeSuccess = (data: TProjectType[]): TFetchProjectTypesSuccess =>
  ({
    type: GET_PROJECT_TYPE_SUCCESS,
    payload: data,
  });

export const fetchProjectTypeFailure = (error: any): TFetchProjectTypesFailure =>
  ({
    type: GET_PROJECT_TYPE_FAILURE,
    payload: error,
} as const);


// Fetch data by id
export const GET_REQUEST_BY_ID_REQUEST = 'GET_REQUEST_BY_ID_REQUEST';
export const GET_REQUEST_BY_ID_SUCCESS = 'GET_REQUEST_BY_ID_SUCCESS';
export const GET_REQUEST_BY_ID_FAILURE = 'GET_REQUEST_BY_ID_FAILURE';
export const RESET_REQUEST_BY_ID = 'RESET_REQUEST_BY_ID';

export const fetchRequestByIdRequest = (requestId: number): TFetchRequestById =>
  ({
    type: GET_REQUEST_BY_ID_REQUEST,
    payload: { requestId },
  } as const);

export const fetchRequestByIdSuccess = (data: RequestResponse): TFetchRequestByIdSuccess =>
  ({
    type: GET_REQUEST_BY_ID_SUCCESS,
    payload: data,
  } as const);

export const fetchRequestByIdFailure = (error: any): TFetchRequestByIdFailure =>
  ({
    type: GET_REQUEST_BY_ID_FAILURE,
    payload: error,
  } as const);

export const fetchRequestByIdReset = (): ResetRequestById =>
  ({
    type: RESET_REQUEST_BY_ID,
} as const);
  
// boolean value
export const SET_BOOLEAN_VARIABLE = 'SET_BOOLEAN_VARIABLE';

export const setBooleanVariable = (value: boolean) => ({
  type: SET_BOOLEAN_VARIABLE,
  payload: value,
});

// save Variable
export const SET_SAVE_FLAG = 'SET_SAVE_FLAG';

export const updateSaveValue = (section:string, value:boolean): TSaveFlagAction => ({
  type: SET_SAVE_FLAG,
  payload: { section, value },
});

// editor save action

export const PUT_EDITED_CONTENT_TO_SAVE_REQUEST = 'PUT_EDITED_CONTENT_TO_SAVE_REQUEST';
export const PUT_EDITED_CONTENT_TO_SAVE_SUCCESS = 'PUT_EDITED_CONTENT_TO_SAVE_SUCCESS';
export const PUT_EDITED_CONTENT_TO_SAVE_FAILURE = 'PUT_EDITED_CONTENT_TO_SAVE_FAILURE';

// Action creators
export const putEditedContentToSaveRequest = (
  reqId: number,
  selectedItem: string,
  payload: SaveApiPayload[],
): PutEditedContentToSaveRequestAction => ({
  type: PUT_EDITED_CONTENT_TO_SAVE_REQUEST,
  payload: { reqId, selectedItem, payload },
});

export const putEditedContentToSaveSuccess = (data: any) => ({
  type: PUT_EDITED_CONTENT_TO_SAVE_SUCCESS,
  payload: data,
});

export const putEditedContentToSaveFailure = (error: any) => ({
  type: PUT_EDITED_CONTENT_TO_SAVE_FAILURE,
  payload: error,
});


// download generated files

export const FETCH_DOWNLOAD_REQUEST = 'FETCH_DOWNLOAD_REQUEST';
export const FETCH_DOWNLOAD_SUCCESS = 'FETCH_DOWNLOAD_SUCCESS';
export const FETCH_DOWNLOAD_FAILURE = 'FETCH_DOWNLOAD_FAILURE';

export const fetchDownloadRequest = (requestId: string) =>
  ({
    type: FETCH_DOWNLOAD_REQUEST,
    payload: { requestId },
  } as const);

export const fetchDownloadSuccess = (data: any) =>
  ({
    type: FETCH_DOWNLOAD_SUCCESS,
    payload: data,
  } as const);

export const fetchDownloadFailure = (error: any) =>
  ({
    type: FETCH_DOWNLOAD_FAILURE,
    payload: error,
  } as const);

// chat API actions
export const CHAT_PROMPT_REQUEST = 'CHAT_PROMPT_REQUEST';
export const CHAT_PROMPT_SUCCESS = 'CHAT_PROMPT_SUCCESS';
export const CHAT_PROMPT_FAILURE = 'CHAT_PROMPT_FAILURE';
export const CHAT_PROMPT_RESET = 'CHAT_PROMPT_RESET';

interface ChatRequestAction {
  type: typeof CHAT_PROMPT_REQUEST;
  payload: TPromptResultPayload;
}

export const chatPromptRequest = (payload: TPromptResultPayload): ChatRequestAction => ({
  type: CHAT_PROMPT_REQUEST,
  payload 
} as const);

export const chatPromptSuccess = (response: any) =>
  ({
    type: CHAT_PROMPT_SUCCESS,
    payload:response,
  } as const);

export const chatPromptFailure = (error: any) =>
  ({
    type: CHAT_PROMPT_FAILURE,
    payload :error,
} as const);

export const chatPromptReset = () =>
  ({
    type: CHAT_PROMPT_RESET,
  } as const);
  

//PUT Process API  Action

export const PUT_PROCESS_GENERATE_SLIDE_CONTENT_REQUEST = 'PUT_PROCESS_GENERATE_SLIDE_CONTENT_REQUEST';
export const PUT_PROCESS_GENERATE_SLIDE_CONTENT_SUCCESS = 'PUT_PROCESS_GENERATE_SLIDE_CONTENT_SUCCESS';
export const PUT_PROCESS_GENERATE_SLIDE_CONTENT_FAILURE = 'PUT_PROCESS_GENERATE_SLIDE_CONTENT_FAILURE';


export const putGenerateSlideContentRequest = (
payload:RequestPayload
) =>
  ({
    type: PUT_PROCESS_GENERATE_SLIDE_CONTENT_REQUEST,
    payload: payload,
  } as const);

export const putGenerateSlideContentSuccess = (data: CreateNewRequestResponse) =>
  ({
    type: PUT_PROCESS_GENERATE_SLIDE_CONTENT_SUCCESS,
    payload: data,
  } as const);

export const putGenerateSlideContentFailure = (error: any) =>
  ({
    type: PUT_PROCESS_GENERATE_SLIDE_CONTENT_FAILURE,
    payload: error,
} as const);
  

//Regenerate header API

export const MODIFY_HEADER_REQUEST = 'MODIFY_HEADER_REQUEST';
export const MODIFY_HEADER_SUCCESS = 'MODIFY_HEADER_SUCCESS';
export const MODIFY_HEADER_FAILURE = 'MODIFY_HEADER_FAILURE';
export const RESET_HEADER_RESPONSE = 'RESET_HEADER_RESPONSE';

export const modifyHeaderRequest = (payload: RegeneratePayload) =>
  ({
    type: MODIFY_HEADER_REQUEST,
    payload,
  } as const);

export const modifyHeaderSuccess = (response: any) =>
  ({
    type: MODIFY_HEADER_SUCCESS,
    payload: response,
  } as const);

export const modifyHeaderFailure = (error: any) =>
  ({
    type: MODIFY_HEADER_FAILURE,
    payload: error,
} as const);

  export const modifyHeaderReset = () =>
    ({
      type: RESET_HEADER_RESPONSE,
    } as const);
  
//save regenerate headers
export const SAVE_REGENERATE_HEADER_REQUEST = 'SAVE_REGENERATE_HEADER_REQUEST';
export const SAVE_REGENERATE_HEADER_SUCCESS = 'SAVE_REGENERATE_HEADER_SUCCESS';
export const SAVE_REGENERATE_HEADER_FAILURE = 'SAVE_REGENERATE_HEADER_FAILURE';

// Action creators
export const saveHeadersRequest = (payload: SaveHeadersPayload) => ({
  type: SAVE_REGENERATE_HEADER_REQUEST,
  payload,
});

export const saveHeadersSuccess = (data: any) => ({
  type: SAVE_REGENERATE_HEADER_SUCCESS,
  payload: data,
});

export const saveHeadersFailure = (error: any) => ({
  type: SAVE_REGENERATE_HEADER_FAILURE,
  payload: error,
});