import { TDataState, TSubSectionResponse } from '../DmapPhase0State/LandingPageState';
import { TKDDState, TKDDTable } from '../../models/KDD.model';
import { GENERATE_KDD_FAILURE, GENERATE_KDD_REQUEST, GENERATE_KDD_SUCCESS, KDD_FAILURE, KDD_SUBSECTION_FAILURE, KDD_SUBSECTION_SUCCESS, KDD_SUCCESS, REQUEST_KDD, REQUEST_KDD_SUBSECTION, SAVE_KDD_FAILURE, SAVE_KDD_REQUEST, SAVE_KDD_SUCCESS, TKDDActionTypes } from '../DmapPhase0State/KDD.state';

const initialState: TKDDState = {
    subsections: null,
    save: null
};

export const kddReducer = (
    state = initialState,
    action: TKDDActionTypes,
): TKDDState => {
    switch (action.type) {
    // KDD Data
    case REQUEST_KDD:
      return {
        ...state,
        subsections: {
          data: null,
          loading: true,
          error: null
        }
      };
    case KDD_SUCCESS:
      return {
        ...state,
        subsections: {
          data: action.payload,
          loading: false,
          error: null
        }
      };
    case KDD_FAILURE:
      return {
        ...state,
        subsections: {
          data: null,
          loading: false,
          error: action.payload
        }
      };
      // KDD Subsection
    case REQUEST_KDD_SUBSECTION:
      return {
        ...state,
        subsections: {
          data: state.subsections?.data?.filter((subsection: TSubSectionResponse<TKDDTable>) => subsection.subsection !== action.payload.body?.subsection) || [],
          loading: true,
          error: null
        }
      };
    case KDD_SUBSECTION_SUCCESS:
      const { subsection } = action.payload

      return {
        ...state,
        subsections: {
          data: [
            ...(state.subsections?.data?.filter((val: TSubSectionResponse<TKDDTable>) => val.subsection !== subsection) || []),
            action.payload
        ],
          loading: false,
          error: null
        }
      };
    case KDD_SUBSECTION_FAILURE:
      return {
        ...state,
        subsections: {
          data: null,
          loading: false,
          error: action.payload
        }
      };

    // KDD Generate Data
    case GENERATE_KDD_REQUEST:
      return {
        ...state,
        subsections: {
          data: state.subsections?.data || [],
          loading: true,
          error: null
        }
      }
    case GENERATE_KDD_SUCCESS:
      const subsectionName  = action.payload.subsection

      return {
        ...state,
        subsections: {
          data: [
            ...(state.subsections?.data?.filter((val: TSubSectionResponse<TKDDTable>) => val.subsection !== subsectionName) || []),
            action.payload
        ],
          loading: false,
          error: null
        }
      };
    case GENERATE_KDD_FAILURE:
      const {error, subsection: genSubsection} = action.payload
      const subsectionData: TSubSectionResponse<TKDDTable> | undefined =  state.subsections?.data?.find((val: TSubSectionResponse<TKDDTable>) => val.subsection === genSubsection)
      if(subsectionData && subsectionData.data.table?.content){
        subsectionData.data.table.content = []
      }
      return {
        ...state,
        subsections: {
          data: [
            ...(state.subsections?.data?.filter((val: TSubSectionResponse<TKDDTable>) => val.subsection !== genSubsection) || []),
            ...(subsectionData ? [subsectionData] : [])
        ],
          loading: false,
          error
        }
      }

    // Save KDD Data
    case SAVE_KDD_REQUEST:
      return {
        ...state,
        save: {
          data: null,
          loading: true,
          error: null
        }
      }
    case SAVE_KDD_SUCCESS:
      return {
        ...state,
        save: {
          data: action.payload,
          loading: false,
          error: null
        }
      }
    case SAVE_KDD_FAILURE:
      return {
        ...state,
        save: {
          data: null,
          loading: false,
          error: action.payload
        }
      }
        default:
            return { ...state };
    }
};
