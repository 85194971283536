import React, { ReactElement, useEffect } from 'react';
import Questionnaire from './Questionnaire/Questionnaire';
import PainPoints from './PainPoints/PainPoints';
import HanaChanges from './HanaChanges/HanaChanges';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { fetchWorkshopEnablers } from '../../../../libs/store/DmapPhase0Actions/WorkshopEnablers.actions';
import { useParams } from 'react-router-dom';
import { TDataState, TRootState, TSectionPayload, TSubSectionResponse } from '../../../../libs/store/DmapPhase0State/LandingPageState';
import { TWorkshopEnablersTableCols } from '../../../../libs/store/DmapPhase0State/WorkshopEnablers.state';
import SelectSubsection from '../../../../Components/SelectSubsection/SelectSubsection';
import { TSectionDetails } from '../../../../libs/models/landingPageModels';
import { SECTION_CONSTANT_MAPPING, SUB_SECTION_MAPPING } from '../../../../constants';

interface Props {
  selectedItem: string;
  onSelectedMenuItem: string;
  requestName: string;

  sectionDetails?: TSectionDetails | null;
  workshopEnablersData?: TDataState<TSubSectionResponse<TWorkshopEnablersTableCols>[]>;

  fetchWorkshopData?: (payload: TSectionPayload) => any;
}

const WorkshopEnablers: React.FC<Props> = ({
  selectedItem,
  onSelectedMenuItem,
  requestName,
  fetchWorkshopData,
  sectionDetails,
  workshopEnablersData,
}) => {
  const { id }: { id: string } = useParams();

  useEffect(() => {
    if (id) {
      fetchWorkshopData && fetchWorkshopData({
        reqId: id,
        selectedSection: selectedItem,
        pageSize: 5
      });
    }
  }, []);

  const renderSelectedMenuItem = (): ReactElement => {
    switch (onSelectedMenuItem) {
      case SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.WORKSHOP_ENABLER].QUESTIONNAIRE:
        return <Questionnaire />;
      case SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.WORKSHOP_ENABLER].PAIN_POINTS:
        return (
          <PainPoints
            selectedSubsection={onSelectedMenuItem}
          />
        );
      case SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.WORKSHOP_ENABLER].HANA_CHANGES:
        return <HanaChanges />;
      default:
        return <></>;
    }
  };

  return sectionDetails?.subsections?.length && onSelectedMenuItem ? (
    selectedItem === SECTION_CONSTANT_MAPPING.WORKSHOP_ENABLER ? (
      renderSelectedMenuItem()
    ) : (
      <></>
    )
  ) : (
    <SelectSubsection />
  );
};

const mapStateToProps = (state: TRootState, {selectedItem}: Props) => ({
  workshopEnablersData: state.workshopEnablers,
  sectionDetails: state.sectionsById?.data?.find((section: TSectionDetails) => section.sectionConstant === selectedItem)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchWorkshopData: (payload: TSectionPayload) => dispatch(fetchWorkshopEnablers(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopEnablers);
