import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  fetchArchitectureDetails,
  fetchArchitectureImage,
  fetchArchitectureSubSection,
  resetArchitectureSubSection,
} from '../../../../../libs/store/DmapPhase0Actions/Architecture.actions';
import { useParams } from 'react-router-dom';
import {
  TArchImgPayload,
  TArchImgResponse,
  TArchTableData,
  TResetArchSubsectionPayload,
} from '../../../../../libs/models/Architecture.model';
import { Dispatch } from 'redux';
import { Image, Spin, TablePaginationConfig } from 'antd';
import './AsIsArchitecture.less';
import {
  TDataState,
  TFetchSubSectionPayload,
  TRootState,
  TSectionPayload,
  TSubSectionDataResponse,
  TSubSectionResponse,
} from '../../../../../libs/store/DmapPhase0State/LandingPageState';
import CustomTable from '../../../../../Components/DynamicForm/components/CustomTable/CustomTable';
import { FilterValue } from 'antd/lib/table/interface';
import { DEFAULT_PAGE_SIZE } from '../../../../../constants';
import { hasPagination } from '../../../../../utils/ReviewScreen.util';
import { TArchitectureSubSectionRequest, TgetArchDetails, TgetArchImage, TResetArchSubsection } from '../../../../../libs/store/DmapPhase0State/Architecture.state';

interface Props {
  selectedSection: string;
  selectedSubsection: string;

  response?: TDataState<TSubSectionResponse<TArchTableData>[]> | null;
  data?: TSubSectionDataResponse<TArchTableData> | null;
  image?: TDataState<TArchImgResponse> | null

  fetchDetails?: (payload: TSectionPayload) => TgetArchDetails;
  fetchImage?: (payload: TArchImgPayload) => TgetArchImage;
  fetchSubSection?: (payload: TFetchSubSectionPayload) => TArchitectureSubSectionRequest;
  resetArchitecture?:  (payload: TResetArchSubsectionPayload) => TResetArchSubsection;
}

const AsIsArchitecture: React.FC<Props> = ({
  selectedSection,
  selectedSubsection,

  response,
  data,
  image,

  fetchDetails,
  fetchImage,
  fetchSubSection,
  resetArchitecture
}) => {
  const { id }: { id: string } = useParams();

  useEffect(() => {
    if (id) {
      fetchDetails &&
        fetchDetails({ reqId: id, pageSize: 5, selectedSection: selectedSection, subsection: selectedSubsection });
      fetchImage && fetchImage({
        requestId: id,
        subsection: selectedSubsection,
        sectionType: selectedSection
      });
    }

    // reset data on unmount
    return () => {
      resetArchitecture && resetArchitecture({subsection: selectedSubsection})
    }
  }, []);

  const onChange = (
    paginationConfig: TablePaginationConfig,
    selectedFilters: Record<string, FilterValue | null>,
    action: 'paginate' | 'sort' | 'filter',
  ) => {
    fetchSubSection &&
      fetchSubSection({
        requestId: id,
        body: {
          selectedFilters: Object.keys(selectedFilters)
            .filter((k) => selectedFilters[k] != null)
            .reduce((a, k) => ({ ...a, [k]: selectedFilters[k] }), {}),
          page: paginationConfig.current || -1,
          pageSize: paginationConfig.pageSize || DEFAULT_PAGE_SIZE,
          section: selectedSection,
          subsection: selectedSubsection,
        },
      });
  };

  return (
    <div className="arch-container">
      {image?.loading ? (
        <Spin className="arch-img arch-spinner" />
      ) : (
        image?.data?.url?.length ? <Image alt='' className='arch-img' src={image.data?.url} loading='eager' preview={false} /> : <></>
      )}
      {data ? (
        <CustomTable
          loading={response?.loading || false}
          data={data?.table?.content || []}
          tableName={selectedSubsection}
          filterCols={data?.table?.filters || {}}
          pageSize={data?.table?.pageSize || DEFAULT_PAGE_SIZE}
          totalCount={data?.table?.totalCount}
          onChange={onChange}
          colNames={data?.table?.colNames || []}
          pagination={true}
          pageNumber={data?.table?.pageNumber}
          scrollProps={{x: 'none', y: `calc(100vh - ${hasPagination(data?.table?.totalCount, data?.table?.pageSize) ? '20.765rem' : '17.165rem'})`}}
        />
      ) : (
        <Spin style={{ marginLeft: '50%', marginTop: '10rem' }} />
      )}
    </div>
  );
};

const mapStateToProps = (state: TRootState, { selectedSubsection }: Props) => {
  return {
    response: state.architecture.asIsArchitecture?.response,
    data:  state.architecture.asIsArchitecture?.response?.data?.find((subsection: TSubSectionResponse<TArchTableData>) => subsection.subsection === selectedSubsection)?.data,
    image: state.architecture.asIsArchitecture?.image
  };
};

const mapDispatchToProps = (dispatch: Dispatch, props: Props) => ({
  fetchDetails: (payload: TSectionPayload) => dispatch(fetchArchitectureDetails(payload)),
  fetchImage: (payload: TArchImgPayload) => dispatch(fetchArchitectureImage(payload)),
  fetchSubSection: (payload: TFetchSubSectionPayload) =>
    dispatch(fetchArchitectureSubSection(payload)),
  resetArchitecture:  (payload: TResetArchSubsectionPayload) => dispatch(resetArchitectureSubSection(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(AsIsArchitecture);
