import React, { useState, useEffect } from 'react';
import { Layout, Col, Button, Collapse, Menu, Dropdown, Spin } from 'antd';
import './Reviewsidenav.less';
import GenerateSection from '../GenerateSection';
import { useSelector } from 'react-redux';
import type { MenuProps } from 'antd';
import Tooltip from 'antd/es/tooltip';
import {
  CaretRightOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MoreOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import Sider from 'antd/lib/layout/Sider';
import RevertChanges from '../RevertChanges';
import RegenerateSection from '../RegenerateSection';
import { constMenuItems } from '../../../assets/Mocks';
import { TSectionDetails, TSubsectionDetails } from '../../../libs/models/landingPageModels';
import { TRootState } from '../../../libs/store/DmapPhase0State/LandingPageState';


const { Header } = Layout;
const { Panel } = Collapse;

interface ReviewSideNavProps {
  requestId: string;
  onRequestSelect: any;
  onSubsectionSelect: any;
  requestheader: any;
  selectedItem: string | null;
  selectedSubsection: string | null;
  collapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const ReviewSideNav: React.FC<ReviewSideNavProps> = ({
  requestId,
  onRequestSelect,
  onSubsectionSelect,
  requestheader,
  selectedItem,
  selectedSubsection,
  collapsed,
  setCollapsed
}) => {
  const [sectionsData, setSectionsData] = useState<TSectionDetails[]>([]);
  const [genSectionOpen, setGenSectionOpen] = useState<boolean>(false);
  const [regenSectionOpen, setRegenSectionOpen] = useState<boolean>(false);
  const [revertSectionOpen, setRevertSectionOpen] = useState<boolean>(false);
  const [menuProps, setmenuProps] = useState<MenuProps>({});
  const { data } = useSelector((state: TRootState) => state.sectionsById);

  useEffect(() => {
    if (selectedItem==='Project Plan' && selectedSubsection === 'Project Plan Estimates') {  
      setmenuProps({ selectedKeys: ['1'] });
    } else if (
      selectedItem === 'Project Plan' &&
      selectedSubsection === 'Estimator'
    ) {
      setmenuProps({ selectedKeys: ['0'] });
    }
  }, [selectedItem, selectedSubsection]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  };

  const handleSectionClick = (index: string | string[] | undefined) => {
    const section = sectionsData[parseInt(typeof index === "string" ? index : "") - 1]
    onRequestSelect(section?.sectionConstant);
    requestheader(section?.section);
  };
  const handleMenuItemClick = (key: string | null) => {
    onSubsectionSelect(key);
  };

  const toggleModal = (key: string, open: boolean) => {
    switch (key) {
      case 'GS':
        setGenSectionOpen(open);
        break;
      case 'RS':
        setRegenSectionOpen(open);
        break;
      case 'RVS':
        setRevertSectionOpen(open);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setSectionsData(data ?? []);
  }, [data]);

  const renderMenuItem = (text: string | null) => {
    return (
      <div style={{ flex: 'auto', minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {text}
      </div>
    );
  };

  const renderSubMenu = (subSections: TSubsectionDetails[]) => {
    if (!subSections || subSections.length === 0) {
      return null;
    }

    return (
      <Menu {...menuProps}>
        {subSections.map((subSect, index) => {
          return (
            <Menu.Item
              style={!collapsed ? { whiteSpace: 'normal', height: 'auto' } : {}}
              className={`submenu-item ${
                selectedSubsection === subSect.subSection ? 'selected-item' : 'not-selected-item'
              }`}

              onClick={() => handleMenuItemClick(subSect.subSection)}
              key={index}
              
              disabled={!JSON.parse(subSect?.isGenerated)}
            >
              <Tooltip title={`${subSect.subSection}`}>
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {renderMenuItem(subSect.subSection)}
                </div>
              </Tooltip>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const onClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case '1':
        toggleModal('GS', true);
        break;
      case '2':
        toggleModal('RS', true);
        break;
      case '3':
        toggleModal('RVS', true);
        break;
      default:
        break;
    }
  };

  const items: MenuProps['items'] = [
    {
      label: 'Generate Content',
      key: '1',
    },
    {
      label: 'Regenerate Subsections',
      key: '2',
    },
    {
      label: 'Revert Sections',
      key: '3',
      disabled: true,
    },
  ];

  return (
    <div className="review-sidenav">
      <Header className="header-container-sidenav">
        <div className="sider-header-title">Sections</div>
        <div className={collapsed ? 'header-actions' : 'header-actions-collapsed'}>
          <Button
            className="header-button"
            onClick={() => {
              toggleModal('GS', true);
            }}
            icon={<PlusOutlined />}
          />
          <Dropdown menu={{ items, onClick }} trigger={['click']} placement="bottomRight">
            <Button
              className="header-button"
              icon={<MoreOutlined />}
              onClick={(e) => {
                e.preventDefault();
              }}
            />
          </Dropdown>
        </div>
        <GenerateSection
          requestId={requestId}
          visible={genSectionOpen}
          onCancel={() => {
            toggleModal('GS', false);
          }}
        />
        <RegenerateSection
          requestId={requestId}
          visible={regenSectionOpen}
          onCancel={() => {
            toggleModal('RS', false);
          }}
        />
        <RevertChanges
          requestId={requestId}
          visible={revertSectionOpen}
          onCancel={() => {
            toggleModal('RVS', false);
          }}
        />
      </Header>
      <br />

      <div className="menu-container">
        <Col span={24}>
          <Spin spinning={!sectionsData}>
            <Sider
              collapsible
              collapsed={collapsed}
              trigger={null}
              collapsedWidth={300}
              width={490}
              onCollapse={toggleCollapsed}
            >
              <Collapse onChange={handleSectionClick}
               accordion 
               expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
               expandIconPosition="end">
                {sectionsData.map((section, index) => (
                  <React.Fragment key={`panel-${index + 1}`}>
                      {section.generated ? (
                      <Panel
                        className="section-item"
                        key={`${index + 1}`}
                        header={
                          <div>{section.section}</div>
                        }
                        showArrow={section.subsections ? true : false}
                      >
                        {renderSubMenu(section.subsections ?? [])}
                      </Panel>
                    ) : (
                      <Panel
                        className="section-item"
                        key={`panel-${index + 1}`}
                        header={<div className="panel-nonclickable">{section.section}</div>}
                        showArrow={section.subsections ? true : false}
                      >
                        {renderSubMenu(section.subsections ?? [])}
                      </Panel>
                    )}
                  </React.Fragment>
                ))}
              </Collapse>
            </Sider>
          </Spin>
        </Col>
      </div>
      <Button
        className={collapsed ? 'collapse-button' : 'collapse-button-expand'}
        type="text"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={toggleCollapsed}
        style={{
          fontSize: '1rem',
          width: 64,
          height: 64,
        }}
      />
    </div>
  );
};

export default ReviewSideNav;
