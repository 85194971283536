import { TKDDSavePayload, TKDDTable } from "../libs/models/KDD.model";
import { TFetchSubSectionPayload } from "../libs/store/DmapPhase0State/LandingPageState";

export const getPayloadForFetchingSubSection = (requestId: string, page: number, pageSize: number, section: string, subsection: string, search: string, selectedFilters: Record<string, string[]>): TFetchSubSectionPayload => ({
    requestId,
    body: {
        page,
        pageSize,
        section,
        subsection,
        search,
        selectedFilters
    }
})

export const getPayloadForSaveKDD = (section: string, subsection: string, request_id: number, tableData: Record<number, Record<string, string>[]>): TKDDSavePayload => {
    let table = (Object.values(tableData || {})?.flat(1) || []) as unknown as TKDDTable[];
    table = table?.map((record: TKDDTable) => {
        const { key, ...rest} = record;
        return rest
    })
    return {
        section,
        subsection,
        request_id,
        table
    }
}