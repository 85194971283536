import { TProcessSimplificationState } from '../../models/ProcessSimplification.model';
import { TSubSectionResponse } from '../DmapPhase0State/LandingPageState';
import {
  GET_PROCESS_SIMPLIFICATION_DETAILS,
  GET_PROCESS_SIMPLIFICATION_DETAILS_SUCCESS,
  GET_PROCESS_SIMPLIFICATION_DETAILS_ERROR,
  ProcessSimplificationActionTypes,
  RESET_PROCESS_SIMPLIFICATION_DATA,
} from '../DmapPhase0State/ProcessSimplification.state';

const initialState: TProcessSimplificationState = {
  existingData: null,
  editedData: null
};

export const processSimplificationReducer = (
  state = initialState,
  action: ProcessSimplificationActionTypes,
): TProcessSimplificationState => {
  switch (action.type) {
    case GET_PROCESS_SIMPLIFICATION_DETAILS:
      return {
        ...state,
        existingData: {
          data: null,
          loading: true,
          error: null,
        }
      };
    case GET_PROCESS_SIMPLIFICATION_DETAILS_SUCCESS:
      const response: TSubSectionResponse<string>[] = []
      action.payload?.forEach((details: TSubSectionResponse<string>) => {
        let newData = details;
        newData.data.response = newData.data.response?.replace(/\n|\r/g, '') || ''
        response.push(newData);
      });
      return {
        ...state,
        existingData: {
          data: response,
          loading: false,
          error: null
        }
      };
    case GET_PROCESS_SIMPLIFICATION_DETAILS_ERROR:
      return {
        ...state,
        existingData: {
          data: null,
          loading: false,
          error: action.payload
        }
      };
    case 'SET_PROCESS_SIMPLIFICATION_EDITED_DATA':
      const {subsection, data} = action.payload
      let newEditedObj = state.editedData || {};
      if(!data || data?.length === 0){
        delete newEditedObj[subsection];
      }
      else{
        newEditedObj = {
          ...(state.editedData || {}),
          [subsection]: data
        }
      }
      return {
        ...state,
        editedData: newEditedObj
      }
    case 'SET_PROCESS_SIMPLIFICATION_EXISTING_DATA':
      const {subsection: selectedSubsection, data: formattedData} = action.payload ;
      return {
        ...state,
        existingData: {
          ...(state.existingData || {}),
          loading: false,
          error: null,
          data: [
            ...(state.existingData?.data?.filter((details: TSubSectionResponse<string>) => details.subsection !== selectedSubsection) || []),
            {
              subsection: selectedSubsection,
              data: {
                response: formattedData,
                highlights: '',
                table: null
              }
            }
          ]
        }
      }
    case RESET_PROCESS_SIMPLIFICATION_DATA:
      return initialState;
    default:
      return state;
  }
};
