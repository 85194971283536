import React from 'react';
import './SideNavBar.less'; 
import { CloudOutlined, FileOutlined, LinkOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';

const SideNavBar = () => {
  return (
    <div className="sider">
      <div className="first-items">
        <div className="item">
          <CloudOutlined style={{ color: '#60D2A2' ,marginLeft:'0.4375rem' ,fontSize: '1rem' }} />
          <p className="label"> Deloitte</p>
        </div>
        <div className="item">
          <FileOutlined style={{ color: 'white', backgroundColor: '#60D2A2', borderRadius: '0 0.625rem 0.625rem 0', padding: '1rem 1.3rem' , marginLeft:'-0.625rem', fontSize: '1rem'  }} />
          <span className="label"></span>
        </div>
      </div>
      <div className="last-items">
        <div className="item">
          <LinkOutlined /> 
        </div>
        <div className="item">
          <TeamOutlined /> 
        </div>
        <div className="item">
          <UserOutlined /> 
        </div>
      </div>
    </div>
  );
}

export default SideNavBar;
