import { AxiosError } from "axios";
import { put, call } from 'redux-saga/effects';
import { TExecutiveSummaryResponse } from "../../models/ExecutiveSummary.model";
import { fetchSectionDetails } from "../../services/DmapPhase0Services/ReviewPageService";
import { fetchExecutiveSummarySuccess, fetchExecutiveSummaryError } from "../DmapPhase0Actions/ExecutiveSummary.actions";
import { TExecutiveSummaryRequest } from "../DmapPhase0State/ExecutiveSummary.state";

/**
 * Saga to fetch ExecutiveSummary data section
 * @param action 
 */
export function* fetchExecutiveSummarySaga(action: TExecutiveSummaryRequest) {
  try {
    const response: TExecutiveSummaryResponse = yield call(fetchSectionDetails<TExecutiveSummaryResponse>, action.payload.reqId, action.payload.itemConstant, action.payload.pageSize);
    yield put(fetchExecutiveSummarySuccess(response.response));
  } catch (error) {
    yield put(fetchExecutiveSummaryError(error as AxiosError));
  }
}