import { UploadFile } from "antd/lib/upload";
import { TCheckBoxGroup, TCheckBoxGroupSubValue, TFormField } from "../libs/models/NewRqst.model";

export const getUniqueElements = (arr1: any[], arr2: any[]): any[] => {
    return Array.from(new Set([...(arr1 || []), ...(arr2 || [])]))
  }
  
export const getDisabledValuesForCheckboxGroup = (checkboxGrp: TCheckBoxGroup): string[] => {
    return checkboxGrp?.subValues?.filter((subVal: TCheckBoxGroupSubValue) => subVal?.disabled)?.map((subVal: TCheckBoxGroupSubValue) => subVal.value) || [];
  }

export const checkboxSelectAll = (field: TFormField, checked: boolean, checkboxValue: TCheckBoxGroup, allCheckedValues: Record<string, any>): Record<string, any> => {
    if (checked) {
        const allSubValues: TCheckBoxGroupSubValue[] = checkboxValue?.subValues || [];
        return (
          {
              ...(allCheckedValues || []),
              [field.name]: field.showArrow
                ? allSubValues?.map((subVal: TCheckBoxGroupSubValue) => subVal.value) || []
                : true,
            }
        )
      } else {
      return (
          {
              ...(allCheckedValues || []),
              [field.name]: field.showArrow
                ? getDisabledValuesForCheckboxGroup(checkboxValue)
                : false,
            }
      )
      }
}

export const checkboxChange = (field: TFormField, checked: boolean, value: string, allCheckedValues: Record<string, any>): Record<string, any> => {
    let filteredSubValues: string[] = [];

    if (checked) {
      // creating new selected sub checkboxes list
      const subCheckBoxItems: string[] = getUniqueElements(
        [value],
        field.showArrow ? allCheckedValues?.[field.name] : [],
      );
      return (
        {
            ...(allCheckedValues || []),
            [field.name]: subCheckBoxItems,
          }
      )
    } else {
      // getting all checked values except the unchecked value
      filteredSubValues = allCheckedValues?.[field.name]?.filter(
        (subVal: string) => subVal !== value,
      );
      return (
        {
            ...(allCheckedValues || []),
            [field.name]: filteredSubValues,
          }
      )
    }
  };

  export const formatBytes = (bytes: number) => {
    var marker = 1024; 
    var decimal = 2;
    var kiloBytes = marker;
    var megaBytes = marker * marker;
    var gigaBytes = marker * marker * marker;
  
    if(bytes < kiloBytes) return bytes + " Bytes";
    else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
    else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
    else return(bytes / gigaBytes).toFixed(decimal) + " GB";
}

  export const getPlaceholderForFile = (file: UploadFile, placeholderText: string): string => {
    if(!file?.name){
      return placeholderText;
    }
    return `${placeholderText?.replace('Upload', '')?.trim()} - ${file.name}`
  }