import { TKDDGeneratePayload, TKDDSavePayload, TKDDSaveResponse, TKDDTable } from "../../models/KDD.model";
import { TFetchSubSectionPayload, TSectionPayload, TSubSectionResponse } from "./LandingPageState";

export const REQUEST_KDD = 'REQUEST_KDD';
export const KDD_SUCCESS = 'KDD_SUCCESS';
export const KDD_FAILURE = 'KDD_FAILURE';

export const REQUEST_KDD_SUBSECTION = 'REQUEST_KDD_SUBSECTION';
export const KDD_SUBSECTION_SUCCESS = 'KDD_SUBSECTION_SUCCESS';
export const KDD_SUBSECTION_FAILURE = 'KDD_SUBSECTION_FAILURE';

export const GENERATE_KDD_REQUEST = 'GENERATE_KDD_REQUEST';
export const GENERATE_KDD_SUCCESS = 'GENERATE_KDD_SUCCESS';
export const GENERATE_KDD_FAILURE = 'GENERATE_KDD_FAILURE';

export const SAVE_KDD_REQUEST = 'SAVE_KDD_REQUEST';
export const SAVE_KDD_SUCCESS = 'SAVE_KDD_SUCCESS';
export const SAVE_KDD_FAILURE = 'SAVE_KDD_FAILURE';

// Fetch KDD Request
export type TKDDRequest = {
    type: 'REQUEST_KDD';
    payload: TSectionPayload
  }

  export type TKDDSuccess = {
    type: 'KDD_SUCCESS';
    payload: TSubSectionResponse<TKDDTable>[];
  }

  export type TKDDFailure = {
    type: 'KDD_FAILURE';
    payload: any;
  }

// Fetch KDD Subsection
export type TKDDSubSectionRequest = {
    type: 'REQUEST_KDD_SUBSECTION';
    payload: TFetchSubSectionPayload;
  }

  export type TKDDSubSectionSuccess = {
    type: 'KDD_SUBSECTION_SUCCESS';
    payload: TSubSectionResponse<TKDDTable>;
  }

  export type TKDDSubSectionFailure = {
    type: 'KDD_SUBSECTION_FAILURE';
    payload: any;
  }

// Generate KDD data
export type TKDDGenerateRequest = {
  type: 'GENERATE_KDD_REQUEST',
  payload: TKDDGeneratePayload;
}

export type TKDDGenerateSuccess = {
  type: 'GENERATE_KDD_SUCCESS',
  payload: TSubSectionResponse<TKDDTable>;
}

export type TKDDGenerateFailure = {
  type: 'GENERATE_KDD_FAILURE',
  payload: {
    error: any,
    subsection: string
  };
}

// Save KDD Data
export type TSaveKDDRequest = {
  type: 'SAVE_KDD_REQUEST',
  payload: TKDDSavePayload
}

export type TSaveKDDSuccess = {
  type: 'SAVE_KDD_SUCCESS',
  payload: TKDDSaveResponse
}

export type TSaveKDDFailure = {
  type: 'SAVE_KDD_FAILURE',
  payload: any
}

  
  export type TKDDActionTypes = TKDDRequest | TKDDSuccess | TKDDFailure | 
  TKDDSubSectionRequest | TKDDSubSectionSuccess | TKDDSubSectionFailure |
  TKDDGenerateRequest | TKDDGenerateSuccess | TKDDGenerateFailure |
  TSaveKDDRequest | TSaveKDDSuccess | TSaveKDDFailure;