import { TProcessMaturityPayload } from "../../models/ProcessMaturity.model";
import { TProcessMaturityTable } from "../../models/ProcessMaturity.model";
import { PROCESS_MATURITY_FAILURE, PROCESS_MATURITY_SUBSECTION_FAILURE, PROCESS_MATURITY_SUBSECTION_SUCCESS, PROCESS_MATURITY_SUCCESS, REQUEST_PROCESS_MATURITY, REQUEST_PROCESS_MATURITY_SUBSECTION, TProcessMaturityFailure, TProcessMaturityRequest, TProcessMaturitySubSectionFailure, TProcessMaturitySubSectionRequest, TProcessMaturitySubSectionSuccess, TProcessMaturitySuccess } from "../DmapPhase0State/ProcessMaturity.state";
import { TFetchSubSectionPayload, TSubSectionResponse } from "../DmapPhase0State/LandingPageState";



export const fetchProcessMaturity = (payload: TProcessMaturityPayload): TProcessMaturityRequest => ({
    type: REQUEST_PROCESS_MATURITY,
    payload
  });
  
  export const fetchProcessMaturitySuccess = (data: TSubSectionResponse<TProcessMaturityTable>[]): TProcessMaturitySuccess => ({
    type: PROCESS_MATURITY_SUCCESS,
    payload: data,
  });
  
  export const fetchProcessMaturityError = (error: any): TProcessMaturityFailure => ({
    type: PROCESS_MATURITY_FAILURE,
    payload: error,
  });
  
export const fetchProcessMaturitySubSection = (payload: TFetchSubSectionPayload): TProcessMaturitySubSectionRequest => ({
    type: REQUEST_PROCESS_MATURITY_SUBSECTION,
    payload
  });
  
  export const fetchProcessMaturitySubSectionSuccess = (data: TSubSectionResponse<TProcessMaturityTable>): TProcessMaturitySubSectionSuccess => ({
    type: PROCESS_MATURITY_SUBSECTION_SUCCESS,
    payload: data,
  });
  
  export const fetchProcessMaturitySubSectionError = (error: any): TProcessMaturitySubSectionFailure => ({
    type: PROCESS_MATURITY_SUBSECTION_FAILURE,
    payload: error,
  });