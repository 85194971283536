import { TFormField } from "../models/NewRqst.model";

export const REGENEERATE_SECTION_FIELDS: TFormField[] = [
    {
        id: 'sections',
        key: 'sections',
        type: 'select',
        name: 'sections',
        label: 'Select Section',
        placeholderText: 'Select Section',
        rules: [
            {
                required: true,
                message: 'Please provide a section.'
            }
        ],
        canRemove: false
    },
    {
        id: 'promptarea',
        key: 'promptarea',
        type: 'textarea',
        name: 'promptarea',
        label: 'Enter prompt for Gen AI',
        placeholderText: 'Enter prompt to regenerate content of this section...',
        rules: [
            {
                required: true,
                message: 'Please provide some prompt to continue.'
            }
        ],
        canRemove: false
    }
]

export const REGENERATE_HEADERS_FIELDS: TFormField[] = [
    {
        id: 'header',
        key: 'headers',
        type: 'checkbox-group',
        name: 'headers',
        placeholderText: 'Select All',
        canRemove: false,
        showArrow: true
    },
]

export const SECTIONS_FIELD: TFormField = {
    id: 'sections',
    key: 'sections',
    type: 'select',
    name: 'sections',
    label: 'Select Sections',
    placeholderText: 'Select Section(s)',
    rules: [
        {
            required: true,
            message: 'Please provide at lease one section.'
        }
    ],
    canRemove: false,
    mode: 'multiple'
}

export const REVERT_CHANGES_FIELDS: TFormField[] = [
    {
        id: 'sections',
        key: 'sections',
        type: 'select',
        name: 'sections',
        label: 'Select Section',
        placeholderText: 'Select Sections',
        rules: [
            {
                required: true,
                message: 'Please provide at lease one section.'
            }
        ],
        canRemove: false
    },
];