export const getStatusColor = (status: string): string => {
    switch (status) {
      case 'completed':
        return '#568437';
      case 'in progress':
        return '#F4B309';
      case 'pending':
        return '#B7B7B7';
      case 'failed':
        return '#954e4c';
      default:
        return 'blue';
    }
  };