import React, { useEffect, useState } from 'react';
import { Button, Collapse, Skeleton, Spin, Tag } from 'antd';
import './Estimator.less';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  fetchProjectTimeline,
  generateVisuals,
  resetProjectimeline,
  saveTimelineReport,
  setTimelineSelectedValues,
} from '../../../libs/store/DmapPhase0Actions/ProjectPlan.actions';
import {
  TPlanEstimates,
  TProjectTimeline,
  TProjectVisualPayload,
  TSaveProjectTimelinePayload,
  TSaveProjectTimelineResponse,
  TSubsection,
} from '../../../libs/models/ProjectPlan.model';
import {
  TDataState,
  TRootState,
  TSaveFlagAction,
  TSetSubsectionDataAction,
} from '../../../libs/store/DmapPhase0State/LandingPageState';
import AccordianTable from '../../../Components/AccordianTable/AccordianTable';
import {
  getPayloadForGenerateReport,
  getPayloadForSaveReport,
  getTagColor,
} from '../../../utils/projectPlan.util';
import { CaretRightOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import {
  TProjectTimelineRequest,
  TProjectVisualsRequest,
  TResetProjectTimeline,
  TSaveProjectTimelinesRequest,
  TSetProjectTimelineSelectedValues,
} from '../../../libs/store/DmapPhase0State/ProjectPlan.state';
import {
  updateSaveValue,
} from '../../../libs/store/DmapPhase0Actions/LandingPageActions';
import { TSectionsByIdRequest } from '../../../libs/store/DmapPhase0State/ReviewScreen.state';
import { fetchSectionsById } from '../../../libs/store/DmapPhase0Actions/ReviewScreen.actions';

const { Panel } = Collapse;

interface Props {
  selectedItem: string;
  setSelectedSubsection: (subsection: string) => void;

  projectTimeline?: TDataState<TProjectTimeline[]> | null;
  saveTimelineStatus?: TDataState<TSaveProjectTimelineResponse> | null;
  visualStatus?: TDataState<TPlanEstimates[]> | null;
  selectedValues?: Record<string, Record<string, string>>;

  fetchSections?: (requestId: string) => TSectionsByIdRequest;
  fetchTimeline?: (requestId: string) => TProjectTimelineRequest;
  resetTimeline?: () => TResetProjectTimeline;
  saveProjectTimelineReport?: (
    payload: TSaveProjectTimelinePayload,
  ) => TSaveProjectTimelinesRequest;
  setSelectedValues?: (
    values: Record<string, Record<string, string>>,
  ) => TSetProjectTimelineSelectedValues;
  generateTimelineReport?: (payload: TProjectVisualPayload) => TProjectVisualsRequest;
  updateSaveEnabled?: (enabled: boolean) => TSaveFlagAction;
}

const Estimator: React.FC<Props> = ({
  selectedItem,
  setSelectedSubsection,
  projectTimeline,
  saveTimelineStatus,
  visualStatus,
  selectedValues,
  fetchSections,
  fetchTimeline,
  resetTimeline,
  saveProjectTimelineReport,
  setSelectedValues,
  generateTimelineReport,
  updateSaveEnabled,
}) => {
  const { id }: { id: string } = useParams();

  // const [selectedValues, setSelectedValues] = useState<Record<string, Record<string, string>>>({});
  const [anyScoreCalculated, setAnyScoreCalculated] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(true);

  useEffect(() => {
    fetchTimeline && fetchTimeline(id);

    // Clear timeline data on unmount
    return () => {
      resetTimeline && resetTimeline();
      setSelectedValues && setSelectedValues({});
    };
  }, []);

  /**
   * Update selected parameter values on first load and make first load flag as false
   */
  useEffect(() => {
    if (projectTimeline?.data?.length && firstLoad) {
      const defaultSelectedValues: Record<string, Record<string, string>> = {};

      projectTimeline?.data.forEach((timeline: TProjectTimeline) => {
        //Commented this condition as a fix for an issue, needs to be removed in future
        // if (timeline.categoryAverage && timeline.categoryScore) {
        const sectionValues: Record<string, string> = {};
        timeline.subsections?.forEach((subSection: TSubsection) => {
          if(subSection.selectedValue){
            sectionValues[subSection.parameter] = subSection.selectedValue;
          }
        });

        defaultSelectedValues[timeline.section] = sectionValues;
        // }
      });
      setSelectedValues && setSelectedValues(defaultSelectedValues);

      setFirstLoad(false);
    }
    setShowLoader(false);
  }, [projectTimeline]);

  /**
   * Call the generate visuals API if generate flag is true
   */
  useEffect(() => {
    if (isGenerating) {
      // For generating the data
      const payload: TProjectVisualPayload = getPayloadForGenerateReport(
        id,
        projectTimeline?.data || [],
      );
      generateTimelineReport && generateTimelineReport(payload);
    }

    if (saveTimelineStatus?.loading) {
      setShowLoader(true);
    }

    if (saveTimelineStatus?.data || saveTimelineStatus?.error) {
      if (!isGenerating) {
        setShowLoader(false);
      }
      updateSaveEnabled && updateSaveEnabled(false);
    }
  }, [saveTimelineStatus]);

  /**
   * Navigate to Project Plan Estimates once we are getting response for visuals report
   */
  useEffect(() => {
    if (visualStatus?.data && setSelectedSubsection && isGenerating) {
      fetchSections && fetchSections(id);
      setIsGenerating(false);
      setSelectedSubsection('Project Plan Estimates')
    }
  }, [visualStatus, setSelectedSubsection]);

  /**
   * Method to update the selected paramter values
   * @param section
   * @param parameter
   * @param value
   */
  const updateSelectedValues = (section: string, parameter: string, value: string) => {
    setSelectedValues &&
      setSelectedValues({
        ...(selectedValues || {}),
        [section]: {
          ...(selectedValues?.[section] || {}),
          [parameter]: value,
        },
      });
  };

  /**
   * Method to save the timeline selected data
   */
  const saveTimelineReport = () => {
    if (Object.keys(selectedValues || {})?.length) {
      const payload: TSaveProjectTimelinePayload = getPayloadForSaveReport(
        id,
        projectTimeline?.data || [],
        selectedValues || {},
      );

      saveProjectTimelineReport && saveProjectTimelineReport(payload);
    }
  };

  /**
   * Method to generate the visuals using the selected values by the user
   */
  const generateVisuals = () => {
    setIsGenerating(true);
    setShowLoader(true);
    saveTimelineReport();
  };

  return (
    <div className="estimator-container">
      {showLoader || projectTimeline?.loading ? (
        <Spin tip={'Fetching Project Timeline'} style={{ marginTop: '20%', marginLeft: '50%' }} />
      ) : (
        <>
          <Collapse
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            className="timeline-collpase"
            bordered={true}
          >
            {projectTimeline?.data?.map((timeline: TProjectTimeline, index: number) => (
              <Panel
                className="timeline-container"
                header={
                  <div className="accordian-header">
                    <span className="section-name">{timeline.section}</span>
                    <div className="ctg-avg">
                      <span className="tile-key">Category Average:</span>
                      <span>
                        {timeline.loading ? (
                          <Skeleton.Button style={{ height: '20px' }} active />
                        ) : (
                          (timeline.categoryAverage && timeline.categoryAverage >=0) ? timeline.categoryAverage : 'N/A'
                        )}
                      </span>
                    </div>
                    <div className="ctg-score">
                      <span className="tile-key">Category Score:</span>
                      <span className={timeline.categoryScore ? 'tile-tag' : ''}>
                        {timeline.loading ? (
                          <Skeleton.Button style={{ height: '20px' }} active />
                        ) : timeline.categoryScore ? (
                          <Tag className="score-tag" color={getTagColor(timeline.categoryScore)}>
                            {timeline.categoryScore}
                          </Tag>
                        ) : (
                          'NA'
                        )}
                      </span>
                    </div>
                  </div>
                }
                key={index}
              >
                <div className="accordian-content-wrapper">
                  <div>
                    <AccordianTable
                      selectedValues={selectedValues?.[timeline.section] || {}}
                      setIsUpdated={setAnyScoreCalculated}
                      selectedItem={selectedItem}
                      setSelectedValues={updateSelectedValues}
                      section={timeline.section}
                      subSections={timeline.subsections}
                    />
                  </div>
                </div>
              </Panel>
            ))}
          </Collapse>
          <div className="generate-btn">
            <div className="generate-hint">Click on “Generate” to get final Project plan</div>
            <Button className="submit-btn" disabled={!anyScoreCalculated} onClick={generateVisuals}>
              Generate
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: TRootState) => ({
  projectTimeline: state.projectPlan?.timeline,
  saveTimelineStatus: state.projectPlan?.saveTimeline,
  visualStatus: state.projectPlan?.visuals,
  selectedValues: state.projectPlan?.selectedTimelineValues,
});

const mapDispatchToProps = (dispatch: Dispatch, { selectedItem }: Props) => ({
  fetchTimeline: (requestId: string) => dispatch(fetchProjectTimeline(requestId)),
  resetTimeline: () => dispatch(resetProjectimeline()),
  saveProjectTimelineReport: (payload: TSaveProjectTimelinePayload) =>
    dispatch(saveTimelineReport(payload)),
  setSelectedValues: (values: Record<string, Record<string, string>>) =>
    dispatch(setTimelineSelectedValues(values)),
  generateTimelineReport: (payload: TProjectVisualPayload) => dispatch(generateVisuals(payload)),
  fetchSections: (requestId: string) =>
    dispatch(fetchSectionsById(parseInt(requestId))),
  updateSaveEnabled: (enabled: boolean) => dispatch(updateSaveValue(selectedItem, enabled)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Estimator);
