import React from 'react';
import ReusableModal from '../../Components/FormModal/ReusableModal';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onSave: () => void;
  selectedItem: string; 
}

const SaveChangesModal: React.FC<Props> = ({ visible, onCancel, onSave, selectedItem }) => {
  return (
    <ReusableModal
      visible={visible}
      onCancel={onCancel}
      title="Are you Sure?"
      cancelButtonText="No, Cancel"
      submitButtonText="Yes, Save"
      onSubmit={onSave}
    >
      <p>
        All the current changes will be lost if you switch to a different section. Do you want to
        save the current changes?
      </p>
    </ReusableModal>
  );
};

export default SaveChangesModal;
