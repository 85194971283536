import { TArchGenericResponse, TArchImgResponse, TArchResponse, TArchTableData } from '../../models/Architecture.model';
import { put, call } from 'redux-saga/effects';
import {
  fetchArchImg,
  generateArchDiagram,
  uploadArchFile,
} from '../../services/DmapPhase0Services/Architecture.service';
import {
  fetchArchitectureDetailsError,
  fetchArchitectureDetailsSuccess,
  fetchArchitectureImageError,
  fetchArchitectureImageSuccess,
  fetchArchitectureSubSectionError,
  fetchArchitectureSubSectionSuccess,
  fetchFilesFailure,
  fetchFilesSuccess,
  generateDiagramFailure,
  generateDiagramSuccess,
  uploadFileFailure,
  uploadFileSuccess,
} from '../DmapPhase0Actions/Architecture.actions';
import { AxiosError } from 'axios';
import { TArchitectureSubSectionRequest, TFetchFilesRequest, TGenerateDiagramRequest, TgetArchDetails, TgetArchImage, TUploadFileRequest } from '../DmapPhase0State/Architecture.state';
import { fetchExistingFiles, fetchSectionDetails, fetchSubSectionDetails } from '../../services/DmapPhase0Services/ReviewPageService';
import { TSectionResponse, TSubSectionResponse } from '../DmapPhase0State/LandingPageState';
import { TFetchFilesResponse } from '../../models/ReviewScreenModels';

export function* fetchArchitectureSaga(action: TgetArchDetails) {
  const { reqId, selectedSection, pageSize, subsection } = action.payload
  try {
    const response: TArchResponse = yield call(fetchSectionDetails<TArchResponse>, reqId, selectedSection, pageSize);
    yield put(fetchArchitectureDetailsSuccess(response.response, subsection || ''));
  } catch (error) {
    yield put(fetchArchitectureDetailsError(error as AxiosError, subsection || ''));
  }
}

/**
 * Saga to fetch workshop enablers data subsection
 * @param action 
 */
export function* fetchArchitectureSubSectionSaga(action: TArchitectureSubSectionRequest): Generator<any, void, any> {
  const { subsection } = action.payload.body
  try {
    const data: TSectionResponse<TArchTableData> = yield call(fetchSubSectionDetails<TSubSectionResponse<TArchTableData>>, action.payload);
    yield put(fetchArchitectureSubSectionSuccess(data?.response?.[0], subsection));
  } catch (error) {
    yield put(fetchArchitectureSubSectionError(error as AxiosError, action.payload?.body?.subsection));
  }
}

export function* fetchArchitectureImgSaga(action: TgetArchImage): Generator<any, void, any> {
  try {
    const response: TArchImgResponse = yield call(fetchArchImg, action.payload);
    yield put(fetchArchitectureImageSuccess(response, action.payload.subsection));
  } catch (error) {
    yield put(fetchArchitectureImageError(error as AxiosError, action.payload.subsection));
  }
}

export function* fetchArchExistingFilesSaga(action: TFetchFilesRequest): Generator<any, void, any> {
  try {
    const response: TFetchFilesResponse = yield call(fetchExistingFiles, action.payload);
    yield put(fetchFilesSuccess(response?.files || [], action.payload.subSection));
  } catch (error) {
    yield put(fetchFilesFailure(error as AxiosError, action.payload.subSection));
  }
}

// Upload Saga
export function* uploadFile(action: TUploadFileRequest) {
  const { subsection } = action.payload;
  try {
    const response: TArchGenericResponse = yield call(
      uploadArchFile,
      action.payload
    );
    yield put(uploadFileSuccess(response, subsection));
  } catch (error) {
    yield put(uploadFileFailure(error as AxiosError, subsection));
  }
} 

// Generate image saga
export function* generateDiagramSaga(action: TGenerateDiagramRequest) {
  const { subsection } = action.payload;
  try {
    const response: TArchGenericResponse = yield call(
      generateArchDiagram,
      action.payload
    );
    yield put(generateDiagramSuccess(response, subsection));
  } catch (error) {
    yield put(generateDiagramFailure(error as AxiosError, subsection));
  }
} 
