import { TProcessMaturityTable } from '../../models/ProcessMaturity.model';
import { TDataState, TSubSectionResponse } from '../DmapPhase0State/LandingPageState';
import {
  PROCESS_MATURITY_FAILURE,
  PROCESS_MATURITY_SUBSECTION_FAILURE,
  PROCESS_MATURITY_SUBSECTION_SUCCESS,
  PROCESS_MATURITY_SUCCESS,
  REQUEST_PROCESS_MATURITY,
  REQUEST_PROCESS_MATURITY_SUBSECTION,
  TProcessMaturityActionTypes,
} from '../DmapPhase0State/ProcessMaturity.state';

const initialState: TDataState<TSubSectionResponse<TProcessMaturityTable>[]> = {
  data: null,
  loading: false,
  error: null,
};

export const processMaturityReducer = (
  state = initialState,
  action: TProcessMaturityActionTypes,
): TDataState<TSubSectionResponse<TProcessMaturityTable>[]> => {
  switch (action.type) {
    case REQUEST_PROCESS_MATURITY:
      return {
        ...state,
        loading: true,
      };
    case PROCESS_MATURITY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case PROCESS_MATURITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REQUEST_PROCESS_MATURITY_SUBSECTION:
      return {
        ...state,
        data:
          state.data?.filter(
            (val: TSubSectionResponse<TProcessMaturityTable>) =>
              val.subsection !== action.payload?.body?.subsection,
          ) || [],
        loading: true,
      };
    case PROCESS_MATURITY_SUBSECTION_SUCCESS:
      const { subsection } = action.payload;

      return {
        ...state,
        loading: false,
        data: [
          ...(state.data?.filter(
            (val: TSubSectionResponse<TProcessMaturityTable>) => val.subsection !== subsection,
          ) || []),
          {
            ...(action?.payload || {}),
            data: { filtered: true, ...(action?.payload?.data || {}) },
          },
        ],
      };
    case PROCESS_MATURITY_SUBSECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};
