import React, { useEffect } from 'react';
import { Form } from 'antd';
import ReusableModal from '../../Components/FormModal/ReusableModal';
import './ReviewModals.less';
import { connect } from 'react-redux';
import DynamicForm from '../../Components/DynamicForm/DynamicForm';
import { getFieldNameForUpdateFile, getFieldsConfigForUpdateFiles, getPayloadForFileUpdate } from '../../utils/ReviewScreen.util';
import { generateSlideContentRequest } from '../../libs/store/DmapPhase0Actions/LandingPageActions';
import { ProcessRequestGenerateSlideContentRequestAction, TDataState, TRootState } from '../../libs/store/DmapPhase0State/LandingPageState';
import { Dispatch } from 'redux';
import { fetchExistingFiles, fetchSectionsById } from '../../libs/store/DmapPhase0Actions/ReviewScreen.actions';
import { TFetchFiles, TSectionsByIdRequest } from '../../libs/store/DmapPhase0State/ReviewScreen.state';
import { TFetchFileData } from '../../libs/models/ReviewScreenModels';
import { useParams } from 'react-router';
import { FormInstance } from 'antd/es/form/Form';
import { TSectionDetails } from '../../libs/models/landingPageModels';

interface Props {
  visible: boolean;
  onCancel: () => void;
  requestId: string;
  section: string;
  subSection: string;

  sectionDropdownData?: TSectionDetails[] | null;
  existingFiles?: TDataState<TFetchFileData[]> | undefined;

  fetchSections?: (reqId: number) => TSectionsByIdRequest;
  fetchFiles?: (reqId: string) => TFetchFiles;
  updateFiles?: (requestId: number, filePrefixes: string, operation: string, files: File[], sections: string[]) => ProcessRequestGenerateSlideContentRequestAction
}

const FileUpload: React.FC<Props> = ({ visible, onCancel, requestId, sectionDropdownData, existingFiles, fetchSections, fetchFiles ,updateFiles }) => {
  const [form] = Form.useForm();
  const { id }: { id: string } = useParams();

  useEffect(() => {
    fetchFiles && fetchFiles(id);
    fetchSections && fetchSections(parseInt(id));
  }, [])

  useEffect(() => {
    if(form && existingFiles?.data?.length){
      existingFiles?.data?.map((data: TFetchFileData, index: number) => {
        form.setFieldValue(getFieldNameForUpdateFile(data.filetype, index), [
          {
            name: data.filename,
            size: data.fileSize
          } as File
        ])
      })
    }
  }, [existingFiles?.data])

  const handleModalSubmit = async (values: any) => {
    try {
      form
      .validateFields()
      .then((_: FormInstance) => {
        const sections = form.getFieldValue('sections');
      const payloadData = getPayloadForFileUpdate(getFieldsConfigForUpdateFiles({requestID: id, files: existingFiles?.data || []}), form);
      if(payloadData.files?.length){
        updateFiles && updateFiles(
            parseInt(requestId),
            payloadData.prefixes?.join(','),
            'update',
            payloadData.files,
            sections
          );
      }
      form.resetFields();
      onCancel();
    })
    } catch (error) {
      throw error;
    }
  };

  return (
    <div>
      <ReusableModal
        visible={visible}
        onCancel={onCancel}
        title="Input Files"
        cancelButtonText="Cancel"
        submitButtonText="Generate"
        onSubmit={handleModalSubmit}
      >
        <DynamicForm 
        fieldsConfig={getFieldsConfigForUpdateFiles({requestID: id, files: existingFiles?.data || []})}
        form={form}
        options={{
          'sections': sectionDropdownData?.map((section: TSectionDetails) => ({
                  label: section.section,
                  value: section.sectionConstant,
                })),
        }}
        />
      </ReusableModal>
    </div>
  );
};

const mapStateToProps = (state: TRootState) => ({
  sectionDropdownData: state.allSections.data,
  existingFiles: state.reviewScreen?.data?.existingFiles
})

const mapDispatchToProps  = (dispatch: Dispatch, {section, subSection}: Props) => ({
  fetchSections: (requestId: number) => dispatch(fetchSectionsById(requestId)),
  fetchFiles: (reqId: string) => dispatch(fetchExistingFiles({reqId, fileName: 'raw', subSection, folderType: '/raw_input/'})),
  updateFiles: (requestId: number, filePrefixes: string, operation: string, files: File[], sections: string[]) => dispatch(generateSlideContentRequest(requestId, filePrefixes, operation, files, sections))
})

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
