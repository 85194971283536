import React, { useEffect } from 'react';
import './Questionnaire.less';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  requestDownloadFile,
  resetDownloadFileData,
} from '../../../../../libs/store/DmapPhase0Actions/WorkshopEnablers.actions';
import { useParams } from 'react-router';
import {
  TDownloadFileResponse,
  TRequestDownloadFile,
  TResetDownloadFileData,
} from '../../../../../libs/store/DmapPhase0State/WorkshopEnablers.state';
import { TDataState, TRootState } from '../../../../../libs/store/DmapPhase0State/LandingPageState';

interface Props {
  fileData?: TDataState<TDownloadFileResponse>;

  resetDownloadData?: () => TResetDownloadFileData;
  getFileDownloadUrl?: (fileName: string, requestId: string) => TRequestDownloadFile;
}

const Questionnaire: React.FC<Props> = ({ fileData, getFileDownloadUrl, resetDownloadData }) => {
  const { id }: { id: string } = useParams();

  const fileResponse = [
    {
      fileName: 'Workshop Questionnaire.csv',
    },
  ];

  useEffect(() => {
    getFileDownloadUrl && getFileDownloadUrl('Workshop Questionnaire.csv', id || '');
  }, []);

  /**
   * Method to download the file
   * @param fielName
   */
  const download = (fileName: string) => {
    if (fileData?.data?.url) {
      window.location.href = fileData?.data?.url;
      resetDownloadFileData && resetDownloadFileData();
    }
  };

  const renderFile = (file: any) => {
    return (
      <div className="file-container">
        <div className="file-details">
          <span className="file-name">{file.fileName}</span>
          {fileData?.data?.size && (
            <>
              <span className="divider">|</span>
              <span className="file-size">{fileData?.data?.size}</span>
            </>
          )}
        </div>
        <button className="download-btn" onClick={() => download(file.fileName)}>
          Download
        </button>
      </div>
    );
  };
  return (
    <div className="questionnaire-container">
      {fileResponse.map((file: any) => renderFile(file))}
    </div>
  );
};

const mapStateToProps = (state: TRootState) => ({
  fileData: state.workshopEnablers.fileData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getFileDownloadUrl: (fileName: string, requestId: string) =>
    dispatch(requestDownloadFile(requestId, fileName)),
  resetDownloadData: () => dispatch(resetDownloadFileData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
