import { CaretDownOutlined } from '@ant-design/icons';
import { Button, notification, Spin, Typography } from 'antd';
import NoDataBottom from 'assets/Images/NoDataBottom.svg';
import NoDataTop from 'assets/Images/NoDataTop.svg';
import classNames from 'classnames';
import { TPlanEstimates } from 'libs/models/ProjectPlan.model';
import { generateVisuals } from 'libs/store/DmapPhase0Actions/ProjectPlan.actions';
import { TDataState, TRootState } from 'libs/store/DmapPhase0State/LandingPageState';
import { TProjectVisualsRequest } from 'libs/store/DmapPhase0State/ProjectPlan.state';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Dispatch } from 'redux';
import 'themes/damp-phase-constants/theme.less';
import './PlanEstimates.less';
import { exportGanttChartToExcel, getTotalWeeks, getWeekColumnNames } from './utils';

interface Props {
  chartData?: TDataState<TPlanEstimates[]> | null;
  getChartData?: (requestId: string) => TProjectVisualsRequest;
}
interface INoDataProps {
  message: React.ReactNode;
}
const NoDataWrapper = ({ message }: INoDataProps) => (
  <div className="flex-center flex-column">
    <img src={NoDataTop} alt="no-data-top" className="mt-1 pt-1" />
    <div className="d-flex my-1">
      <Typography className="font-16">{message}</Typography>
    </div>
    <img src={NoDataBottom} alt="no-data-bottom" />
  </div>
);
const ProjectPlanEstimates: React.FC<Props> = ({ chartData, getChartData }) => {
  const [expandedActivity, setExpandedActivity] = useState<number | null>(null);
  const totalWeeks = getTotalWeeks(chartData?.data || []);
  const { id }: { id: string } = useParams();

  useEffect(() => {
    getChartData && getChartData(id);
  }, [id]);

  /**
   * @function getGanttChartBar
   * @description Get Gantt chart bar jsx element for a week for an activity
   * @param start start week of the activity
   * @param end end week of the activity
   * @param index week index
   * @param isExpanded is the activity expanded
   * @returns JSX.Element of the Gantt chart bar
   */
  const getGanttChartBar = (start: number, end: number, index: number, isExpanded = false) => {
    const isFilled = index + 1 >= start && index + 1 <= end;

    return (
      <td key={index}>
        {isFilled ? (
          <div
            className={classNames(
              'gantt-chart-cell',
              index + 1 === start && 'start-bar',
              index + 1 === end && 'end-bar',
              isExpanded && 'expanded',
            )}
          />
        ) : (
          <></>
        )}
      </td>
    );
  };

  return (
    <Spin spinning={chartData?.loading}>
      <div className="gantt-chart-container">
        {chartData?.data?.length && !chartData?.loading ? (
          <>
            <div className="table-container">
              <table className="phase0-plan-estimates-table">
                <thead>
                  <tr>
                    <th className="activity-column">KEY ACTIVITIES</th>
                    {getWeekColumnNames(totalWeeks).map((week) => (
                      <th key={week}>{week}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {chartData?.data?.map(
                    ({ section, tasks: { start, end }, subsections }, activityIndex) => (
                      <React.Fragment key={activityIndex}>
                        <tr
                          className={classNames(
                            expandedActivity === activityIndex && 'selected-row',
                          )}
                        >
                          <td
                            className="activity-column"
                            onClick={() =>
                              setExpandedActivity(
                                expandedActivity === activityIndex ? null : activityIndex,
                              )
                            }
                          >
                            <CaretDownOutlined
                              rotate={expandedActivity === activityIndex ? 180 : 0}
                            />
                            {section}
                          </td>
                          {Array.from({ length: totalWeeks }, (_, index) =>
                            getGanttChartBar(start, end, index),
                          )}
                        </tr>
                        {expandedActivity === activityIndex &&
                          subsections.map(
                            (
                              {
                                content,
                                start_subPhase: startSubsection,
                                end_subPhase: endSubsection,
                              },
                              subIndex,
                            ) => (
                              <tr
                                className={classNames(
                                  expandedActivity === activityIndex && 'selected-row',
                                )}
                                key={`${activityIndex}-${subIndex}`}
                              >
                                <td className="activity-column expanded">{content}</td>
                                {Array.from({ length: totalWeeks }, (_, index) =>
                                  getGanttChartBar(startSubsection, endSubsection, index, true),
                                )}
                              </tr>
                            ),
                          )}
                      </React.Fragment>
                    ),
                  )}
                </tbody>
              </table>
            </div>
            <div className="export-container">
              <Typography className="font-16">
                Click 'Export' to download the Gantt chart in Excel format
              </Typography>
              <Button
                size="large"
                className="phase0-primary-btn"
                onClick={() => {
                  try {
                    exportGanttChartToExcel(chartData.data!, `Gantt Chart_${id}.xlsx`);
                    notification.success({ message: 'Successfully exported to excel' });
                  } catch (e) {
                    notification.error({ message: 'Error exporting to excel' });
                  }
                }}
              >
                Export File
              </Button>
            </div>
          </>
        ) : (
          <NoDataWrapper message="Try changing request." />
        )}
      </div>
    </Spin>
  );
};

const mapStateToProps = (state: TRootState) => ({
  chartData: state.projectPlan?.visuals,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getChartData: (requestId: string) => dispatch(generateVisuals({ requestId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPlanEstimates);
