import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  DownloadRequestResponse,
  RegeneratePayload,
  SaveApiPayload,
  SaveHeadersPayload,
  TExistingFilesPayload,
  TFetchFilesResponse,
} from '../../models/ReviewScreenModels';
import { TFetchSubSectionPayload } from '../../store/DmapPhase0State/LandingPageState';
import { ROUTES } from '../../../constants';
import { TPromptResultPayload, TPromptResultResponse } from '../../models/ChatPrompt.model';

export const getSectionsById = async (requestId: number) => {
  try {
    const response = await axios.get<{ sections: any[] }>(
      ROUTES.GET_SECTIONS_BY_ID(requestId),
    );
    return response.data;
  } catch (error) {
    
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchSectionDetails = async <T>(reqId: string, selectedItem: string, pageSize: number): Promise<T | AxiosError> => {
  try {
    const response = await axios.get(
      ROUTES.GET_SECTION_DETAILS(reqId, selectedItem, pageSize),
    );
    const responseData = response.data;
    return responseData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchSubSectionDetails = async <T>(payload: TFetchSubSectionPayload): Promise<T | AxiosError> => {
  try {
    const response: AxiosResponse<T> = await axios.post(
      ROUTES.GET_SUBSECTION_DETAILS(payload.requestId),
      payload.body
    );
    const responseData = response.data;
    return responseData;
  } catch (error: any) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const putEditedContentToSave = async (
  reqId: number,
  selectedItem: string,
  payload: SaveApiPayload,
) => {
  try {
    const response = await axios.put(
      ROUTES.SAVE_EDITED_CONTENT(reqId, selectedItem),
      payload,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const downloadGeneratedFiles = async (requestId: string) => {
  try {
    const response = await axios.get<string, AxiosResponse<DownloadRequestResponse>>(
      ROUTES.GET_DOWNLOAD_URL_BY_ID(requestId),
    );
    return response.data;
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

export const chatAPICall = async (payload: TPromptResultPayload) => {
  try {
    const response = await axios.post<TPromptResultPayload, AxiosResponse<TPromptResultResponse>>(
    ROUTES.GENERATE_PROMPT_RESULT('useClientInfo=false'),
      payload,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

// Regenerate subsections (header names service call)
export const regenerateHeaderAPI = async (payload: RegeneratePayload) => {
  try {
    const regenrateHeaderResponse = await axios.post(
      ROUTES.REGENERATE_HEADERS(),
      payload,
    );
    return regenrateHeaderResponse.data;
  } catch (error) {
    throw error;
  }
};

//save regenerated headers API
export const saveRegeneratedHeader = async (payload: SaveHeadersPayload) => {
  try {
    let savePaylaod = payload;
    const saveRegenerateResponse = await axios.post(
      ROUTES.SAVE_REGENERATED_HEADERS(savePaylaod.request_id),
      {
        section: savePaylaod.section,
        subSections: savePaylaod.subSections,
      },
    );
    return saveRegenerateResponse.data;
  } catch (error) {
    throw error;
  }
};

export const fetchExistingFiles = async (payload: TExistingFilesPayload) => {
  try {
    const response: AxiosResponse<TFetchFilesResponse> = await axios.get(
      ROUTES.GET_EXISTING_FILES_BY_ID(payload),
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}