import axios, { AxiosResponse } from "axios";
import { TPlanEstimates, TProjectAveragePayload, TProjectAvgResponse, TProjectTimelineResponse, TProjectVisualDataPayload, TProjectVisualPayload, TProjectVisualsResponse, TSaveProjectTimelineDataPayload, TSaveProjectTimelinePayload, TSaveProjectTimelineResponse } from "../../models/ProjectPlan.model";



export const fetchProjectTimelineService= async (requestId: string) => {
    try {
      const response = await axios.get<TProjectTimelineResponse>(
        `${process.env.REACT_APP_BASE_URL}/api/phase-zero/request/ProjectPlanEstimator/${requestId}`,
      );
      return response.data?.response;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };


export const fetchProjectTimelineServiceAverage= async (payload: TProjectAveragePayload): Promise<TProjectAvgResponse> => {
  try {
    const response: AxiosResponse<TProjectAvgResponse> = await axios.post<TProjectAveragePayload, AxiosResponse<TProjectAvgResponse>>(
      `${process.env.REACT_APP_BASE_URL}/api/phase-zero/request/ProjectPlan/estimate`, payload);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const saveProjectTimelineService= async (payload: TSaveProjectTimelinePayload): Promise<TSaveProjectTimelineResponse> => {
  try {
    const response: AxiosResponse<TSaveProjectTimelineResponse> = await axios.post<TSaveProjectTimelineDataPayload[], AxiosResponse<TSaveProjectTimelineResponse>>(
      `${process.env.REACT_APP_BASE_URL}/api/phase-zero/request/ProjectPlan/save/${payload.requestId}`, payload.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const generateProjectVisualsService= async (payload: TProjectVisualPayload): Promise<TPlanEstimates[]> => {
  try {
    const response: AxiosResponse<TProjectVisualsResponse> = await axios.post<TProjectVisualDataPayload, AxiosResponse<TProjectVisualsResponse>>(
      `${process.env.REACT_APP_BASE_URL}/api/phase-zero/request/ProjectPlanEstimator/${payload.requestId}`, payload.data );
    return response.data.response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};