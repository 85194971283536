import { put, call } from 'redux-saga/effects';
import { AxiosError } from 'axios';
import { fetchSectionDetails, fetchSubSectionDetails } from '../../services/DmapPhase0Services/ReviewPageService';
import { TSectionResponse, TSubSectionResponse } from '../DmapPhase0State/LandingPageState';
import { TProcessMaturityResponse, TProcessMaturityTable } from '../../models/ProcessMaturity.model';
import { TProcessMaturityRequest, TProcessMaturitySubSectionRequest } from '../DmapPhase0State/ProcessMaturity.state';
import { fetchProcessMaturityError, fetchProcessMaturitySubSectionError, fetchProcessMaturitySubSectionSuccess, fetchProcessMaturitySuccess } from '../DmapPhase0Actions/ProcessMaturity.actions';

/**
 * Saga to fetch process maturity data section
 * @param action 
 */
export function* fetchProcessMaturitySaga(action: TProcessMaturityRequest) {
  try {
    const { reqId, selectedSection, pageSize } = action.payload
    const response: TProcessMaturityResponse = yield call(fetchSectionDetails<TProcessMaturityResponse>, reqId, selectedSection, pageSize);
    yield put(fetchProcessMaturitySuccess(response.response));
  } catch (error) {
    yield put(fetchProcessMaturityError(error as AxiosError));
  }
}

/**
 * Saga to fetch process maturity data subsection
 * @param action 
 */
export function* fetchProcessMaturitySubSectionSaga(action: TProcessMaturitySubSectionRequest): Generator<any, void, any> {
  try {
    const data: TSectionResponse<TProcessMaturityTable> = yield call(fetchSubSectionDetails<TSubSectionResponse<TProcessMaturityTable>>, action.payload);
    yield put(fetchProcessMaturitySubSectionSuccess(data?.response?.[0]));
  } catch (error) {
    yield put(fetchProcessMaturitySubSectionError(error));
  }
}
