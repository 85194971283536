import { put, call } from 'redux-saga/effects';
import { AxiosError } from 'axios';
import { fetchSectionDetails, fetchSubSectionDetails } from '../../services/DmapPhase0Services/ReviewPageService';
import { TKDDResponse, TKDDSaveResponse, TKDDTable } from '../../models/KDD.model';
import { TKDDGenerateRequest, TKDDRequest, TKDDSubSectionRequest, TSaveKDDRequest } from '../DmapPhase0State/KDD.state';
import { fetchKDDError, fetchKDDSubSectionError, fetchKDDSubSectionSuccess, fetchKDDSuccess, generateKDDSuccess, generateKDDailure, saveKDDFailure, saveKDDSuccess } from '../DmapPhase0Actions/KDD.actions';
import { TSectionResponse, TSubSectionResponse } from '../DmapPhase0State/LandingPageState';
import { generateKDDData, saveKDDData } from '../../services/DmapPhase0Services/KDD.service';

/**
 * Saga to fetch KDD data section
 * @param action 
 */
export function* fetchKDDSaga(action: TKDDRequest) {
  try {
    const response: TKDDResponse = yield call(fetchSectionDetails<TKDDResponse>, action.payload.reqId, action.payload.selectedSection, action.payload.pageSize);
    yield put(fetchKDDSuccess(response.response));
  } catch (error) {
    yield put(fetchKDDError(error as AxiosError));
  }
}

/**
 * Saga to fetch KDD data subsection
 * @param action 
 */
export function* fetchKDDSubSectionSaga(action: TKDDSubSectionRequest): Generator<any, void, any> {
  try {
    const data: TSectionResponse<TKDDTable> = yield call(fetchSubSectionDetails<TSubSectionResponse<TKDDTable>>, action.payload);
    yield put(fetchKDDSubSectionSuccess(data?.response?.[0]));
  } catch (error) {
    yield put(fetchKDDSubSectionError(error));
  }
}

/**
 * Saga to generate KDD data using prompt
 * @param action 
 */
export function* generateKDDSaga(action: TKDDGenerateRequest): Generator<any, void, any> {
  try {
    const data: TSubSectionResponse<TKDDTable> = yield call(generateKDDData, action.payload);
    yield put(generateKDDSuccess(data));
  } catch (error) {
    yield put(generateKDDailure(error, action.payload.subsection));
  }
}

/**
 * Saga to generate KDD data using prompt
 * @param action 
 */
export function* saveKDDSaga(action: TSaveKDDRequest): Generator<any, void, any> {
  try {
    const data: TKDDSaveResponse = yield call(saveKDDData, action.payload);
    yield put(saveKDDSuccess(data));
  } catch (error) {
    yield put(saveKDDFailure(error));
  }
}
