import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import 'antd/dist/antd.less';
import ReusableModal from '../../Components/FormModal/ReusableModal';
import './Reviewscreen.less';
import { connect } from 'react-redux';
import { RequestPayload } from '../../libs/models/ReviewScreenModels';
import DynamicForm from '../../Components/DynamicForm/DynamicForm';
import { useForm } from 'antd/lib/form/Form';
import { Dispatch } from 'redux';
import {
  getGenerateModalErrMsg,
  getGenerateSectionForm,
  getGenerateSectionOptions,
  getPayloadForGenerateSection,
  isGenSectionFormValid,
} from '../../utils/ReviewScreen.util';
import { fetchRequestByIdRequest, putGenerateSlideContentRequest } from '../../libs/store/DmapPhase0Actions/LandingPageActions';
import { DataState, TRootState } from '../../libs/store/DmapPhase0State/LandingPageState';
import { TSectionDetails } from '../../libs/models/landingPageModels';

interface Props {
  visible: boolean;
  onCancel: () => void;
  requestId: string;

  sections?: TSectionDetails[];
  requestDetails?: Record<string, any>;
  processDetails?: DataState;

  fetchRequest?: (rqstId: number) => void;
  generateSection?: (payload: RequestPayload) => void;
}

interface State {
  loading: boolean;
  selectedSections: Record<string, any>;
  isFormValidated: boolean;
}

const GenerateSection = (props: Props) => {
  const [form] = useForm();
  const saveDisabledConditions = ['In Progress', 'Pending']

  const [state, setState] = useState<State>({
    loading: false,
    selectedSections: {},
    isFormValidated: false,
  });

  useEffect(() => {
    props.visible && props.requestId && props.fetchRequest && props.fetchRequest(parseInt(props.requestId))

    if(props.processDetails?.data && state.loading){
      setState((prevState: State): State => ({
        ...prevState,
        selectedSections: {},
        isFormValidated: false,
        loading: false
      }))
      props.onCancel()
    }
  }, [props.visible, props.processDetails])

  /**
   * Method to handle generation of the section
   * @param values 
   */
  const handleGenSecSubmit = async (values: any) => {
    setState(
      (prevState: State): State => ({
        ...prevState,
        isFormValidated: true,
      }),
    );
    if (isGenSectionFormValid(state.selectedSections)) {
      const reqId = parseInt(props.requestId);
      const payload = getPayloadForGenerateSection(reqId, state.selectedSections);
      setState(
        (prevState: State): State => ({
          ...prevState,
          loading: true,

        }),
      );
      props.generateSection && props.generateSection(payload)
      // props.onCancel();
    } else {
      setState(
        (prevState: State): State => ({
          ...prevState,
          loading: false,
        }),
      );
    }
  };

  /**
   * Mthod to handle modal cancel click
   */
  const handleCancel = () => {
    setState(
      (prevState: State): State => ({
        ...prevState,
        selectedSections: [],
        isFormValidated: false
      }),
    );
    props.onCancel(); // Close the modal or perform any other necessary actions
  };

  return (
    <ReusableModal
      visible={props.visible}
      onCancel={handleCancel}
      title="Generate Section"
      cancelButtonText="Cancel"
      submitButtonText="Generate"
      onSubmit={handleGenSecSubmit}
      submitDisabled={saveDisabledConditions.includes(props.requestDetails?.status)}
      errMsg={getGenerateModalErrMsg(props.requestDetails?.status, saveDisabledConditions, state.isFormValidated, state.selectedSections)}
    >
      <Spin spinning={state.loading}>
        <DynamicForm
          fieldsConfig={getGenerateSectionForm(props.sections || [])}
          form={form}
          options={{
            ...getGenerateSectionOptions(props.sections || []),
          }}
          selectedValues={state.selectedSections}
          setSelectedValues={(values: Record<string, any>) => {
            setState(
              (prevState: State): State => ({
                ...prevState,
                selectedSections: values,
              }),
            );
          }}
        />
      </Spin>
    </ReusableModal>
  );
};

const mapStateToProps = (state: any) => ({
  sections: state.sectionsById?.data,
  requestDetails: state.fetchRequestId?.data,
  processDetails: state.postProcessRequestgeneration
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRequest: (rqstId: number) => dispatch(fetchRequestByIdRequest(rqstId)),
  generateSection: (payload: RequestPayload) => dispatch(putGenerateSlideContentRequest(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateSection);
