import { takeLatest, put, call } from 'redux-saga/effects';
import {
  createNewRequestAPI,
  fetchAllSectionDetails,
  fetchProjectTypes,
  fetchRequestById,
  fetchRequestDetailsData,
  processRequestGenerateSlideContent,
} from '../../services/DmapPhase0Services/LandingPageService';
import {
  fetchDataSuccess,
  fetchDataFailure,
  createRequestFailure, 
  CREATE_NEW_REQUEST,
  GET_REQUEST_BY_ID_REQUEST,
  fetchRequestByIdFailure,
  fetchRequestByIdSuccess,
  generateSlideContentFailure,
  generateSlideContentSuccess,
  PROCESS_GENERATE_SLIDE_CONTENT_REQUEST,
  PUT_EDITED_CONTENT_TO_SAVE_REQUEST,
  putEditedContentToSaveFailure,
  putEditedContentToSaveSuccess,
  FETCH_DOWNLOAD_REQUEST,
  fetchDownloadFailure,
  fetchDownloadSuccess,
  chatPromptFailure,
  chatPromptSuccess,
  CHAT_PROMPT_REQUEST,
  chatPromptRequest,
  PUT_PROCESS_GENERATE_SLIDE_CONTENT_REQUEST,
  modifyHeaderSuccess,
  modifyHeaderFailure,
  // uploadFileRequest,
  MODIFY_HEADER_REQUEST,
  saveHeadersFailure,
  saveHeadersSuccess,
  SAVE_REGENERATE_HEADER_REQUEST,
  fetchProjectTypeSuccess,
  fetchProjectTypeFailure,
  GET_PROJECT_TYPE_REQUEST,
  fetchAllSectionDetailsSuccess,
  fetchAllSectionDetailsFailure,
} from '../DmapPhase0Actions/LandingPageActions';
import {
  CreateNewRequestResponse,
  TProjectType,
  RequestResponse,
  RequestDetailsResponse,
  TSectionDetails,
} from '../../models/landingPageModels';
import {
  EditiedContentResponse,
  ModifyHeaderResponse,
  ProcessRequestGenSecApiResponse,
} from '../../models/ReviewScreenModels';
import {
  chatAPICall,
  downloadGeneratedFiles,
  putEditedContentToSave,
  regenerateHeaderAPI,
  saveRegeneratedHeader,
} from '../../services/DmapPhase0Services/ReviewPageService';
import { processRequestGenerateSlideContentput } from '../../services/DmapPhase0Services/ReviewScreenApiService';
import { REQUEST_PROJECT_TIMELINE, REQUEST_PROJECT_TIMELINE_AVERAGE, REQUEST_PROJECT_VISUALS, REQUEST_SAVE_PROJECT_TIMELINE } from '../DmapPhase0State/ProjectPlan.state';
import { fetchProjectTimelineDataSaga, fetchProjectTimelineDataSagaAverage, generateProjectVisualsSaga, saveProjectTimelineReportSaga } from './ProjectPlan.saga';
import { FETCH_FILES_REQUEST, GENERATE_DIAGRAM_REQUEST, GET_ARCHITECTURE_DETAILS, GET_ARCHITECTURE_IMAGE, REQUEST_ARCHITECTURE_SUBSECTION, UPLOAD_FILE_REQUEST } from '../DmapPhase0State/Architecture.state';
import { fetchArchExistingFilesSaga, fetchArchitectureImgSaga, fetchArchitectureSaga, fetchArchitectureSubSectionSaga, generateDiagramSaga, uploadFile } from './Architecture.saga';
import { GET_PROCESS_SIMPLIFICATION_DETAILS } from '../DmapPhase0State/ProcessSimplification.state';
import { fetchProcessSimplificationData } from './ProcessSimplification.saga';
import { downloadFileSaga, fetchWorkshopEnablersSaga, fetchWorkshopEnablersSubSectionSaga } from './WorkshopEnablers.saga';
import { REQUEST_DOWNLOAD_FILE, REQUEST_WORKSHOP_ENABLERS, REQUEST_WORKSHOP_ENABLERS_SUBSECTION } from '../DmapPhase0State/WorkshopEnablers.state';
import { GENERATE_KDD_REQUEST, REQUEST_KDD, REQUEST_KDD_SUBSECTION, SAVE_KDD_REQUEST } from '../DmapPhase0State/KDD.state';
import { fetchKDDSaga, fetchKDDSubSectionSaga, generateKDDSaga, saveKDDSaga } from './KDD.saga';
import { REQUEST_PROCESS_MATURITY, REQUEST_PROCESS_MATURITY_SUBSECTION } from '../DmapPhase0State/ProcessMaturity.state';
import { fetchProcessMaturitySaga, fetchProcessMaturitySubSectionSaga } from './ProcessMaturity.saga';
import { FETCH_EXISTING_FILES, SECTIONS_BY_ID_REQUEST } from '../DmapPhase0State/ReviewScreen.state';
import { fetchExistingFilesSaga, fetchSectionsByIdSaga } from './ReviewScreen.saga';
import { REQUEST_EXECUTIVE_SUMMARY } from '../DmapPhase0State/ExecutiveSummary.state';
import { fetchExecutiveSummarySaga } from './ExecutiveSummary.saga';
import { ALL_SECTION_DETAILS_REQUEST, FetchDataRequestAction, TAllSectionDetailsRequest, TTableResponse } from '../DmapPhase0State/LandingPageState';
import { TPromptResultResponse } from '../../models/ChatPrompt.model';
import { APPLY_CHAT_TO_ALL_REQUEST, PROMPT_RESULT_REQUEST } from '../DmapPhase0State/ChatPrompt.state';
import { applyChatToAllSaga, fetchPromptResultSaga } from './ChatPrompt.saga';
import { AxiosError } from 'axios';

// table data Saga
function* fetchDataSaga(action: FetchDataRequestAction) {
  try {
    const data: TTableResponse<RequestDetailsResponse> = yield call(fetchRequestDetailsData, action.payload);
    yield put(fetchDataSuccess(data));
  } catch (error) {
    yield put(fetchDataFailure(error));
  }
}
// create Request Saga
function* createRequestSaga(action: any) {
  try {
    const { requestName, projectTypeId, sections, uploadPayload, operation } = action.payload;
    const response: CreateNewRequestResponse = yield call(
      createNewRequestAPI,
      requestName,
      projectTypeId,
      sections,
      uploadPayload,
      operation
    );
    yield call(
      processRequestGenerateSlideContent,
      parseInt(response.requestId),
      uploadPayload.prefixes,
      operation,
      uploadPayload.files,
      sections?.split(',')
    );      
  } catch (error) {
    yield put(createRequestFailure(error));
  }
} 
// process APi saga (Gen AI call)
export function* processRequestGenerateSlideContentSaga(action: any) {
  try {
    const { requestId, filePrefixes, operation,files, sections } = action.payload;
    const response: CreateNewRequestResponse = yield call(
      processRequestGenerateSlideContent,
      requestId,
      filePrefixes,
      operation,
      files,
      sections
    );
    yield put(generateSlideContentSuccess(response));
  } catch (error) {
    yield put(generateSlideContentFailure(error));
  }
}

// API for getting all the section details
export function* fetchAllSectionDetailsSaga(action: TAllSectionDetailsRequest) {
  try {
     const data: TSectionDetails[] = yield call(fetchAllSectionDetails, action.payload);
    yield put(fetchAllSectionDetailsSuccess(data));
  } catch (error) {
    yield put(fetchAllSectionDetailsFailure(error as AxiosError));
  }
}

// API to get types of project
export function* fetchProjectTypeSaga(action: any) {
  try {
     const data: TProjectType[] = yield call(fetchProjectTypes);
    yield put(fetchProjectTypeSuccess(data));
  } catch (error) {
    yield put(fetchProjectTypeFailure(error));
  }
}

// get details by id saga
export function* fetchRequestByIdSaga(action: any) {
  try {
    const { requestId } = action.payload;
    const response: RequestResponse = yield call(fetchRequestById, requestId);
    yield put(fetchRequestByIdSuccess(response));
  } catch (error) {
    yield put(fetchRequestByIdFailure(error));
  }
}


// save/update api saga
function* putEditedContentToSaveSaga(action: { type: string; payload: any }) {
  try {
    const { reqId, selectedItem, payload } = action.payload;
    const responseData: EditiedContentResponse = yield call(
      putEditedContentToSave,
      reqId,
      selectedItem,
      // editedContent,
      payload
    );
    yield put(putEditedContentToSaveSuccess(responseData));
  } catch (error) {
    yield put(putEditedContentToSaveFailure(error));
  }
}
// download file saga
export function* fetchDownloadGeneretedlink(action: any) {
  try {
    const { requestId } = action.payload;
    const response: RequestResponse  = yield call(downloadGeneratedFiles, requestId);
    yield put(fetchDownloadSuccess(response));
  } catch (error) {
    yield put(fetchDownloadFailure(error));
  }
}

// chat api saga
export function* chatPromptAPISaga(action: ReturnType<typeof chatPromptRequest>) {
  try {   
    const response: TPromptResultResponse = yield call(chatAPICall, action.payload);
    yield put(chatPromptSuccess(response));
  } catch (error) {
    yield put(chatPromptFailure(error));
  }
}

//put process Saga
export function* putProcessRequestGenerateSlideContentSaga(action: any) {
  try {
    const { payload } = action;
    const response: CreateNewRequestResponse = yield call(processRequestGenerateSlideContentput, payload);
    yield put(generateSlideContentSuccess(response));
  } catch (error) {
    yield put(generateSlideContentFailure(error));
  }
}

// regenerate header saga

export function* modifyHeaderSaga(action: { type: 'MODIFY_HEADER_REQUEST'; payload: any }) {
  try {
    const { payload } = action;
    const response: ModifyHeaderResponse = yield call(regenerateHeaderAPI, payload);
    yield put(modifyHeaderSuccess(response));
  } catch (error) {
    yield put(modifyHeaderFailure(error));
  }
}

//save headers saga
export function* saveRegeneratedHeaderSaga(action : any) {
  try {
    const response:ProcessRequestGenSecApiResponse = yield call(saveRegeneratedHeader, action.payload);
    yield put(saveHeadersSuccess(response));
  } catch (error) {
    yield put(saveHeadersFailure(error));
  }
}



export default function* rootSaga() {
  yield takeLatest('FETCH_ALL_REQUESTS', fetchDataSaga);
  yield takeLatest(CREATE_NEW_REQUEST, createRequestSaga);
  // yield takeLatest(UPLOAD_NEW_FILE_REQUEST, uploadFileSaga);
  yield takeLatest(PROCESS_GENERATE_SLIDE_CONTENT_REQUEST, processRequestGenerateSlideContentSaga);
  yield takeLatest(GET_REQUEST_BY_ID_REQUEST, fetchRequestByIdSaga);
  yield takeLatest(ALL_SECTION_DETAILS_REQUEST, fetchAllSectionDetailsSaga);
  yield takeLatest(GET_PROJECT_TYPE_REQUEST, fetchProjectTypeSaga);
  yield takeLatest(SECTIONS_BY_ID_REQUEST, fetchSectionsByIdSaga);
  yield takeLatest(PUT_EDITED_CONTENT_TO_SAVE_REQUEST, putEditedContentToSaveSaga);
  yield takeLatest(FETCH_DOWNLOAD_REQUEST, fetchDownloadGeneretedlink);
  yield takeLatest(CHAT_PROMPT_REQUEST, chatPromptAPISaga);
  yield takeLatest(
    PUT_PROCESS_GENERATE_SLIDE_CONTENT_REQUEST,
    putProcessRequestGenerateSlideContentSaga,
  );
  yield takeLatest(MODIFY_HEADER_REQUEST, modifyHeaderSaga);
  yield takeLatest(SAVE_REGENERATE_HEADER_REQUEST, saveRegeneratedHeaderSaga);
  yield takeLatest(REQUEST_PROJECT_TIMELINE, fetchProjectTimelineDataSaga);
  yield takeLatest(REQUEST_PROJECT_TIMELINE_AVERAGE, fetchProjectTimelineDataSagaAverage);
  yield takeLatest(REQUEST_SAVE_PROJECT_TIMELINE, saveProjectTimelineReportSaga);
  yield takeLatest(REQUEST_PROJECT_VISUALS, generateProjectVisualsSaga);

  // Architecture Saga
  yield takeLatest(GET_ARCHITECTURE_DETAILS, fetchArchitectureSaga);
  yield takeLatest(REQUEST_ARCHITECTURE_SUBSECTION, fetchArchitectureSubSectionSaga);
  yield takeLatest(GET_ARCHITECTURE_IMAGE, fetchArchitectureImgSaga);
  yield takeLatest(FETCH_FILES_REQUEST, fetchArchExistingFilesSaga);
  yield takeLatest(UPLOAD_FILE_REQUEST, uploadFile);
  yield takeLatest(GENERATE_DIAGRAM_REQUEST, generateDiagramSaga);

  //Process Simplification
  yield takeLatest(GET_PROCESS_SIMPLIFICATION_DETAILS, fetchProcessSimplificationData);

  // Workshop Enablers
  yield takeLatest(REQUEST_WORKSHOP_ENABLERS, fetchWorkshopEnablersSaga);
  yield takeLatest(REQUEST_WORKSHOP_ENABLERS_SUBSECTION, fetchWorkshopEnablersSubSectionSaga);
  yield takeLatest(REQUEST_DOWNLOAD_FILE, downloadFileSaga);

  // KDD
  yield takeLatest(REQUEST_KDD, fetchKDDSaga);
  yield takeLatest(REQUEST_KDD_SUBSECTION, fetchKDDSubSectionSaga);
  yield takeLatest(GENERATE_KDD_REQUEST, generateKDDSaga);
  yield takeLatest(SAVE_KDD_REQUEST, saveKDDSaga);

  // Process Maturity
  yield takeLatest(REQUEST_PROCESS_MATURITY, fetchProcessMaturitySaga);
  yield takeLatest(REQUEST_PROCESS_MATURITY_SUBSECTION, fetchProcessMaturitySubSectionSaga);
  
  // Fetch Existing Files
  yield takeLatest(FETCH_EXISTING_FILES, fetchExistingFilesSaga);

  // Chat
  yield takeLatest(PROMPT_RESULT_REQUEST, fetchPromptResultSaga);
  yield takeLatest(APPLY_CHAT_TO_ALL_REQUEST, applyChatToAllSaga);

  // Executive Summary of RFP
  yield takeLatest(REQUEST_EXECUTIVE_SUMMARY, fetchExecutiveSummarySaga);
}





