import React, { useEffect, useState } from 'react';
import ReusableModal from '../../Components/FormModal/ReusableModal';
import { Divider, Form, Progress, Spin } from 'antd';
import { connect } from 'react-redux';
import {
  modifyHeaderRequest,
  modifyHeaderReset,
  saveHeadersRequest,
} from '../../libs/store/DmapPhase0Actions/LandingPageActions';
import './ReviewModals.less';
import DynamicForm from '../../Components/DynamicForm/DynamicForm';
import {
  ModifyHeaderResponse,
  RegeneratePayload,
  SaveHeadersPayload,
} from '../../libs/models/ReviewScreenModels';
import {
  REGENEERATE_SECTION_FIELDS,
  REGENERATE_HEADERS_FIELDS,
} from '../../libs/constants/ReviewScreen.const';
import { Dispatch } from 'redux';
import {
  getPayloadForRegenerateHeaders,
  getPayloadForRegenerateSubmit,
  getRegenerateModalErrMsg,
  getRegenerateSectionHeaderOptions,
  isRegenerateBtnDisabled,
} from '../../utils/ReviewScreen.util';
import { TSectionDetails } from '../../libs/models/landingPageModels';
import { TRootState } from '../../libs/store/DmapPhase0State/LandingPageState';

interface Props {
  visible: boolean;
  onCancel: () => void;
  requestId: string;

  sections?: TSectionDetails[] | null;
  headerResponse?: ModifyHeaderResponse | null;
  headersList?: TSectionDetails[] | null;

  resetHeaders?: () => void;
  modifyHeader?: (payload: RegeneratePayload) => any;
  saveHeaders?: (payload: SaveHeadersPayload) => any;
}

interface State {
  loading: boolean;
  regenerated: boolean;
  selectedCheckboxes: Record<string, string[]>;
  regenerateErrMsg: string;
}

const RegenerateSubsection: React.FC<Props> = ({visible, onCancel, requestId, sections, headerResponse, headersList, resetHeaders, modifyHeader, saveHeaders}) => {
  const [form] = Form.useForm();
  Form.useWatch(['sections'], form);

  const [state, setState] = useState<State>({
    loading: false,
    regenerated: false,
    selectedCheckboxes: {},
    regenerateErrMsg: '',
  });
  useEffect(() => {
    if (!visible && resetHeaders) {
      resetHeaders();
    }
  }, [visible]);

  useEffect(() => {
    if (headerResponse !== undefined && headerResponse !== null) {
      setState(
        (prevState: State): State => ({
          ...prevState,
          loading: false,
        }),
      );

    }
  }, [headerResponse]);

  const handleSaveHeaders = () => {
    const payload = getPayloadForRegenerateSubmit(
      requestId,
      form.getFieldValue('sections'),
      headerResponse?.response || [],
      state.selectedCheckboxes?.headers,
    );
    saveHeaders && saveHeaders(payload);
    form.resetFields();
    resetHeaders && resetHeaders();
    setState(
      (prevState: State): State => ({
        ...prevState,
        loading: false,
        regenerated: false,
        selectedCheckboxes: {},
      }),
    );
    onCancel();
  };

  const onCancelForm = () => {
    form.resetFields();
    resetHeaders && resetHeaders();
    setState(
      (prevState: State): State => ({
        ...prevState,
        loading: false,
        regenerated: false,
      }),
    );
    onCancel();
  };

  const handleRegenerateSubsectionSubmit = async () => {
    form.validateFields().then(async (values: any) => {
      setState(
        (prevState: State): State => ({
          ...prevState,
          loading: true,
          regenerated: true,
        }),
      );
      const payload = getPayloadForRegenerateHeaders(
        requestId,
        values,
        headersList || [],
      );
      resetHeaders && resetHeaders();
      modifyHeader && modifyHeader(payload);
    });
  };

  return (
    <ReusableModal
      visible={visible}
      title="Regenerate sub-sections"
      closable={true}
      submitButtonText="Save"
      showCancelBtn={false}
      onSubmit={handleSaveHeaders}
      submitDisabled={!(state.selectedCheckboxes?.headers?.length > 0)}
      extraBtnText={'Regenerate'}
      extraBtnClick={handleRegenerateSubsectionSubmit}
      errMsg={getRegenerateModalErrMsg(form.getFieldValue('sections'), headersList || [])}
      extraBtnDisabled={isRegenerateBtnDisabled(
        state.loading,
        form.getFieldValue('sections'),
        headersList || [],
      )}
      onCancel={onCancelForm}
    >
      <Spin spinning={state.loading}>
        <DynamicForm
          form={form}
          fieldsConfig={REGENEERATE_SECTION_FIELDS}
          submit={handleRegenerateSubsectionSubmit}
          options={{
            'sections': sections
              ?.filter(
                (section: TSectionDetails) => section.sectionConstant === 'Transformation Deepdive',
              )
              ?.map((section: TSectionDetails) => ({
                label: section.section,
                value: section.sectionConstant,
              })),
          }}
          initialValues={{
            'sections': 'Transformation Deepdive',
          }}
        />
      </Spin>

      <div>
        {state.regenerated && (
          <div>
            {state.loading ? (
              <div className="progress-container">
                <Progress
                  percent={100}
                  showInfo={false}
                  status="active"
                  className="loading-progress"
                  strokeColor={'#55A98F'}
                />
                <span className="loading-text">Regenerating the sub-sections...</span>
              </div>
            ) : (
              <>
                <Divider className="results-divider" />
                <span className="preview-text">Preview </span>
                <DynamicForm
                  fieldsConfig={REGENERATE_HEADERS_FIELDS}
                  form={form}
                  defaultActiveKey={'headers'}
                  options={{
                    ...getRegenerateSectionHeaderOptions(headerResponse?.response || []),
                  }}
                  selectedValues={state.selectedCheckboxes}
                  setSelectedValues={(values: Record<string, any>) => {
                    setState(
                      (prevState: State): State => ({
                        ...prevState,
                        selectedCheckboxes: values,
                      }),
                    );
                  }}
                />
              </>
            )}
          </div>
        )}
      </div>
    </ReusableModal>
  );
};

const mapStateToProps = (state: TRootState) => ({
  sections: state.sectionsById?.data,
  headerResponse: state.regenerateHeader?.data,
  headersList: state.sectionsById?.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetHeaders: () => dispatch(modifyHeaderReset()),
  modifyHeader: (payload: RegeneratePayload) => dispatch(modifyHeaderRequest(payload)),
  saveHeaders: (payload: SaveHeadersPayload) => dispatch(saveHeadersRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegenerateSubsection);
