
import "./App.less";
import  Reviewscreen from "./container/ReviewScreen/Reviewscreen";
import LandingPage from "./container/LandingPage/LandingPage";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router basename="/dmap-phase-0">
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <Route path="/review/:id" component={Reviewscreen} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
