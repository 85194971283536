import { AxiosError } from 'axios';
import { TSubSectionResponse } from './LandingPageState';
import { TProcessSimpDetailsPayload } from '../../models/ProcessSimplification.model';
import { TSubsectionPromptResult } from '../../models/ChatPrompt.model';

export const GET_PROCESS_SIMPLIFICATION_DETAILS = 'GET_PROCESS_SIMPLIFICATION_DETAILS';
export const GET_PROCESS_SIMPLIFICATION_DETAILS_SUCCESS = 'GET_PROCESS_SIMPLIFICATION_DETAILS_SUCCESS';
export const GET_PROCESS_SIMPLIFICATION_DETAILS_ERROR = 'GET_PROCESS_SIMPLIFICATION_DETAILS_ERROR';
export const RESET_PROCESS_SIMPLIFICATION_DATA = 'RESET_PROCESS_SIMPLIFICATION_DATA';

export type TProcessSimpDetailsRequest = {
  type: 'GET_PROCESS_SIMPLIFICATION_DETAILS';
  payload: TProcessSimpDetailsPayload;
};
export type TProcessSimpDetailsSuccess = {
  type: 'GET_PROCESS_SIMPLIFICATION_DETAILS_SUCCESS';
  payload: TSubSectionResponse<string>[];
};

export type TProcessSimpDetailsError = {
  type: 'GET_PROCESS_SIMPLIFICATION_DETAILS_ERROR';
  payload: AxiosError;
};

export type TSetProcessSimpEditedData = {
  type: 'SET_PROCESS_SIMPLIFICATION_EDITED_DATA';
  payload: TSubsectionPromptResult;
}

export type TSetProcessSimpExistingData = {
  type: 'SET_PROCESS_SIMPLIFICATION_EXISTING_DATA';
  payload: TSubsectionPromptResult;
}

export type TResetProcessSimpDetails = {
  type: 'RESET_PROCESS_SIMPLIFICATION_DATA';
};

export type TProcessSimpDetailsActionTypes = TProcessSimpDetailsRequest | TProcessSimpDetailsSuccess | TProcessSimpDetailsError;

export type ProcessSimplificationActionTypes =
  TProcessSimpDetailsActionTypes
  | TSetProcessSimpExistingData
  | TSetProcessSimpEditedData
  | TResetProcessSimpDetails;