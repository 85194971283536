import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import './ProcessSimplification.less';
import { updateSaveValue } from '../../../../libs/store/DmapPhase0Actions/LandingPageActions';
import 'quill/dist/quill.snow.css';
import { UnprivilegedEditor } from 'react-quill';
import { DeltaStatic, Sources } from 'quill';
import {
  fetchProcessSimplificationDetails,
  resetProcessSimpDetails,
  setProcessSimpEditedData,
  setProcessSimpExistingData,
} from '../../../../libs/store/DmapPhase0Actions/ProcessSimplification.actions';
import ChatPrompt from '../../../../Components/ChatPrompt/ChatPrompt';
import {
  TDataState,
  TRootState,
  TSaveFlagAction,
  TSubSectionResponse,
} from '../../../../libs/store/DmapPhase0State/LandingPageState';
import {
  TProcessSimpDetailsRequest,
  TResetProcessSimpDetails,
  TSetProcessSimpEditedData,
  TSetProcessSimpExistingData,
} from '../../../../libs/store/DmapPhase0State/ProcessSimplification.state';
import {
  TProcessSimpDetailsPayload,
  TProcessSimplificationState,
} from '../../../../libs/models/ProcessSimplification.model';
import {
  TPromptResultPayload,
  TSubsectionPromptResult,
} from '../../../../libs/models/ChatPrompt.model';
import {
  applyChatToAllRequest,
  fetchPromptResult,
  resetApplyChatToAll,
  resetPromptResult,
} from '../../../../libs/store/DmapPhase0Actions/ChatPrompt.actions';
import {
  TApplyChatToAllRequest,
  TPromptResultRequest,
  TResetApplyChatToAll,
  TResetPromptResult,
} from '../../../../libs/store/DmapPhase0State/ChatPrompt.state';
import {
  getLoadingText,
  getPayloadForGenerateAndApplyPrompt,
} from '../../../../utils/ProcessSimplification.utils';
import { TSaveEditsResponse } from '../../../../libs/models/landingPageModels';
import RichTextEditor from '../../../../Components/RichTextEditor/RichTextEditor';

interface ProcessSimplificationProps {
  selectedItem: string;
  onSelectedMenuItem: string;
  requestName: string;
  startPolling: () => void;
  stopPolling: () => void;
  updateSideNav: () => void;
  onSaveData: (data: any) => void;

  details?: TProcessSimplificationState;
  promptResultDetails?: TDataState<TSubsectionPromptResult> | null;
  applyChatToAllDetails?: TDataState<TSubsectionPromptResult> | null;
  saveEditsDetails?: TDataState<TSaveEditsResponse>;

  updateSaveEnabled?: (enabled: boolean) => TSaveFlagAction;
  getProcessSimpDetails?: (payload: TProcessSimpDetailsPayload) => TProcessSimpDetailsRequest;
  resetSubsectionDetails?: () => TResetProcessSimpDetails;
  generatePromptResult?: (payload: TPromptResultPayload) => TPromptResultRequest;
  resetPromptResults?: () => TResetPromptResult;
  applyChatToAllSubsections?: (payload: TPromptResultPayload) => TApplyChatToAllRequest;
  resetApplyToAllResults?: () => TResetApplyChatToAll;
  setExistingData?: (payload: TSubsectionPromptResult) => TSetProcessSimpExistingData;
  setEditedData?: (payload: TSubsectionPromptResult) => TSetProcessSimpEditedData;
}

const ProcessSimplification: React.FC<ProcessSimplificationProps> = ({
  selectedItem,
  onSelectedMenuItem,
  startPolling,
  updateSideNav,

  details,
  promptResultDetails,
  applyChatToAllDetails,
  saveEditsDetails,

  updateSaveEnabled,
  getProcessSimpDetails,
  resetSubsectionDetails,
  generatePromptResult,
  resetPromptResults,
  applyChatToAllSubsections,
  resetApplyToAllResults,
  setExistingData,
  setEditedData,
}) => {
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();
  const [prompt, setPrompt] = useState<string>('');
  const textAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getProcessSimpDetails &&
      getProcessSimpDetails({
        reqId: id,
        selectedItem,
        pageSize: 0,
      });
  }, []);

  useEffect(() => {
    if (Object.values(details?.editedData || {}).length) {
      updateSaveEnabled && updateSaveEnabled(true);
    } else {
      updateSaveEnabled && updateSaveEnabled(false);
    }
  }, [details]);

  useEffect(() => {
    if (promptResultDetails?.loading === false) {
      if (promptResultDetails.data) {
        setEditedData && setEditedData(promptResultDetails.data);
        resetPromptResults && resetPromptResults();
      }
    }
  }, [promptResultDetails]);

  useEffect(() => {
    if (applyChatToAllDetails?.loading === false) {
      if (applyChatToAllDetails.data) {
        setTimeout(() => {
          resetSubsectionDetails && resetSubsectionDetails();
          updateSideNav();
          startPolling();
          setPrompt('')
        }, 2000);
      }
      resetApplyToAllResults && resetApplyToAllResults();
    }
  }, [applyChatToAllDetails]);

  const isChatDisabled = (): boolean => {
    return Boolean(
      details?.existingData?.loading ||
        promptResultDetails?.loading ||
        applyChatToAllDetails?.loading ||
        saveEditsDetails?.loading,
    );
  };

  useEffect(() => {
    if (isChatDisabled()) {
      if (textAreaRef.current) {
        textAreaRef.current.scrollTop = 0;
      }
    }
  }, [isChatDisabled()]);

  const getEditorText = (): string => {
    return (
      details?.editedData?.[onSelectedMenuItem] ||
      details?.existingData?.data?.find(
        (data: TSubSectionResponse<string>) => data.subsection === onSelectedMenuItem,
      )?.data?.response ||
      ''
    );
  };

  const handleRTEChange = (
    content: string,
    delta: DeltaStatic,
    source: Sources,
    editor: UnprivilegedEditor,
  ) => {
    const existingText =
      details?.existingData?.data?.find(
        (data: TSubSectionResponse<string>) => data.subsection === onSelectedMenuItem,
      )?.data?.response || '';
    let trimmed = content.replace(/\n|\r/g, '');
    if (existingText && source === 'user') {
      if (existingText !== trimmed) {
        setEditedData &&
          setEditedData({
            subsection: onSelectedMenuItem,
            data: trimmed,
          });
      } else {
        setEditedData &&
          setEditedData({
            subsection: onSelectedMenuItem,
            data: '',
          });
      }
    } else if (existingText && source === 'api' && trimmed !== existingText) {
      setExistingData &&
        setExistingData({
          subsection: onSelectedMenuItem,
          data: content,
        });
    }
  };

  const handleEnterPress = async (promptText: string) => {
    setPrompt(promptText);
    dispatch(updateSaveValue(selectedItem, true));
    const subsectionsData = [
      {
        subsection: onSelectedMenuItem,
        data: getEditorText(),
      },
    ];
    const payload = getPayloadForGenerateAndApplyPrompt(
      id,
      selectedItem,
      promptText,
      '',
      '',
      subsectionsData,
    );

    generatePromptResult && generatePromptResult(payload);
  };

  const handleApplytoAll = async () => {
    try {
      if (details?.existingData) {
        const subsections =
          details?.existingData?.data
            ?.filter((item: any) => item.subsection !== onSelectedMenuItem)
            .map((item: any) => ({
              subsection: item.subsection,
              data: item.data.response,
            })) || [];

        const payload = getPayloadForGenerateAndApplyPrompt(
          id,
          selectedItem,
          prompt,
          onSelectedMenuItem,
          getEditorText(),
          subsections,
        );
        console.warn(payload)
        applyChatToAllSubsections && applyChatToAllSubsections(payload);
      }
    } catch (error) {
      console.warn('Error', error);
    }
  };

  return (
    <div className="process-simplification-container">
      <div
        ref={textAreaRef}
        className={`text-editor-container ${isChatDisabled() ? 'text-editor-disabled' : ''}`}
      >
        {
          <RichTextEditor
            editable={true}
            editorText={getEditorText()}
            loading={
              isChatDisabled()
                ? getLoadingText(
                    details?.existingData?.loading,
                    promptResultDetails?.loading,
                    saveEditsDetails?.loading,
                    applyChatToAllDetails?.loading,
                  )
                : undefined
            }
            selectedSubsection={onSelectedMenuItem}
            handleChange={handleRTEChange}
          />
        }
      </div>
      <ChatPrompt
        disabled={isChatDisabled()}
        selectedSection={selectedItem}
        selectedSubsection={onSelectedMenuItem}
        subsections={details?.existingData?.data || []}
        promptText={prompt}
        onSubmit={handleEnterPress}
        onApplyAll={handleApplytoAll}
      />
    </div>
  );
};

const mapStateToProps = (state: TRootState) => ({
  details: state.processSimplification,
  promptResultDetails: state.chatPrompt.generateResult,
  applyChatToAllDetails: state.chatPrompt.applyChatToAll,
  saveEditsDetails: state.editedContentToSave,
});

const mapDispatchToProps = (dispatch: Dispatch, { selectedItem }: ProcessSimplificationProps) => ({
  updateSaveEnabled: (enabled: boolean) => dispatch(updateSaveValue(selectedItem, enabled)),
  getProcessSimpDetails: (payload: TProcessSimpDetailsPayload) =>
    dispatch(fetchProcessSimplificationDetails(payload)),
  resetSubsectionDetails: () => dispatch(resetProcessSimpDetails()),
  generatePromptResult: (payload: TPromptResultPayload) => dispatch(fetchPromptResult(payload)),
  resetPromptResults: () => dispatch(resetPromptResult()),
  applyChatToAllSubsections: (payload: TPromptResultPayload) =>
    dispatch(applyChatToAllRequest(payload)),
  resetApplyToAllResults: () => dispatch(resetApplyChatToAll()),
  setExistingData: (payload: TSubsectionPromptResult) =>
    dispatch(setProcessSimpExistingData(payload)),
  setEditedData: (payload: TSubsectionPromptResult) => dispatch(setProcessSimpEditedData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessSimplification);
