import React from 'react';
import { Checkbox, Collapse } from 'antd';
import {
  TCheckBoxGroup,
  TCheckBoxGroupSubValue,
  TFormField,
} from '../../../../libs/models/NewRqst.model';
import { checkboxChange, checkboxSelectAll, getDisabledValuesForCheckboxGroup, getUniqueElements } from '../../../../utils/DynamicForm.util';

interface Props {
  values: TCheckBoxGroup;
  field: TFormField;
  defaultActiveKey?: string | number | string[] | number[];
  checkboxCheckedItems?: Record<string, any>;
  setCheckboxCheckedItems?: (val: Record<string, any>) => any;
}

const CustomCheckboxGroup = (props: Props) => {
  const { Panel } = Collapse;
  const CheckboxGroup = Checkbox.Group;

  const onCheckboxSelectAll = (field: TFormField, checked: boolean) => {
    props.setCheckboxCheckedItems &&
      props.setCheckboxCheckedItems(
        checkboxSelectAll(field, checked, props.values, props.checkboxCheckedItems || {}),
      );
  };

  const onCheckboxChange = (field: TFormField, value: string, checked: boolean) => {
    props.setCheckboxCheckedItems &&
      props.setCheckboxCheckedItems(
        checkboxChange(field, checked, value, props.checkboxCheckedItems || {}),
      );
  };

  const renderCheckboxField = (field: TFormField) => {
    const fieldCheckedSubValues = getUniqueElements(
      field.showArrow ? props.checkboxCheckedItems?.[field.name] : [],
      getDisabledValuesForCheckboxGroup(props.values),
    );

    return (
      <Collapse
        collapsible={field.showArrow ? undefined : 'icon'}
        accordion={true}
        bordered={false}
        defaultActiveKey={props.defaultActiveKey}
        expandIconPosition="end"
      >
        <Panel
          header={
            <div className="checkbox-header-name">
              <Checkbox
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  onCheckboxSelectAll(field, e.target.checked);
                }}
                indeterminate={
                  fieldCheckedSubValues?.length > 0 &&
                  fieldCheckedSubValues?.length < props.values?.subValues?.length
                }
                checked={
                  field.showArrow
                    ? props.values?.subValues?.length === fieldCheckedSubValues?.length
                    : props.checkboxCheckedItems?.[field.name]
                }
              />
              <span className="checkbox-value">{field.placeholderText}</span>
            </div>
          }
          key={field.name}
          showArrow={field.showArrow}
        >
          <CheckboxGroup value={fieldCheckedSubValues}>
            <div className="sub-checkbox-container">
              {/* Adjust margin for indentation */}
              {props.values?.subValues?.map((subVal: TCheckBoxGroupSubValue) => (
                <>
                  <Checkbox
                    className="sub-checkbox"
                    key={subVal.value}
                    defaultChecked
                    disabled={subVal.disabled || false}
                    value={subVal.value}
                    onChange={(e) => onCheckboxChange(field, subVal.value, e.target.checked)}
                  >
                    <span>{subVal?.value}</span>
                  </Checkbox>
                  <br />
                </>
              ))}
            </div>
          </CheckboxGroup>
        </Panel>
      </Collapse>
    );
  };

  return renderCheckboxField(props.field);
};

export default CustomCheckboxGroup;
