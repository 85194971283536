import { TPlanEstimates } from 'libs/models/ProjectPlan.model';
import { maxBy } from 'lodash';
import XLSX from 'sheetjs-style';

const GanttChartColors = ['fc6203', '036ffc', '3103fc', '03f4fc'];
const GanttChartLightColors = ['f7c9a3', 'a3c9f7', 'a3a3f7', 'a3f7f7'];

/**
 * @description Get total weeks from the data
 * @param data array of plan estimates
 * @returns total weeks
 */
export const getTotalWeeks = (data: TPlanEstimates[]) => maxBy(data, 'tasks.end')?.tasks.end || 0;

/**
 * @description Get week column configs from max number of weeks possible
 * @param maxWeeks number of weeks
 * @returns array of week with names. eg. W 01, W 02, W 03, ...
 */
export const getWeekColumnNames = (maxWeeks: number) =>
  Array.from({ length: maxWeeks }, (_, i) => `W ${String(i + 1).padStart(2, '0')}`);

/**
 * @description Export Gantt chart to excel and save it
 * @param data data to be exported
 */
export const exportGanttChartToExcel = (data: TPlanEstimates[], fileName: string) => {
  const totalWeeks = getTotalWeeks(data);
  const workbook = XLSX.utils.book_new();

  // first row should be font bold
  const headers = ['KEY ACTIVITIES', 'DURATION', ...getWeekColumnNames(totalWeeks)];
  const worksheet = XLSX.utils.aoa_to_sheet([headers], { cellStyles: true });

  // set the first row to be bold
  for (let week = 1; week <= headers.length; week++)
    setStyleOrValueOfCell(worksheet, week - 1, 0, true);

  let currentRow = 1;
  data.forEach(({ section, tasks: { start, end }, subsections }, activityIndex) => {
    // set the section name in the first column
    // set the duration in the second column
    // fill the range of cells in the row with the color
    setStyleOrValueOfCell(worksheet, 0, currentRow, true, section);
    setStyleOrValueOfCell(worksheet, 1, currentRow, false, end - start + 1);
    fillRangeOfCellsInRow(worksheet, start, end, currentRow, GanttChartColors[activityIndex % 4]);

    subsections.forEach(({ content, start_subPhase, end_subPhase }, subActivityIndex) => {
      // set the subsection content in the first column
      // set the duration in the second column
      // fill the range of cells for sub activities in the row with the color
      setStyleOrValueOfCell(worksheet, 0, currentRow + subActivityIndex + 1, false, content);
      setStyleOrValueOfCell(
        worksheet,
        1,
        currentRow + subActivityIndex + 1,
        false,
        end_subPhase - start_subPhase + 1,
      );
      fillRangeOfCellsInRow(
        worksheet,
        start_subPhase,
        end_subPhase,
        currentRow + subActivityIndex + 1,
        GanttChartLightColors[activityIndex % 4],
        'thin',
      );
    });

    currentRow += subsections.length + 1;
  });

  // set the range of the worksheet to be exported
  worksheet['!ref'] = `A1:${XLSX.utils.encode_cell({ c: headers.length, r: currentRow })}`;

  // first column should be auto adjusted for width and the rest should be 40px wide
  // hardcoded 6px per character width
  const maxLengthOfSectionSubsection = Math.max(
    ...data.map(({ section, subsections }) =>
      Math.max(section.length, ...subsections.map(({ content }) => content.length)),
    ),
  );
  worksheet['!cols'] = [
    { wch: maxLengthOfSectionSubsection },
    { wch: headers[1].length + 4 },
    ...getWeekColumnNames(getTotalWeeks(data)).map(() => ({ wpx: 40 })),
  ];

  // all rows should be 16px high
  worksheet['!rows'] = Array.from({ length: currentRow + 1 }, () => ({ hpx: 16 }));

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Gantt Chart');
  XLSX.writeFile(workbook, fileName);
};

/**
 * @description Set style or value of a cell in the worksheet
 * @param worksheet worksheet to be modified
 * @param col column index
 * @param row row index
 * @param isBold whether the cell should be bold
 * @param value value to be set in the cell, if any
 */
const setStyleOrValueOfCell = (
  worksheet: XLSX.WorkSheet,
  col: number,
  row: number,
  isBold = false,
  value?: string | number,
) => {
  const cellAddress = XLSX.utils.encode_cell({ c: col, r: row });
  worksheet[cellAddress] = { v: value ?? worksheet[cellAddress]?.v, s: { font: { bold: isBold } } };
};

/**
 * @description Fill range of cells in a row with a color
 * @param worksheet worksheet to be modified
 * @param start start index of the range
 * @param end end index of the range
 * @param row row index
 * @param fillColor color to be filled in the range
 */
const fillRangeOfCellsInRow = (
  worksheet: XLSX.WorkSheet,
  start: number,
  end: number,
  row: number,
  fillColor: string,
  borderStyle = 'medium',
) => {
  const startColumnIndex = start;
  for (let i = startColumnIndex; i <= end; i++) {
    worksheet[XLSX.utils.encode_cell({ c: i + 1, r: row })] = {
      s: {
        fill: { bgColor: { rgb: fillColor }, fgColor: { rgb: fillColor }, patternType: 'solid' },
        border: {
          top: { style: borderStyle, color: { rgb: '000000' } },
          bottom: { style: borderStyle, color: { rgb: '000000' } },
          left: { style: borderStyle, color: { rgb: '000000' } },
          right: { style: borderStyle, color: { rgb: '000000' } },
        },
      },
      v: '',
    };
  }
};
