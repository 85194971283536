import { TExecutiveSummaryPayload,  } from '../../models/ExecutiveSummary.model';
import {
  EXECUTIVE_SUMMARY_FAILURE,
  EXECUTIVE_SUMMARY_SUCCESS,
  REQUEST_EXECUTIVE_SUMMARY,
  TExecutiveSummaryFailure,
  TExecutiveSummaryRequest,
  TExecutiveSummarySuccess,
} from '../DmapPhase0State/ExecutiveSummary.state';
import { TSubSectionResponse } from '../DmapPhase0State/LandingPageState';

export const fetchExecutiveSummary = (payload: TExecutiveSummaryPayload): TExecutiveSummaryRequest => ({
  type: REQUEST_EXECUTIVE_SUMMARY,
  payload,
});

export const fetchExecutiveSummarySuccess = (data: TSubSectionResponse<null>[]): TExecutiveSummarySuccess => ({
  type: EXECUTIVE_SUMMARY_SUCCESS,
  payload: data,
});

export const fetchExecutiveSummaryError = (error: any): TExecutiveSummaryFailure => ({
  type: EXECUTIVE_SUMMARY_FAILURE,
  payload: error,
});
