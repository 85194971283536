import { FileTextFilled } from '@ant-design/icons';
import React from 'react';
import './SelectSubsection.less';

interface Props{
  text?: string
}

const SelectSubsection: React.FC<Props> = ({text}) => {
  return (
    <div className="wrapper-textfile-icon">
      <FileTextFilled className="file-icon" />
      <div className="text-wrapper">
        <p>
          {text ? text : 'Select subsection to view content.'}
        </p>
      </div>
    </div>
  );
};

export default SelectSubsection;
