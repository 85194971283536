import { TSubSectionResponse } from '../DmapPhase0State/LandingPageState';
import {
    ARCHITECTURE_SUBSECTION_FAILURE,
    ARCHITECTURE_SUBSECTION_SUCCESS,
    ArchActionTypes,
    FETCH_FILES_FAILURE,
    FETCH_FILES_REQUEST,
    FETCH_FILES_SUCCESS,
    GENERATE_DIAGRAM_FAILURE,
    GENERATE_DIAGRAM_REQUEST,
    GENERATE_DIAGRAM_SUCCESS,
    GET_ARCHITECTURE_DETAILS,
    GET_ARCHITECTURE_DETAILS_ERROR,
    GET_ARCHITECTURE_DETAILS_SUCCESS,
    GET_ARCHITECTURE_IMAGE,
    GET_ARCHITECTURE_IMAGE_ERROR,
    GET_ARCHITECTURE_IMAGE_SUCCESS,
    REQUEST_ARCHITECTURE_SUBSECTION,
    RESET_ARCHITECTURE_SUBSECTION,
    TArchitectureState,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS,
} from '../DmapPhase0State/Architecture.state';
import { TArchTableData, archSubsectionToReducerMapping } from '../../models/Architecture.model';

const initialState: TArchitectureState = {
    asIsArchitecture: null,
    toBeArchitecture: null
};

export const archReducers = (
    state = initialState,
    action: ArchActionTypes,
): TArchitectureState => {
    let subsection = '';
    switch (action.type) {
        // (AS IS/TO BE) Architecture Details
        case GET_ARCHITECTURE_DETAILS:
            subsection = action.payload.subsection || '';
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    response: {
                        data: null,
                        loading: true,
                        error: null
                    }
                }
            }
        case GET_ARCHITECTURE_DETAILS_SUCCESS:
            subsection = action.payload.subsection;
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    response: {
                        data: action.payload.response,
                        loading: false,
                        error: null
                    }
                }
            }
        case GET_ARCHITECTURE_DETAILS_ERROR:
            subsection = action.payload.subsection;
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    response: {
                        data: null,
                        loading: false,
                        error: action.payload.error
                    }
                }
            }

        // (AS IS/TO BE) Architecture Image
        case GET_ARCHITECTURE_IMAGE:
            subsection = action.payload.subsection
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    image: {
                        data: null,
                        loading: true,
                        error: null
                    }
                }
            }
        case GET_ARCHITECTURE_IMAGE_SUCCESS:
            subsection = action.payload.subsection;
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    image: {
                        data: action.payload.response,
                        loading: false,
                        error: null
                    }
                }
            }
        case GET_ARCHITECTURE_IMAGE_ERROR:
            subsection = action.payload.subsection;
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    image: {
                        data: null,
                        loading: false,
                        error: action.payload.error
                    }
                }
            }

        // (AS IS/ TO BE) Architecture Subsection
        case REQUEST_ARCHITECTURE_SUBSECTION:
            subsection = action.payload.body.subsection;
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    response: {
                        loading: true,
                        data: [
                            ...(state[archSubsectionToReducerMapping[subsection]]?.response?.data || []),
                        ],
                        error: null
                    }
                }
            }

        case ARCHITECTURE_SUBSECTION_SUCCESS:
            const newData = action.payload.response;
            subsection = action.payload.subsection
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    response: {
                        loading: false,
                        data: [...(state[archSubsectionToReducerMapping[subsection]]?.response?.data?.filter((val: TSubSectionResponse<TArchTableData>) => val.subsection !== subsection) || []), newData],
                        error: null
                    }
                }
            }
        case ARCHITECTURE_SUBSECTION_FAILURE:
            subsection = action.payload.subsection;
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    response: {
                        loading: false,
                        data: [...(state[archSubsectionToReducerMapping[subsection]]?.response?.data || [])],
                        error: action.payload.error
                    }
                }
            }
        
        case FETCH_FILES_REQUEST:
            subsection = action.payload.subSection;
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    existingFiles: {
                        loading: true,
                        data: null,
                        error: null
                    }
                }
            }
        case FETCH_FILES_SUCCESS:
            subsection = action.payload.subsection;
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    existingFiles: {
                        loading: false,
                        data: action.payload.response,
                        error: null
                    }
                }
            }
        case FETCH_FILES_FAILURE:
            subsection = action.payload.subsection;
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    existingFiles: {
                        loading: false,
                        data: null,
                        error: action.payload.error
                    }
                }
            }

        case UPLOAD_FILE_REQUEST:
            subsection = action.payload.subsection;
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    uploadFile: {
                        loading: true,
                        data: null,
                        error: null
                    }
                }
            }
        case UPLOAD_FILE_SUCCESS:
            subsection = action.payload.subsection;
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    uploadFile: {
                        loading: false,
                        data: action.payload.response,
                        error: null
                    }
                }
            }
        case UPLOAD_FILE_FAILURE:
            subsection = action.payload.subsection;
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    uploadFile: {
                        loading: false,
                        data: null,
                        error: action.payload.error
                    }
                }
            }

        case GENERATE_DIAGRAM_REQUEST:
            subsection = action.payload.subsection;
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    generateDiagram: {
                        loading: true,
                        data: null,
                        error: null
                    }
                }
            }
        case GENERATE_DIAGRAM_SUCCESS:
            subsection = action.payload.subsection;
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    generateDiagram: {
                        loading: false,
                        data: action.payload.response,
                        error: null
                    }
                }
            }
        case GENERATE_DIAGRAM_FAILURE:
            subsection = action.payload.subsection;
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: {
                    ...(state[archSubsectionToReducerMapping[subsection]] || {}),
                    generateDiagram: {
                        loading: false,
                        data: null,
                        error: action.payload.error
                    }
                }
            }
        case RESET_ARCHITECTURE_SUBSECTION:
            subsection = action.payload.subsection;
            return {
                ...state,
                [archSubsectionToReducerMapping[subsection]]: null
            }
        default:
            return { ...state };
    }
};
