import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import CustomTable from 'Components/DynamicForm/components/CustomTable/CustomTable';
import { DEFAULT_PAGE_SIZE } from '../../../../../constants';
import { fetchWorkshopEnablersSubSection } from 'libs/store/DmapPhase0Actions/WorkshopEnablers.actions';
import {
  TFetchSubSectionPayload,
  TRootState,
  TSubSectionResponse,
  TTableResponse,
} from 'libs/store/DmapPhase0State/LandingPageState';
import {
  TWorkshopEnablersSubSectionRequest,
  TWorkshopEnablersTableCols,
} from 'libs/store/DmapPhase0State/WorkshopEnablers.state';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Dispatch } from 'redux';
import { hasPagination } from 'utils/ReviewScreen.util';
import './HanaChanges.less';

interface Props {
  s4HanaData?: TTableResponse<TWorkshopEnablersTableCols> | null;
  dataLoading?: boolean;
  fetchWorkshopSubSection: (payload: TFetchSubSectionPayload) => TWorkshopEnablersSubSectionRequest;
}

const HanaChanges: React.FC<Props> = ({ s4HanaData, dataLoading, fetchWorkshopSubSection }) => {
  const { id }: { id: string } = useParams();

  const onChange = (
    paginationConfig: TablePaginationConfig,
    selectedFilters: Record<string, FilterValue | null>,
    action: 'paginate' | 'sort' | 'filter',
  ) => {
    fetchWorkshopSubSection &&
      fetchWorkshopSubSection({
        requestId: id,
        body: {
          selectedFilters: Object.keys(selectedFilters)
            .filter((k) => selectedFilters[k] != null)
            .reduce((a, k) => ({ ...a, [k]: selectedFilters[k] }), {}),
          page: paginationConfig.current || -1,
          pageSize: paginationConfig.pageSize || DEFAULT_PAGE_SIZE,
          section: 'Workshop Enablers',
          subsection: 'S4 HANA Changes',
        },
      });
  };

  return (
    <div className="hana-container" id="hana-table">
      <CustomTable
        loading={dataLoading || false}
        data={s4HanaData?.content || []}
        tableName="s4-hana"
        filterCols={s4HanaData?.filters}
        pageSize={s4HanaData?.pageSize || DEFAULT_PAGE_SIZE}
        totalCount={s4HanaData?.totalCount}
        onChange={onChange}
        colNames={s4HanaData?.colNames || []}
        pagination={true}
        pageNumber={s4HanaData?.pageNumber}
        scrollProps={{
          x: 'none',
          y: `calc(100vh - ${
            hasPagination(s4HanaData?.totalCount, s4HanaData?.pageSize) ? '18.965rem' : '15.165rem'
          })`,
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: TRootState) => ({
  s4HanaData: state.workshopEnablers?.data?.find(
    (data: TSubSectionResponse<TWorkshopEnablersTableCols>) =>
      data.subsection === 'S4 HANA Changes',
  )?.data?.table,
  dataLoading: state.workshopEnablers?.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchWorkshopSubSection: (payload: TFetchSubSectionPayload) =>
    dispatch(fetchWorkshopEnablersSubSection(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HanaChanges);
