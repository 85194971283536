import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { TExecutiveSummaryRequest } from '../../../../libs/store/DmapPhase0State/ExecutiveSummary.state';
import {
  TDataState,
  TRootState,
  TSubSectionResponse,
} from '../../../../libs/store/DmapPhase0State/LandingPageState';
import { fetchExecutiveSummary } from '../../../../libs/store/DmapPhase0Actions/ExecutiveSummary.actions';
import { useParams } from 'react-router';
import RichTextEditor from '../../../../Components/RichTextEditor/RichTextEditor';

interface ExecutiveProps {
  selectedItem: string;
  requestName: string;
  executiveData?: TDataState<TSubSectionResponse<null>[]>;
  fetchExecutiveSummaryData?: (reqId: string, selectedItem: string, pageSize: number) => TExecutiveSummaryRequest;
}

const ExecutiveSummary: React.FC<ExecutiveProps> = ({
  selectedItem,
  executiveData,
  fetchExecutiveSummaryData,
}) => {
  const { id }: { id: string } = useParams();
  const [summary, setSummary] = useState<string>('No Data Found');

  useEffect(() => {
    if (id) {
      fetchExecutiveSummaryData && fetchExecutiveSummaryData(id, selectedItem, 0);
    }
  }, [id, selectedItem]);

  useEffect(() => {
    if (executiveData?.data && executiveData.data.length > 0) {
      const response = executiveData.data[0]?.data?.response;
      if (response) {
        setSummary(response);
      } else {
        setSummary('No Data Found');
      }
    } else {
      setSummary('No Data Found');
    }
  }, [executiveData]);

  return (
    <div className={`text-editor-container`}>
      <RichTextEditor editorText={(summary && summary !== 'No Data Found') ? summary : ''} editable={false} selectedSubsection={selectedItem} loading={executiveData?.loading ? 'Fetching Data...' : undefined}/>
    </div>
  );
};

const mapStateToProps = (state: TRootState) => ({
  executiveData: state.executiveSummary,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchExecutiveSummaryData: (reqId: string, itemConstant: string, pageSize: number) =>
    dispatch(fetchExecutiveSummary({ reqId, itemConstant, pageSize })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExecutiveSummary);
