import { AxiosError } from 'axios';
import { TArchImgResponse, TArchResponse, TArchTableData, TGenerateDiagramPayload, TUploadFilesPayload, TArchGenericResponse, TArchSuccess, TArchImgPayload, TResetArchSubsectionPayload } from '../../models/Architecture.model';
import {
  ARCHITECTURE_SUBSECTION_FAILURE,
  ARCHITECTURE_SUBSECTION_SUCCESS,
  FETCH_FILES_FAILURE,
  FETCH_FILES_REQUEST,
  FETCH_FILES_SUCCESS,
  GENERATE_DIAGRAM_FAILURE,
  GENERATE_DIAGRAM_REQUEST,
  GENERATE_DIAGRAM_SUCCESS,
  GET_ARCHITECTURE_DETAILS,
  GET_ARCHITECTURE_DETAILS_ERROR,
  GET_ARCHITECTURE_DETAILS_SUCCESS,
  GET_ARCHITECTURE_IMAGE,
  GET_ARCHITECTURE_IMAGE_ERROR,
  GET_ARCHITECTURE_IMAGE_SUCCESS,
  REQUEST_ARCHITECTURE_SUBSECTION,
  RESET_ARCHITECTURE_SUBSECTION,
  TArchitectureSubSectionFailure,
  TArchitectureSubSectionRequest,
  TArchitectureSubSectionSuccess,
  TFetchFilesFailure,
  TFetchFilesRequest,
  TFetchFilesSuccess,
  TGenerateDiagramFailure,
  TGenerateDiagramRequest,
  TGenerateDiagramSuccess,
  TResetArchSubsection,
  TUploadFileFailure,
  TUploadFileRequest,
  TUploadFileSuccess,
  TgetArchDetails,
  TgetArchDetailsError,
  TgetArchDetailsSuccess,
  TgetArchImage,
  TgetArchImageError,
  TgetArchImageSuccess,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
} from '../DmapPhase0State/Architecture.state';
import { TFetchSubSectionPayload, TSectionPayload, TSubSectionResponse } from '../DmapPhase0State/LandingPageState';
import { TExistingFilesPayload, TFetchFileData, TFetchFilesResponse } from '../../models/ReviewScreenModels';

// (As-IS/To-Be) Architecture details
export const fetchArchitectureDetails = (payload: TSectionPayload): TgetArchDetails => ({
  type: GET_ARCHITECTURE_DETAILS,
  payload,
});

export const fetchArchitectureDetailsSuccess = (
  response: TSubSectionResponse<TArchTableData>[],
  subsection: string
): TgetArchDetailsSuccess => ({
  type: GET_ARCHITECTURE_DETAILS_SUCCESS,
  payload: {
    response,
    subsection
  }
});

export const fetchArchitectureDetailsError = (error: AxiosError, subsection: string): TgetArchDetailsError => ({
  type: GET_ARCHITECTURE_DETAILS_ERROR,
  payload: {error, subsection},
});

// (As-IS/To-Be) Architecture Image
export const fetchArchitectureImage = (payload: TArchImgPayload): TgetArchImage => ({
  type: GET_ARCHITECTURE_IMAGE,
  payload,
});

export const fetchArchitectureImageSuccess = (
  response: TArchImgResponse,
  subsection: string
): TgetArchImageSuccess => ({
  type: GET_ARCHITECTURE_IMAGE_SUCCESS,
  payload: {
    response,
    subsection
  },
});

export const fetchArchitectureImageError = (error: AxiosError, subsection: string): TgetArchImageError => ({
  type: GET_ARCHITECTURE_IMAGE_ERROR,
  payload: {error, subsection},
});

// (As-IS/To-Be) Architecture Subsection
export const fetchArchitectureSubSection = (payload: TFetchSubSectionPayload): TArchitectureSubSectionRequest => ({
  type: REQUEST_ARCHITECTURE_SUBSECTION,
  payload
});

export const fetchArchitectureSubSectionSuccess = (response: TSubSectionResponse<TArchTableData>, subsection: string): TArchitectureSubSectionSuccess => ({
  type: ARCHITECTURE_SUBSECTION_SUCCESS,
  payload: {
    response,
    subsection
  },
});

export const fetchArchitectureSubSectionError = (error: AxiosError, subsection: string): TArchitectureSubSectionFailure => ({
  type: ARCHITECTURE_SUBSECTION_FAILURE,
  payload: {error, subsection},
});

// (As-IS/To-Be) Architecture reset
export const resetArchitectureSubSection = (payload: TResetArchSubsectionPayload): TResetArchSubsection => ({
  type: RESET_ARCHITECTURE_SUBSECTION,
  payload
});


// To-Be Architecture Upload file
export const uploadFileRequest = (payload: TUploadFilesPayload): TUploadFileRequest => ({
  type: UPLOAD_FILE_REQUEST,
  payload
});

export const uploadFileSuccess = (response: TArchGenericResponse, subsection: string): TUploadFileSuccess => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: {
    response,
    subsection
  }
});

export const uploadFileFailure = (error: AxiosError, subsection: string): TUploadFileFailure => ({
  type: UPLOAD_FILE_FAILURE,
  payload: {
    error,
    subsection
  }
});

// To-Be Architecture Existing files
export const fetchFilesRequest = (payload: TExistingFilesPayload): TFetchFilesRequest => ({
  type: FETCH_FILES_REQUEST,
  payload
});

export const fetchFilesSuccess = (response: TFetchFileData[], subsection: string): TFetchFilesSuccess => ({
  type: FETCH_FILES_SUCCESS,
  payload: {
    response,
    subsection
  },
});

export const fetchFilesFailure = (error: AxiosError, subsection: string): TFetchFilesFailure => ({
  type: FETCH_FILES_FAILURE,
  payload: {error, subsection}
});

// To-Be Architecture Generate Architecture Diagram
export const generateDiagramRequest = (payload: TGenerateDiagramPayload): TGenerateDiagramRequest => ({
  type: GENERATE_DIAGRAM_REQUEST,
  payload
});

export const generateDiagramSuccess = (response: TArchGenericResponse, subsection: string): TGenerateDiagramSuccess => ({
  type: GENERATE_DIAGRAM_SUCCESS,
  payload: {response, subsection}
});

export const generateDiagramFailure = (error: AxiosError, subsection: string): TGenerateDiagramFailure => ({
  type: GENERATE_DIAGRAM_FAILURE,
  payload: {error, subsection}
});