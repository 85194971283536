import { call, put } from "redux-saga/effects";
import {
  fetchProcessSimpDetailsSuccess,
  fetchProcessSimplificationDetailsError
} from '../DmapPhase0Actions/ProcessSimplification.actions';
import { AxiosError } from "axios";
import { TSectionResponse } from "../DmapPhase0State/LandingPageState";
import { fetchSectionDetails } from "../../services/DmapPhase0Services/ReviewPageService";
import { TProcessSimpDetailsRequest } from "../DmapPhase0State/ProcessSimplification.state";

export function* fetchProcessSimplificationData(action: TProcessSimpDetailsRequest) {
  try {
    const { reqId, selectedItem, pageSize } = action.payload
    const data: TSectionResponse<string> = yield call(
      fetchSectionDetails<string>,
      reqId,
      selectedItem,
      pageSize
    );
    yield put(fetchProcessSimpDetailsSuccess(data.response));

  } catch (error) {
    yield put(fetchProcessSimplificationDetailsError(error as AxiosError));
  }
}
