import axios, { AxiosResponse } from 'axios';
// import { process.env.REACT_APP_BASE_URL } from '../../../utils/Dmap-phase0-apiUrl';
import {
  RequestPayload,
  ProcessRequestGenSecApiResponse,
} from '../../models/ReviewScreenModels';


export const processRequestGenerateSlideContentput = async (
  payload: RequestPayload,
)=> {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/api/phase-zero/request/process`;
    const response = await axios.put<
      RequestPayload,
      AxiosResponse<ProcessRequestGenSecApiResponse>
    >(url, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
      return response.data;
      
  } catch (error) {
    throw error;
  }
};


