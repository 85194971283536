import { appendSectionToProjects, getProjectDetailsBySection } from "../../../utils/projectPlan.util";
import { TProjectTimeline } from "../../models/ProjectPlan.model";
import { PROJECT_TIMELINE_AVERAGE_FAILURE, PROJECT_TIMELINE_AVERAGE_SUCCESS, PROJECT_TIMELINE_FAILURE, PROJECT_TIMELINE_SUCCESS, PROJECT_VISUALS_FAILURE, PROJECT_VISUALS_SUCCESS, ProjectTimelineActionTypes, REQUEST_PROJECT_TIMELINE, REQUEST_PROJECT_TIMELINE_AVERAGE, REQUEST_PROJECT_VISUALS, REQUEST_SAVE_PROJECT_TIMELINE, RESET_PROJECT_TIMELINE, SAVE_PROJECT_TIMELINE_FAILURE, SAVE_PROJECT_TIMELINE_SUCCESS, SET_PROJECT_TIMELINE_SELECTED_VALUES, TProjectPlan } from "../DmapPhase0State/ProjectPlan.state";

const initialState: TProjectPlan = {
    timeline: null,
    saveTimeline: null,
    visuals: null,
    selectedTimelineValues: {}
};

export const ProjectPlanReducer = (state = initialState, action: ProjectTimelineActionTypes): TProjectPlan => {
    switch (action.type) {
        // Project Timeline
        case REQUEST_PROJECT_TIMELINE:
            return {
                ...state,
                timeline: {
                    data: null,
                    loading: true,
                    error: null
                }
            }
        case PROJECT_TIMELINE_SUCCESS:
            return {
                ...state,
                timeline: {
                    data: action.payload,
                    loading: false,
                    error: null
                }
            }
        case PROJECT_TIMELINE_FAILURE:
            return {
                ...state,
                timeline: {
                    data: null,
                    loading: false,
                    error: action.payload
                }
            }
        case SET_PROJECT_TIMELINE_SELECTED_VALUES:
            return {
                ...state,
                selectedTimelineValues: action.payload
            }

        // Project Average
        case REQUEST_PROJECT_TIMELINE_AVERAGE:
            const project = getProjectDetailsBySection(action.payload.Section, state.timeline?.data || [])
            if (project.data) {
                project.data.loading = true
            }
            const updatedProject = appendSectionToProjects(project.data, state.timeline?.data || [], project.index)
            return {
                ...state,
                timeline: {
                    data: updatedProject || [],
                    loading: false,
                    error: null
                }
            }
        case PROJECT_TIMELINE_AVERAGE_SUCCESS:
            const { section, ...otherFields } = action.payload
            const result = getProjectDetailsBySection(section, state.timeline?.data || [])
            if (result.data) {
                result.data.categoryAverage = otherFields.categoryAverage
                result.data.categoryScore = otherFields.categoryScore
                result.data.loading = false
            }
            const updatedTimeline = appendSectionToProjects(result.data, state.timeline?.data || [], result.index)
            return {
                ...state,
                timeline: {
                    data: updatedTimeline || [],
                    loading: false,
                    error: null
                }
            }
        case PROJECT_TIMELINE_AVERAGE_FAILURE:
            const updatedAvgData: TProjectTimeline[] = state.timeline?.data?.map((data: TProjectTimeline) => ({ ...data, loading: false })) || []
            return {
                ...state,
                timeline: {
                    data: updatedAvgData,
                    loading: false,
                    error: action.payload
                }
            }

        // SAVE PROJECT TIMELINE
        case REQUEST_SAVE_PROJECT_TIMELINE:
            return {
                ...state,
                saveTimeline: {
                    data: null,
                    loading: true,
                    error: null
                }
            }
        case SAVE_PROJECT_TIMELINE_SUCCESS:
            return {
                ...state,
                saveTimeline: {
                    data: action.payload,
                    loading: false,
                    error: null
                }
            }
        case SAVE_PROJECT_TIMELINE_FAILURE:
            return {
                ...state,
                saveTimeline: {
                    data: null,
                    loading: false,
                    error: action.payload
                }
            }
        case RESET_PROJECT_TIMELINE:
            return {
                ...state,
                timeline: null
            }

        // GEENERATE TIMELINE VISAUALS
        case REQUEST_PROJECT_VISUALS:
            return {
                ...state,
                visuals: {
                    data: null,
                    loading: true,
                    error: null
                }
            }
        case PROJECT_VISUALS_SUCCESS:
            return {
                ...state,
                visuals: {
                    data: action.payload,
                    loading: false,
                    error: null
                }
            }
        case PROJECT_VISUALS_FAILURE:
            return {
                ...state,
                visuals: {
                    data: null,
                    loading: false,
                    error: action.payload
                }
            }
        default:
            return state;
    }
};