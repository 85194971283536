import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { uploadFieldDynamicValidation } from 'utils/newRqst.util';
import { TFormField } from '../models/NewRqst.model';

export enum DOC_FILE_TYPE {
  DPA = '.pptx',
  MOCK = '.xls, .xlsx',
  PROCESS_L2_L3_SCOPE = '.xls, .xlsx',
  FUTURE = '.xls, .xlsx',
  Architecture = '.xls, .xlsx, .pdf, .png, .jpg, .jpeg',
  RFP = '.docx, .pdf, .xls, .xlsx',
  READINESS_CHECK = '.docx, .pdf',
}

export enum DOC_PLCEHOLDERS {
  DPA = 'Upload DPA Report',
  MOCK = 'Upload Current Maturity Assessment',
  PROCESS_L2_L3_SCOPE = 'Upload Process (L2-L3) Scope',
  FUTURE = 'Upload Future Maturity Assessment',
  Architecture = 'Upload As-Is Architecture',
  RFP = 'Upload RFP Document',
  READINESS_CHECK = 'Upload Readiness Check Report',
}

export const SECTION_DOC__REQUIRED_MAPPING = {
  'Technology transformation': [],
  'Transformation Deepdive': [],
  'Implementation Plan': [],
  'Architecture': ['Architecture'],
  'Process transformation': ['BPA'],
  'Workshop Enablers and KDDs': ['KDD'],
  'Workshop Enablers': ['workshopEnablers'],
};

export const NEW_RQST_FIELDS_1: TFormField[] = [
  {
    id: 'requestNameField',
    key: 'rqstName',
    type: 'text',
    name: 'requestName',
    label: 'Request Name',
    placeholderText: 'Request Name',
    rules: [
      {
        required: true,
        message: 'Please provide a Request Name.',
      },
      {
        min: 3,
        message: 'Please enter at least 3 characters',
      },
      {
        max: 255,
        message: 'Maximum 255 charactes allowed !!!',
      },
    ],
    canRemove: false,
  },
  {
    id: 'projectType',
    key: 'projectTypeId',
    type: 'select',
    name: 'projectType',
    label: 'Project Type',
    placeholderText: 'Select',
    showArrow: true,
    rules: [
      {
        required: true,
        message: 'Please select the type of the project.',
      },
    ],
    canRemove: false,
  },
  {
    id: 'sectionDropdownField',
    key: 'sections',
    type: 'select',
    name: 'sectionDropdown',
    label: 'Select Sections',
    placeholderText: 'Select Section(s)',
    mode: 'multiple',
    showArrow: true,
    dependencies: ['projectType'],
    canBeDisabled: true,
    rules: [
      {
        required: true,
        message: 'Please select atleast one section to continue.',
      },
    ],
    canRemove: false,
  },
  {
    id: 'dpaReportField',
    key: 'DPA',
    type: 'upload',
    name: 'dpaReport',
    accept: DOC_FILE_TYPE.DPA,
    placeholderText: DOC_PLCEHOLDERS.DPA,
    uploadCount: 1,
    canRemove: false,
    dependencies: ['sectionDropdown'],
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value: UploadChangeParam<UploadFile>) {
          return uploadFieldDynamicValidation(
            getFieldValue,
            'sectionDropdown',
            value,
            'DPA',
            `Please ${DOC_PLCEHOLDERS.DPA}`,
          );
        },
      }),
    ],
  },
  {
    id: 'readinessCheck',
    key: 'READINESS_CHECK',
    type: 'upload',
    name: 'READINESS_CHECK',
    accept: DOC_FILE_TYPE.READINESS_CHECK,
    placeholderText: DOC_PLCEHOLDERS.READINESS_CHECK,
    uploadCount: 1,
    canRemove: false,
    dependencies: ['sectionDropdown'],
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value: UploadChangeParam<UploadFile>) {
          return uploadFieldDynamicValidation(
            getFieldValue,
            'sectionDropdown',
            value,
            'readinessCheck',
            `Please ${DOC_PLCEHOLDERS.READINESS_CHECK}`,
          );
        },
      }),
    ],
  },
  {
    id: 'l2L3ScopeField',
    key: 'Process (L2-L3) Scope',
    type: 'upload',
    name: 'l2L3Scope',
    accept: DOC_FILE_TYPE.PROCESS_L2_L3_SCOPE,
    placeholderText: DOC_PLCEHOLDERS.PROCESS_L2_L3_SCOPE,
    uploadCount: 1,
    canRemove: false,
  },
  {
    id: 'currentMaturityAssessmentField',
    key: 'MOCK',
    type: 'upload',
    name: 'currentMaturityAssessment',
    accept: DOC_FILE_TYPE.MOCK,
    placeholderText: DOC_PLCEHOLDERS.MOCK,
    uploadCount: 1,
    canRemove: false,
    dependencies: ['sectionDropdown'],
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value: UploadChangeParam<UploadFile>) {
          return uploadFieldDynamicValidation(
            getFieldValue,
            'sectionDropdown',
            value,
            'MOCK',
            `Please ${DOC_PLCEHOLDERS.MOCK}`,
          );
        },
      }),
    ],
  },
  {
    id: 'futureMaturity',
    key: 'FUTURE',
    type: 'upload',
    name: 'futureMaturity',
    accept: DOC_FILE_TYPE.FUTURE,
    placeholderText: DOC_PLCEHOLDERS.FUTURE,
    uploadCount: 1,
    canRemove: false,
    dependencies: ['sectionDropdown'],
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value: UploadChangeParam<UploadFile>) {
          return uploadFieldDynamicValidation(
            getFieldValue,
            'sectionDropdown',
            value,
            'FUTURE',
            `Please ${DOC_PLCEHOLDERS.FUTURE}`,
          );
        },
      }),
    ],
  },
  {
    id: 'asisarchitecture',
    key: 'Architecture',
    type: 'upload',
    name: 'asisarchitecture',
    accept: DOC_FILE_TYPE.Architecture,
    placeholderText: DOC_PLCEHOLDERS.Architecture,
    uploadCount: 1,
    canRemove: false,
    dependencies: ['sectionDropdown'],
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value: UploadChangeParam<UploadFile>) {
          return uploadFieldDynamicValidation(
            getFieldValue,
            'sectionDropdown',
            value,
            'Architecture',
            `Please ${DOC_PLCEHOLDERS.Architecture}`,
          );
        },
      }),
    ],
  },
];

export const NEW_RQST_FIELDS_2: TFormField[] = [
  {
    id: 'addMoreRFPField',
    key: 'rfp-btn',
    type: 'dynamic-field-incr-btn',
    name: 'addMoreRFP',
    placeholderText: 'Add Another RFP Document',
    canRemove: false,
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value: UploadChangeParam<UploadFile>) {
          return uploadFieldDynamicValidation(
            getFieldValue,
            'sectionDropdown',
            value,
            'RFP',
            'Please Upload RFP document',
          );
        },
      }),
    ],
  },
];

export const getRFPDocFields = (index: number): TFormField[] => [
  {
    id: `rfpReportField-${index}`,
    key: 'RFP',
    type: 'upload',
    name: `rfpReport-${index}`,
    accept: DOC_FILE_TYPE.RFP,
    placeholderText: `${DOC_PLCEHOLDERS.RFP} ${index > 1 ? index : ''}`,
    uploadCount: 1,
    canRemove: index > 1 ? true : false,
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value: UploadChangeParam<UploadFile>) {
          return uploadFieldDynamicValidation(
            getFieldValue,
            'sectionDropdown',
            value,
            'RFP',
            `Please ${DOC_PLCEHOLDERS.RFP}`,
          );
        },
      }),
    ],
  },
];
