import { AxiosError } from "axios";
import { TSubsectionPromptResult } from "../../models/ChatPrompt.model";
import { applyChatToAllSubsections, generateChatResult } from "../../services/DmapPhase0Services/ChatPrompt.service";
import { applyChatToAllFailure, applyChatToAllSuccess, fetchPromptResultFailure, fetchPromptResultSuccess } from "../DmapPhase0Actions/ChatPrompt.actions";
import { TPromptResultRequest } from "../DmapPhase0State/ChatPrompt.state";
import { put, call } from 'redux-saga/effects';

export function* fetchPromptResultSaga(action: TPromptResultRequest): Generator<any, void, any> {
    try {
      const response: TSubsectionPromptResult = yield call(generateChatResult, action.payload);
      yield put(fetchPromptResultSuccess(response));
    } catch (error) {
      yield put(fetchPromptResultFailure(error as AxiosError));
    }
  }

export function* applyChatToAllSaga(action: TPromptResultRequest): Generator<any, void, any> {
    try {
      const response: TSubsectionPromptResult = yield call(applyChatToAllSubsections, action.payload);
      yield put(applyChatToAllSuccess(response));
    } catch (error) {
      yield put(applyChatToAllFailure(error as AxiosError));
    }
  }