import { TPlanEstimates, TProjectAveragePayload, TProjectAvgSubSection, TProjectTimeline, TProjectVisualPayload, TSaveProjectTimelinePayload, TSubsection } from "../libs/models/ProjectPlan.model";

export const getRatingFromSubValue = (subSection: TSubsection | undefined, subValue: string): string => {
    if(!subValue){
        return 'NA';
    }

    let rating: number = -1

    if(subSection?.values?.[subValue]?.rating){
        rating = subSection?.values?.[subValue]?.rating
    }

    return rating === -1 ? 'NA' : rating.toString()
}

export const getTagFromSubValue = (subSection: TSubsection, subValue: string): string | null => {
    let tag: string | null = null

    if(!subValue){
        return null;
    }

    if(subSection?.values?.[subValue]?.individualScore){
        tag = subSection?.values?.[subValue]?.individualScore
    }

    return tag;
}

export const getTagColor = (tag: string): string => {
    switch (tag) {
        case 'Medium':
          return 'rgba(223, 174, 1, 1)';
        case 'Small':
          return 'rgba(0, 158, 35, 1)';
        case 'Large':
          return 'rgba(201, 10, 10, 1)';
        default:
          return '';
      }
}

export const getProjectDetailsBySection = (section: string, projectTimeline: TProjectTimeline[]): {index: number, data: TProjectTimeline} => {
    const timeline = projectTimeline?.find((project: TProjectTimeline) => project.section === section);
    const timelineIndex = projectTimeline?.findIndex((project: TProjectTimeline) => project.section === section);

    return {
        index: timelineIndex,
        data: timeline as TProjectTimeline
    }
} 

export const appendSectionToProjects = (section: TProjectTimeline, projectTimeline: TProjectTimeline[], sectionIndex: number): TProjectTimeline[] => {
    const updatedTimeline: TProjectTimeline[] = [...(projectTimeline || [])]
    updatedTimeline.splice(sectionIndex,1,section)

    return updatedTimeline;
}

export const getPayloadForProjectAverage = (section : string, selectedParameters: Record<string, string>, subSections: TSubsection[]): TProjectAveragePayload => {
    const projectAvgSubsections: TProjectAvgSubSection[] = [];

    Object.keys(selectedParameters)?.forEach((parameter: string) => {
        const rating = getRatingFromSubValue(subSections.find((subSection: TSubsection) => subSection.parameter === parameter), selectedParameters[parameter])
        projectAvgSubsections.push({
            Parameter: parameter,
            Value: selectedParameters[parameter],
            Rating: rating === 'NA' ? -1 : parseInt(rating) 
        })
    })

    return {
        Section: section,
        Subsections: projectAvgSubsections
    }
}

export const getPayloadForSaveReport = (requestId: string, projectTimeline: TProjectTimeline[], selectedValues: Record<string, Record<string, string>>): TSaveProjectTimelinePayload => {
    const payload: TSaveProjectTimelinePayload = {
        requestId,
        data: []
    }
    projectTimeline.forEach((timeline: TProjectTimeline) => {
        if(timeline.categoryAverage && timeline.categoryScore){
            payload.data.push({
                Section: timeline.section,
                "Category Average": timeline.categoryAverage,
                "Category Score": timeline.categoryScore,
                Subsections: timeline.subsections.map((subSection: TSubsection) => ({
                    Parameter: subSection.parameter,
                    Values: selectedValues?.[timeline.section]?.[subSection.parameter],
                    "Individual Score": getTagFromSubValue(subSection, selectedValues?.[timeline.section]?.[subSection.parameter]) || '',
                    Rating: parseInt(getRatingFromSubValue(subSection, selectedValues?.[timeline.section]?.[subSection.parameter])) || 0,
                    Weightage: subSection.weightage
                }))
            })
        }
    })

    return payload;
}

export const getPayloadForGenerateReport = (requestId: string, projectTimeline: TProjectTimeline[]): TProjectVisualPayload => {
    const payload: TProjectVisualPayload = {
        requestId,
        data: {
            sections: []
        }
    }
    projectTimeline.forEach((timeline: TProjectTimeline) => {
        if(timeline.categoryAverage && timeline.categoryScore){
            payload.data?.sections.push({
                Section: timeline.section,
                "Category Average": timeline.categoryAverage,
                "Section Weightage": timeline.sectionWeightage
            })
        }
    })

    return payload;
}

// Project Visualisation

export const getMaxWeekForEstimates = (estimates: TPlanEstimates[]): number => {
    let maxWeek = 0;

    estimates.forEach((estimate: TPlanEstimates) => {
        if(estimate.tasks.end > maxWeek){
            maxWeek = estimate.tasks.end
        }
    })

    return maxWeek;
}
