import React, { useEffect, useState } from 'react';
import { Table, Select, Tag } from 'antd';
import './AccordianTable.less';
import {
  getPayloadForProjectAverage,
  getRatingFromSubValue,
  getTagColor,
  getTagFromSubValue,
} from '../../utils/projectPlan.util';
import { TProjectAveragePayload, TSubsection } from '../../libs/models/ProjectPlan.model';
import { fetchAvg } from '../../libs/store/DmapPhase0Actions/ProjectPlan.actions';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { updateSaveValue } from '../../libs/store/DmapPhase0Actions/LandingPageActions';
import { TSaveFlagAction } from '../../libs/store/DmapPhase0State/LandingPageState';

interface Props {
  section: string;
  subSections: TSubsection[];
  selectedItem: string;
  selectedValues: Record<string, string>;
  setIsUpdated: (isScoreUpdated: boolean) => void;
  setSelectedValues: (section: string, parameter: string, value: string) => any;

  fetchProjectAvg?: (payload: TProjectAveragePayload) => any;
  updateSaveEnabled?: (disabled: boolean) => TSaveFlagAction;
}

const { Option } = Select;

const AccordianTable: React.FC<Props> = ({section, subSections, selectedValues, setIsUpdated, setSelectedValues, fetchProjectAvg, updateSaveEnabled}) => {
  const columns = [
    {
      title: 'Parameter',
      dataIndex: 'parameter',
      key: 'parameter',
    },
    {
      title: 'Value',
      dataIndex: 'values',
      key: 'values',
      render: (text: any, record: TSubsection, index: number) => (
        <Select
          placeholder={'Select'}
          style={{ width: 250 }}
          getPopupContainer={(trigger) => trigger.parentElement}
          onChange={(value: string) => handleChange(value, record.parameter)}
          value={selectedValues?.[record.parameter]}
        >
          {Object.keys(record.values)?.map((option) => (
            <Option className='values-option' key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      style: { fontFamily: 'Poppins' },
      render: (text: any, record: TSubsection, index: number) => (
        <span>{getRatingFromSubValue(record, selectedValues?.[record?.parameter])}</span>
      ),
    },
    {
      title: 'Individual Score',
      dataIndex: 'individualScore',
      key: 'individualScore',
      style: { fontFamily: 'Poppins' },
      render: (text: any, record: TSubsection, index: number) =>
        getTagFromSubValue(record, selectedValues?.[record?.parameter]) ? (
          <Tag
            className="score-tag"
            color={getTagColor(
              getTagFromSubValue(record, selectedValues?.[record?.parameter]) || '',
            )}
          >
            {getTagFromSubValue(record, selectedValues?.[record?.parameter])}
          </Tag>
        ) : (
          <span>NA</span>
        ),
    },
  ];

  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  /**
   * Pre-poulate the selected values if already present
   */
  useEffect(() => {
    if (
      !firstLoad &&
      Object.keys(selectedValues || {})?.length === subSections?.length
    ) {
      fetchProjectAvg &&
        fetchProjectAvg(
          getPayloadForProjectAverage(section, selectedValues, subSections),
        );
        setIsUpdated(true);
        updateSaveEnabled && updateSaveEnabled(true)
    }

    if (firstLoad) {
      setFirstLoad(_ => false);
    }
  }, [selectedValues]);

  /**
   * Method to updated selected values if changed by the user
   * @param value
   * @param parameter
   */
  const handleChange = (value: string, parameter: string) => {
    setSelectedValues(section, parameter, value);
  };

  return (
    <Table
      rowClassName={(_: TSubsection, index: number) => (index % 2 === 0 ? 'highlighted-row' : '')}
      columns={columns}
      dataSource={subSections || []}
      pagination={false}
    />
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: Dispatch, {selectedItem}: Props) => ({
  fetchProjectAvg: (payload: TProjectAveragePayload) => dispatch(fetchAvg(payload)),
  updateSaveEnabled: (enabled: boolean) => dispatch(updateSaveValue(selectedItem, enabled))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccordianTable);
