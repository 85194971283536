import axios, { AxiosError, AxiosResponse } from 'axios';
import { TArchGenericResponse, TArchImgPayload, TArchImgResponse, TGenerateDiagramPayload, TUploadFilesPayload } from '../../models/Architecture.model';
import { ROUTES } from '../../../constants';

export const fetchArchImg = async (payload: TArchImgPayload): Promise<TArchImgResponse | AxiosError> => {
    try {
      const { requestId, sectionType, subsection } = payload
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/phase-zero/request/download/image?requestId=${requestId}&sectionType=${sectionType}&subSection=${subsection}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

// Call for  uploading Architecture file
export const uploadArchFile = async (payload: TUploadFilesPayload) => {
  const { files } = payload
  try {
    const formData = new FormData();
    files.forEach((file: File) => {
      formData.append(`files`, file);
    });
    const createResponse = await axios.post<FormData, AxiosResponse<TArchGenericResponse>>(ROUTES.UPLOAD_FILE(payload), formData,
      {
        headers: {
          responseType: 'json',
        },
      },
    )
      .then((response) => {
        return response.data;
      });
    return createResponse;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const generateArchDiagram = async (payload: TGenerateDiagramPayload): Promise<TArchGenericResponse | AxiosError> => {
  try {
    const { subsection, ...rest } = payload
    const response: AxiosResponse<TArchGenericResponse> = await axios.post<Omit<TGenerateDiagramPayload, 'subsection'>, AxiosResponse<TArchGenericResponse>>(ROUTES.GENERATE_DIAGRAM(), rest);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};