import React, { useState } from 'react';
import ReusableModal from '../../Components/FormModal/ReusableModal';
import { Form } from 'antd';
import { connect } from 'react-redux';
import DynamicForm from '../../Components/DynamicForm/DynamicForm';
import { REVERT_CHANGES_FIELDS } from '../../libs/constants/ReviewScreen.const';
import { Dispatch } from 'redux';
import { TRootState } from '../../libs/store/DmapPhase0State/LandingPageState';
import { TSectionDetails } from '../../libs/models/landingPageModels';

interface Props {
    visible: boolean;
    onCancel: () => void;
    requestId: string;
  
    sections?: TSectionDetails[] | null;
  }

const RevertChanges = (props: Props) => {
    const [form] = Form.useForm();
    const [selectedSection, setselectedSection] = useState<any>('');
    const [showConfirmation, setshowConfirmation] = useState(false);
  
    const handleRevertChanges = async () => {
      setshowConfirmation(true);
    };
  
    return (
      <div className="revert-section-modal-class">
        <ReusableModal
          visible={props.visible}
          onCancel={props.onCancel}
          title="Regenerate sub-sections"
          cancelButtonText={showConfirmation ? 'No, Cancel' : 'Cancel'}
          submitButtonText={showConfirmation ? 'Yes, Revert' : 'Next'}
          onSubmit={handleRevertChanges}
        >
          {showConfirmation ? (
            <>
              <div className="confirmation-modal">
                <p>
                  Are you sure you want to revert all changes you have made to{' '}
                  <b>{selectedSection}</b>?
                </p>
                <span className="note-text">
                  Note : This action will lead to take all the generated content to its original
                  version.
                </span>
              </div>
            </>
          ) : (
            <>
              <DynamicForm
            form={form}
            fieldsConfig={REVERT_CHANGES_FIELDS} 
              options={{
                'sections': props.sections?.map((section: TSectionDetails) => ({
                  label: section.section,
                  value: section.sectionConstant,
                }))
              }}
            />
            </>
          )}
        </ReusableModal>
      </div>
    );
  };
  
  const mapStateToProps = (state: TRootState) => ({
    sections: state.sectionsById?.data,
  });
  
  const mapDispatchToProps = (dispatch: Dispatch) => ({
  });

export default connect(mapStateToProps, mapDispatchToProps)(RevertChanges);