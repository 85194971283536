import axios, { AxiosResponse } from "axios";
import { ROUTES } from "../../../constants";
import { TKDDGeneratePayload, TKDDResponse, TKDDSavePayload, TKDDSaveResponse } from "../../models/KDD.model";

export const generateKDDData = async (payload: TKDDGeneratePayload) => {
    try {
        const response: AxiosResponse<TKDDResponse> = await axios.post<TKDDGeneratePayload, AxiosResponse<TKDDResponse>>(
            ROUTES.GENERATE_KDD(),
            payload
        );
        return response.data.response?.[0];
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const saveKDDData = async (payload: TKDDSavePayload) => {
    try {
        const response: AxiosResponse<TKDDSaveResponse> = await axios.post<TKDDSavePayload, AxiosResponse<TKDDSaveResponse>>(
            ROUTES.SAVE_KDD(),
            payload
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};