import { call, put } from "redux-saga/effects";
import { fetchExistingFiles, getSectionsById } from "../../services/DmapPhase0Services/ReviewPageService";
import { TFetchFiles, TSectionsByIdRequest } from "../DmapPhase0State/ReviewScreen.state";
import { fetchExistingFilesFailure, fetchExistingFilesSuccess, fetchSectionsByIdFailure, fetchSectionsByIdSuccess } from "../DmapPhase0Actions/ReviewScreen.actions";
import { AxiosError } from "axios";
import { TFetchFilesResponse } from "../../models/ReviewScreenModels";
import { TSectionDetails } from "../../models/landingPageModels";

// Side nav Data API saga
export function* fetchSectionsByIdSaga(action: TSectionsByIdRequest) {
  try {
    const { reqId } = action.payload; 
    const data: TSectionDetails[] = yield call(getSectionsById, reqId); 
    yield put(fetchSectionsByIdSuccess(data));
  } catch (error) {
    yield put(fetchSectionsByIdFailure(error as AxiosError));
  }
}

/**
 * Saga to fetch existing files data
 * @param action 
 */
export function* fetchExistingFilesSaga(action: TFetchFiles): Generator<any, void, any> {
    try {
      const response: TFetchFilesResponse = yield call(fetchExistingFiles, action.payload);
      yield put(fetchExistingFilesSuccess(response));
    } catch (error) {
      yield put(fetchExistingFilesFailure(error as AxiosError));
    }
  }
  