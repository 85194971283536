import { TPlanEstimates, TProjectAveragePayload, TProjectAvgResponse, TProjectTimeline, TProjectVisualPayload, TSaveProjectTimelinePayload, TSaveProjectTimelineResponse } from "../../models/ProjectPlan.model";
import { PROJECT_TIMELINE_AVERAGE_SUCCESS, PROJECT_TIMELINE_FAILURE, SET_PROJECT_TIMELINE_SELECTED_VALUES, PROJECT_TIMELINE_SUCCESS, PROJECT_VISUALS_FAILURE, PROJECT_VISUALS_SUCCESS, REQUEST_PROJECT_TIMELINE, REQUEST_PROJECT_TIMELINE_AVERAGE, REQUEST_PROJECT_VISUALS, REQUEST_SAVE_PROJECT_TIMELINE, RESET_PROJECT_TIMELINE, SAVE_PROJECT_TIMELINE_FAILURE, SAVE_PROJECT_TIMELINE_SUCCESS, TProjectTimelineFailure, TProjectTimelineRequest, TSetProjectTimelineSelectedValues, TProjectTimelineSuccess, TProjectVisualsFailure, TProjectVisualsRequest, TProjectVisualsSuccess, TResetProjectTimeline, TSaveProjectTimelineFailure, TSaveProjectTimelineSuccess, TSaveProjectTimelinesRequest } from "../DmapPhase0State/ProjectPlan.state";


export const fetchProjectTimeline = (requestId: string): TProjectTimelineRequest => ({
  type: REQUEST_PROJECT_TIMELINE,
  payload: requestId
});

export const fetchProjectTimelineSuccess = (data: TProjectTimeline[]): TProjectTimelineSuccess => ({
  type: PROJECT_TIMELINE_SUCCESS,
  payload: data,
});

export const fetchProjectTimelineFailure = (error: any): TProjectTimelineFailure => ({
  type: PROJECT_TIMELINE_FAILURE,
  payload: error,
});

export const setTimelineSelectedValues = (values: Record<string, Record<string, string>>): TSetProjectTimelineSelectedValues => ({
  type: SET_PROJECT_TIMELINE_SELECTED_VALUES,
  payload: values
})

export const resetProjectimeline = (): TResetProjectTimeline => ({
  type: RESET_PROJECT_TIMELINE
})

export const updateProjectTimeline = (data: TProjectAvgResponse) => ({
    type: PROJECT_TIMELINE_AVERAGE_SUCCESS,
    payload: data,
})

export const updateProjectTimelineError = (data: any) => ({
    type: PROJECT_TIMELINE_AVERAGE_SUCCESS,
    payload: data,
})

export const fetchAvg = (data: TProjectAveragePayload) => ({
  type: REQUEST_PROJECT_TIMELINE_AVERAGE,
  payload: data,
});

export const saveTimelineReport = (payload: TSaveProjectTimelinePayload): TSaveProjectTimelinesRequest => ({
  type: REQUEST_SAVE_PROJECT_TIMELINE,
  payload: payload,
});

export const saveTimelineReportSuccess = (payload: TSaveProjectTimelineResponse): TSaveProjectTimelineSuccess => ({
  type: SAVE_PROJECT_TIMELINE_SUCCESS,
  payload: payload,
});

export const saveTimelineReportFailure = (error: any): TSaveProjectTimelineFailure => ({
  type: SAVE_PROJECT_TIMELINE_FAILURE,
  payload: error,
});

export const generateVisuals = (payload: TProjectVisualPayload): TProjectVisualsRequest => ({
  type: REQUEST_PROJECT_VISUALS,
  payload: payload
});

export const generateVisualsSuccess = (response: TPlanEstimates[]): TProjectVisualsSuccess => ({
  type: PROJECT_VISUALS_SUCCESS,
  payload: response,
});

export const generateVisualsFailure = (error: any): TProjectVisualsFailure => ({
  type: PROJECT_VISUALS_FAILURE,
  payload: error,
});