import { combineReducers } from 'redux';
import { ALL_SECTION_DETAILS_FAILURE, ALL_SECTION_DETAILS_REQUEST, ALL_SECTION_DETAILS_SUCCESS, DataActionTypes, DataState, RequestByIdState, TDataState, TSectionDetailsActionTypes, TTableResponse } from '../DmapPhase0State/LandingPageState';
import {
  CREATE_NEW_REQUEST,
  CREATE_REQUEST_SUCCESS,
  CREATE_REQUEST_FAILURE,
  UPLOAD_NEW_FILE_FAILURE,
  UPLOAD_NEW_FILE_REQUEST,
  UPLOAD_NEW_FILE_SUCCESS,
  GET_REQUEST_BY_ID_REQUEST,
  GET_REQUEST_BY_ID_SUCCESS,
  GET_REQUEST_BY_ID_FAILURE,
  PROCESS_GENERATE_SLIDE_CONTENT_REQUEST,
  PROCESS_GENERATE_SLIDE_CONTENT_SUCCESS,
  PROCESS_GENERATE_SLIDE_CONTENT_FAILURE,
  PUT_EDITED_CONTENT_TO_SAVE_REQUEST,
  PUT_EDITED_CONTENT_TO_SAVE_FAILURE,
  PUT_EDITED_CONTENT_TO_SAVE_SUCCESS,
  FETCH_DOWNLOAD_FAILURE,
  FETCH_DOWNLOAD_REQUEST,
  FETCH_DOWNLOAD_SUCCESS,
  CHAT_PROMPT_FAILURE,
  CHAT_PROMPT_REQUEST,
  CHAT_PROMPT_SUCCESS,
  RESET_REQUEST_BY_ID,
  PUT_PROCESS_GENERATE_SLIDE_CONTENT_FAILURE,
  PUT_PROCESS_GENERATE_SLIDE_CONTENT_REQUEST,
  PUT_PROCESS_GENERATE_SLIDE_CONTENT_SUCCESS,
  MODIFY_HEADER_FAILURE,
  MODIFY_HEADER_REQUEST,
  MODIFY_HEADER_SUCCESS,
  SET_BOOLEAN_VARIABLE,
  RESET_UPLOAD_NEW_FILE,
  SAVE_REGENERATE_HEADER_FAILURE,
  SAVE_REGENERATE_HEADER_REQUEST,
  SAVE_REGENERATE_HEADER_SUCCESS,
  RESET_HEADER_RESPONSE,
  CHAT_PROMPT_RESET,
  SET_SAVE_FLAG,
  GET_PROJECT_TYPE_REQUEST,
  GET_PROJECT_TYPE_SUCCESS,
  GET_PROJECT_TYPE_FAILURE,
} from '../DmapPhase0Actions/LandingPageActions';
import { ProjectPlanReducer } from './ProjectPlan.reducer';
import { archReducers } from './Architecture.reducer';
import { processSimplificationReducer } from './ProcessSimplification.reducer';
import { workshopEnablersReducer } from './WorkshopEnablers.reducer';
import { kddReducer } from './KDD.reducer';
import { processMaturityReducer } from './ProcessMaturity.reducer';
import { existingFilesReducer, sectionsByIdReducer } from './ReviewScreen.reducer';
import { executiveSummaryReducer } from './ExcutiveSummary.reducer';
import { RequestDetailsResponse, TSectionDetails } from '../../models/landingPageModels';
import { chatPromptReducer } from './ChatPrompt.reducer';

const initialState: DataState = {
  data: [],
  loading: false,
  error: null,
};

const allRequestsReducer = (state = initialState, action: DataActionTypes): TDataState<TTableResponse<RequestDetailsResponse>> => {
  switch (action.type) {
    case 'FETCH_ALL_REQUESTS':
      return { ...state, loading: true, error: null };
    case 'FETCH_REQUESTS_SUCCESS':
      return { ...state, loading: false, data: action.payload };
    case 'FETCH_REQUESTS_FAILURE':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const createRequestReducer = (state = initialState, action: DataActionTypes): DataState => {
  switch (action.type) {
    case CREATE_NEW_REQUEST:
      return { ...state, loading: true, error: null };
    case CREATE_REQUEST_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case CREATE_REQUEST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const uploadFileReducer = (state = initialState, action: DataActionTypes): DataState => {
  switch (action.type) {
    case UPLOAD_NEW_FILE_REQUEST:
      return { ...state, loading: true, error: null };
    case UPLOAD_NEW_FILE_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case UPLOAD_NEW_FILE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case RESET_UPLOAD_NEW_FILE:
      return { ...initialState };
    default:
      return state;
  }
};

const requestByIdInitialState: RequestByIdState = {
  data: null,
  loading: false,
  error: null,
};

const fetchRequestByIdReducer = (
  state = requestByIdInitialState,
  action: DataActionTypes,
): DataState => {
  switch (action.type) {
    case GET_REQUEST_BY_ID_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_REQUEST_BY_ID_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_REQUEST_BY_ID_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };
    case RESET_REQUEST_BY_ID:
      return { ...requestByIdInitialState };
    default:
      return state;
  }
};

//for donwload zip btn
const varInitialState = {
  isBooleanVariable: false,
};

const variableReducer = (state = varInitialState, action: any) => {
  switch (action.type) {
    case SET_BOOLEAN_VARIABLE:
      return {
        ...state,
        isBooleanVariable: action.payload,
      };
    default:
      return state;
  }
};

//save variable
const saveInitialState = {
  saveBtnFlag: null,
};

const saveBtnFlagReducer = (state = saveInitialState, action: any) => {
  switch (action.type) {
    case SET_SAVE_FLAG:
      return {
        ...state,
        saveBtnFlag: {
          [action.payload.section]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

const processRequestInitialState: DataState = {
  data: null,
  loading: false,
  error: null,
};

const processRequestGenerateSlideContentReducer = (
  state = processRequestInitialState,
  action: DataActionTypes,
): DataState => {
  switch (action.type) {
    case PROCESS_GENERATE_SLIDE_CONTENT_REQUEST:
      return { ...state, loading: true, error: null };
    case PROCESS_GENERATE_SLIDE_CONTENT_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case PROCESS_GENERATE_SLIDE_CONTENT_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };
    default:
      return state;
  }
};


const allSectionDetailsReducer = (state = initialState, action: TSectionDetailsActionTypes): TDataState<TSectionDetails[]> => {
  switch (action.type) {
    case ALL_SECTION_DETAILS_REQUEST:
      return { ...state, loading: true, error: null };
    case ALL_SECTION_DETAILS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case ALL_SECTION_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const projectTypesReducer = (state = initialState, action: DataActionTypes): DataState => {
  switch (action.type) {
    case GET_PROJECT_TYPE_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_PROJECT_TYPE_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case GET_PROJECT_TYPE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//save editor content API
export const putEditedContentToSaveReducer = (
  state = { data: null, loading: false, error: null },
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case PUT_EDITED_CONTENT_TO_SAVE_REQUEST:
      return { ...state, loading: true, error: null };
    case PUT_EDITED_CONTENT_TO_SAVE_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case PUT_EDITED_CONTENT_TO_SAVE_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };
    default:
      return state;
  }
};

const downloadInitialState: DataState = {
  data: null,
  loading: false,
  error: null,
};

// download generated files
const fetchGeneratedFiledownloadReducer = (
  state = downloadInitialState,
  action: DataActionTypes,
): DataState => {
  switch (action.type) {
    case FETCH_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_DOWNLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    
    case FETCH_DOWNLOAD_FAILURE:
      return {
        loading: false,
        data: '',
        error: action.payload,
      };
    default:
      return state;
  }
};

// chat APi Reducer
export const chatApiReducer = (state = initialState, action: any) => { 

   switch (action.type) {
     case CHAT_PROMPT_REQUEST:
       return {
         ...state,
         loading: true,
       };
     case CHAT_PROMPT_SUCCESS:
       return {
         ...state,
         data: action.payload,
         loading: false,
         response: action.response,
       };
     case CHAT_PROMPT_FAILURE:
       return {
         ...state,
         loading: false,
         error: action.error,
       };
     case CHAT_PROMPT_RESET:
       return { ...initialState };
     default:
       return state;
   }
}

// Put Process APi reducer
const putProcessRequestInitialState: DataState = {
  data: null,
  loading: false,
  error: null,
};

const putProcessRequestGenerateSlideContentReducer = (
  state = putProcessRequestInitialState,
  action: DataActionTypes,
): DataState => {
  switch (action.type) {
    case PUT_PROCESS_GENERATE_SLIDE_CONTENT_REQUEST:
      return { ...state, loading: true, error: null };
    case PUT_PROCESS_GENERATE_SLIDE_CONTENT_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case PUT_PROCESS_GENERATE_SLIDE_CONTENT_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };
    default:
      return state;
  }
};


// regenerate headers API
const RegenerateHeaderState: DataState = {
  data: null,
  loading: false,
  error: null,
};

const regenerateHeaderReducer = (state = RegenerateHeaderState,
  action: DataActionTypes,
): DataState => {
  switch (action.type) {
    case MODIFY_HEADER_REQUEST:
      return { ...state, loading: true, error: null };
    case MODIFY_HEADER_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case MODIFY_HEADER_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };
    case RESET_HEADER_RESPONSE:
      return { ...initialState, data: undefined };
    default:
      return state;
  }
};

//save headers reducer.
const SaveRegenerateHeaderState: DataState = {
  data: null,
  loading: false,
  error: null,
};

const saveRegenerateHeaderReducer = (state = SaveRegenerateHeaderState, action: DataActionTypes): DataState => {
  switch (action.type) {
    case SAVE_REGENERATE_HEADER_REQUEST:
      return { ...state, loading: true, error: null };
    case SAVE_REGENERATE_HEADER_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case SAVE_REGENERATE_HEADER_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  allRequests: allRequestsReducer,
  createRequest: createRequestReducer,
  uploadFile: uploadFileReducer,
  postProcessRequestgeneration: processRequestGenerateSlideContentReducer,
  allSections: allSectionDetailsReducer,
  createFormProjectTypes: projectTypesReducer,
  fetchRequestId: fetchRequestByIdReducer,
  sectionsById: sectionsByIdReducer,
  editedContentToSave: putEditedContentToSaveReducer,
  fetchDownloadGeneratedFile: fetchGeneratedFiledownloadReducer,
  chatContent: chatApiReducer,
  booleanReducer: variableReducer,
  saveBtnFlagReducer: saveBtnFlagReducer,
  putProcess: putProcessRequestGenerateSlideContentReducer,
  regenerateHeader: regenerateHeaderReducer,
  saveHeader: saveRegenerateHeaderReducer,
  projectPlan: ProjectPlanReducer,
  architecture: archReducers,
  processSimplification: processSimplificationReducer,
  workshopEnablers: workshopEnablersReducer,
  kdd: kddReducer,
  processMaturity: processMaturityReducer,
  reviewScreen: existingFilesReducer,
  executiveSummary:executiveSummaryReducer,
  chatPrompt: chatPromptReducer
});

export default rootReducer;
