import axios, { AxiosResponse } from "axios";
import { TDownloadFileResponse } from "../../store/DmapPhase0State/WorkshopEnablers.state";

  /**
   * Method to get download link for specific file from API
   * @param requestId 
   * @param fileName 
   * @returns 
   */
  export const getDownloadLinkForFile = async (requestId: string, fileName: string) => {
    try {
      const response = await axios.get<string, AxiosResponse<TDownloadFileResponse>>(
        `${process.env.REACT_APP_BASE_URL}/api/phase-zero/request/download-output-as-zip?requestId=${requestId}&filePath=/output/workshopEnablers/&fileName=${fileName}`,
      );
      return response.data;
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };