import { AxiosError } from "axios";
import { TSubSectionResponse, TTableResponse } from "../store/DmapPhase0State/LandingPageState";
import { TArchitectureState } from "../store/DmapPhase0State/Architecture.state";
import { SECTION_CONSTANT_MAPPING, SUB_SECTION_MAPPING } from "../../constants";

export type TArchResponse = {
  response: TSubSectionResponse<TArchTableData>[];
};

export type TArchImgPayload = {
  requestId: string;
  sectionType: string;
  subsection: string;
};

export type TArchImgResponse = {
  request_id: string;
  url: string;
};

export type TArchData = {
  response: string;
  highlights: string;
  table: TTableResponse<TArchTableData> | null;
};

export type TArchTableData = {
  'Source System': string;
  'Source System Type': string;
  'Description': string;
  'SAP Module': string;
  'Treatment': string;
  'GENAI Recommendation for Treatment': string;
  'Justification': string;
};

export type TArchSuccess<T> = {
  response: T;
  subsection: string;
}

export type TArchFailure = {
  error: AxiosError,
  subsection: string
}

export type TGenerateDiagramPayload = {
  requestId: string;
  selectedFilters: Record<string, string[]>;
  subsection: string
}

export type TUploadFilesPayload = {
  requestId: string;
  prefixes: string;
  uploadType: string;
  files: File[];
  subsection: string;
}

export type TArchGenericResponse = {
  requestId: string;
  message: string;
}

export const archSubsectionToReducerMapping: {[key: string]: keyof TArchitectureState} = {
  [SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.ARCHITECTURE].AS_IS_ARCHITECTURE]: 'asIsArchitecture',
  [SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.ARCHITECTURE].GENERATE_ARCHITECTURE_DIAGRAM]: 'toBeArchitecture'
}

export type TResetArchSubsectionPayload = {
  subsection: string;
}