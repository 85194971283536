import React from 'react';
import Estimator from '../../../ProjectPlan/Estimator/Estimator';
import PlanEstimates from '../../../ProjectPlan/PlanEstimates/PlanEstimates';
import { connect, useSelector } from 'react-redux';
import { TRootState } from '../../../../libs/store/DmapPhase0State/LandingPageState';
import { Dispatch } from 'redux';
import SelectSubsection from '../../../../Components/SelectSubsection/SelectSubsection';
import { TSectionDetails } from '../../../../libs/models/landingPageModels';

interface ProjectplanProp {
  selectedItem: string;
  onSelectedMenuItem: string;
  setSelectedSubsection: (subsection: string) => void;
  requestName: string;

  sectionDetails?: TSectionDetails | null;
}

const ProjectPlan: React.FC<ProjectplanProp> = ({
  selectedItem,
  onSelectedMenuItem,
  setSelectedSubsection,

  sectionDetails
}) => {

  return (
    sectionDetails?.subsections?.length && onSelectedMenuItem ?
    <>
      {selectedItem === 'Project Plan' &&
        (onSelectedMenuItem === 'Estimator' ? (
          <Estimator selectedItem={selectedItem} setSelectedSubsection={setSelectedSubsection} />
        ) : onSelectedMenuItem === 'Project Plan Estimates' ? (
          <PlanEstimates />
        ) : (
          <>
          </>
        ))}
    </>
    :
    <SelectSubsection />
  );
};

const mapStateToProps = (state: TRootState, {selectedItem}: ProjectplanProp) => ({
  sectionDetails: state.sectionsById?.data?.find((section: TSectionDetails) => section.sectionConstant === selectedItem)
})

const mapDispatchToProps = (dispatch: Dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPlan);
