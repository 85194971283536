import React, { useEffect, useState } from 'react';
import { RequestDetailsResponse } from '../../libs/models/landingPageModels';
import { TableColumnsType, TablePaginationConfig, Tag, Tooltip, message } from 'antd';
import { getStatusColor } from '../../utils/dashboard.utils';
import {
  FetchDataRequestAction,
  TDataState,
  TRootState,
  TTableResponse,
} from '../../libs/store/DmapPhase0State/LandingPageState';
import { Dispatch } from 'redux';
import { fetchAllRequests } from '../../libs/store/DmapPhase0Actions/LandingPageActions';
import { connect } from 'react-redux';
import CustomTable from '../DynamicForm/components/CustomTable/CustomTable';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { useHistory } from 'react-router';
import './AllRequests.less';
import { FilterValue } from 'antd/lib/table/interface';

interface Props {
  allRqsts?: TDataState<TTableResponse<RequestDetailsResponse>>;

  getAllRqsts?: (pageNumber?: number, pageSize?: number) => FetchDataRequestAction;
}

const AllRequests: React.FC<Props> = ({ allRqsts, getAllRqsts }) => {
  const history = useHistory();
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [pageSize, setPageSize] = useState<number>(5);
  const [pageNumber, setPageNumber] = useState<number>(1);
    let interval: NodeJS.Timeout;

  useEffect(() => {
    getAllRqsts && getAllRqsts(pageNumber, pageSize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    interval = setInterval(async () => {
      try {
        getAllRqsts && getAllRqsts(pageNumber, pageSize);
      } catch (error) {
        message.error('Error updating table data:');
        console.error(error);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    interval = setInterval(async () => {
        try {
              getAllRqsts && getAllRqsts(pageNumber, pageSize);
        } catch (error) {
          message.error('Error updating table data:');
          console.error(error);
        }
      }, 3000);
      return () => clearInterval(interval);

  }, [pageSize, pageNumber])

  useEffect(() => {
    if(allRqsts?.data?.colNames && firstLoad){
        setFirstLoad(false);
    }
  }, [allRqsts]);

  const columns = ['id', 'requestName', 'uploadStatus', 'lastupdatedBy', 'lastUpdatedOn'];

  const columnsConfig: TableColumnsType<Record<string, string>> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: 'REQUEST NAME',
      dataIndex: 'requestName',
      key: 'requestName',
      width: '35%',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: RequestDetailsResponse | any) => (
        <Tag className="tag-text" color={getStatusColor(text.toLowerCase())}>
          {record.errorTitle && record.status !== 'Completed' ? (
            <Tooltip title={record.errorTitle}>{text.toUpperCase()}</Tooltip>
          ) : (
            text.toUpperCase()
          )}
        </Tag>
      ),
      width: '20%',
    },
    {
      title: 'LAST UPDATED BY',
      dataIndex: 'lastUpdatedBy',
      key: 'lastUpdatedBy',
      width: '18%',
    },
    {
      title: 'LAST UPDATED ON',
      dataIndex: 'lastUpdatedOn',
      key: 'lastUpdatedOn',
      width: '17%',
      render: (text: string, record: RequestDetailsResponse | any) => (
        <span>
          {
          new Date(text)?.toLocaleDateString('en-GB', {day: '2-digit', year: 'numeric',  month: 'short', hourCycle: 'h12', second: '2-digit', hour: '2-digit', minute: '2-digit'})
          }
        </span>
      ),
    },
  ];

  const redirectToReviewScreen = (record: Record<string, string>) => {
    history.push(`/review/${record?.id}`);
  };

  const onChange = (paginationConfig:  TablePaginationConfig, selectedFilters: Record<string, FilterValue | null>, action: 'paginate' | 'sort' | 'filter') => {
    getAllRqsts && getAllRqsts(paginationConfig.current || -1, paginationConfig.pageSize || DEFAULT_PAGE_SIZE)
    setPageNumber(paginationConfig.current || -1);
    setPageSize(paginationConfig.pageSize || DEFAULT_PAGE_SIZE)
  }

  return (
    <div className="request-table">
        <CustomTable
          loading={(allRqsts?.loading || false) && firstLoad}
          data={(allRqsts?.data?.content || []) as unknown as Record<string, string>[]}
          tableName="all-requests"
          filterCols={allRqsts?.data?.filters}
          onRowClick={redirectToReviewScreen}
          pageSize={allRqsts?.data?.pageSize || DEFAULT_PAGE_SIZE}
          totalCount={allRqsts?.data?.totalCount}
          pageNumber={allRqsts?.data?.pageNumber}
          tableConfig={columnsConfig}
          onChange={onChange}
          colNames={columns}
          pagination={true}
          scrollProps={{y: `calc(100vh - 280px)`, x: 'none'}}
          uniqueKey='id'
        />
    </div>
  );
};

const mapStateToProps = (state: TRootState) => ({
  allRqsts: state.allRequests,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllRqsts: (pageNumber?: number, pageSize?: number) => dispatch(fetchAllRequests(pageNumber, pageSize)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllRequests);
