import React from 'react';
import ProcessSimplification from './Content/ProcessSimplification/ProcessSimplification';
import ProcessMaturity from './Content/ProcessMaturity/ProcessMaturity';
import ProjectPlan from './Content/ProjectPlan/ProjectPlan';
import KeyDecisionDesigns from './Content/KDD/KeyDecisionDesigns';
import Architecture from './Content/Architecture/Architecture';
import WorkshopEnablers from './Content/WorkshopEnablers/WorkshopEnablers';
import ExecutiveSummary from './Content/ExecutiveSummary/ExecutiveSummary';

 export interface ComponentConfig {
  component: React.ComponentType<any>;
  parentProps?: any; // Props for the parent component are optional
  subcomponents?: { [key: string]: ComponentConfig };
}

interface ContentConfig {
  [key: string]: ComponentConfig;
}


const baseParentProps = {
  selectedItem: null,
  requestId: null,
  onSelectedMenuItem: null,
  requestName: null,
  onSave :null,
};

const contentConfig: ContentConfig = {
  'Transformation Deepdive': {
    component: ProcessSimplification,
    parentProps: { ...baseParentProps, startPolling: null, stopPolling: null, updateSideNav: null },
  },
  'Process transformation': {
    component: ProcessMaturity,
    parentProps: {
      baseParentProps,
    },
  },
  // 'Technology transformation': {
  //   component: TechnologySimplification,
  //   parentProps: baseParentProps,
  // },
  'Implementation Plan': {
    component: ProcessMaturity,
    parentProps: {},
  },
  'Architecture': {
    component: Architecture,
    parentProps: {
      baseParentProps,
    },
  },
  'Workshop Enablers and KDDs': {
    component: KeyDecisionDesigns,
    parentProps: {},
  },
  'Workshop Enablers': {
    component: WorkshopEnablers,
    parentProps: {},
  },
  'Project Plan': {
    component: ProjectPlan,
    parentProps: {},
  },
  'Executive Summary': {
    component: ExecutiveSummary,
    parentProps: {},
  },
};

export default contentConfig;


