import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, CheckboxProps, Divider, Form, Skeleton, Spin, TablePaginationConfig } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import './KeyDecisiondesigns.less'
import { TDataState, TFetchSubSectionPayload, TRootState, TSaveFlagAction, TSectionPayload, TSubSectionResponse } from '../../../../libs/store/DmapPhase0State/LandingPageState';
import { TKDDGeneratePayload, TKDDSaveResponse, TKDDTable } from '../../../../libs/models/KDD.model';
import { Dispatch } from 'redux';
import { fetchKDD, fetchKDDSubSection, generateKDDRequest } from '../../../../libs/store/DmapPhase0Actions/KDD.actions';
import { TKDDGenerateRequest, TKDDRequest, TKDDSubSectionRequest } from '../../../../libs/store/DmapPhase0State/KDD.state';
import CustomTable from '../../../../Components/DynamicForm/components/CustomTable/CustomTable';
import { FilterValue } from 'antd/lib/table/interface';
import { DEFAULT_PAGE_SIZE, SECTION_CONSTANT_MAPPING, SUB_SECTION_MAPPING } from '../../../../constants';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form/Form';
import DynamicForm from '../../../../Components/DynamicForm/DynamicForm';
import { GENERATE_KDD_USING_PROMPT_FIELDS_CONFIG } from '../../../../libs/constants/KDD.const';
import { updateSaveValue } from '../../../../libs/store/DmapPhase0Actions/LandingPageActions';
import SelectSubsection from '../../../../Components/SelectSubsection/SelectSubsection';
import { hasPagination } from '../../../../utils/ReviewScreen.util';
import { TSectionDetails } from '../../../../libs/models/landingPageModels';
import { TSectionsByIdRequest } from '../../../../libs/store/DmapPhase0State/ReviewScreen.state';
import { fetchSectionsById } from '../../../../libs/store/DmapPhase0Actions/ReviewScreen.actions';
interface Props {
  selectedItem: string;
  onSelectedMenuItem: string;
  requestName: string;
  tableSelectedRows: Record<number, Record<string, string | boolean>[]>;
  onTableSelectionChange: (pageNumber: number, selectedRows: Record<string, string>[]) => void;
  searchText: string;

  setSearchText: (text: string) => void;
  saveKDDDetails?: TDataState<TKDDSaveResponse> | null;
  sectionDetails?: TSectionDetails | null;
  saveBtnFlags?: Record<string, boolean>;
  subsections?: TDataState<TSubSectionResponse<TKDDTable>[]> | null;
  fetchKDDData?: (payload: TSectionPayload) => TKDDRequest;
  fetchKDDSubSectionData?: (payload: TFetchSubSectionPayload) => TKDDSubSectionRequest;
  fetchSections?: (reqId: number) => TSectionsByIdRequest;
  generateData?: (payload: TKDDGeneratePayload) => TKDDGenerateRequest;
  updateSaveBtnFlag: (section: string, flag: boolean) => TSaveFlagAction;
}

const KDD: React.FC<Props> = ({
  selectedItem,
  onSelectedMenuItem,
  saveBtnFlags,
  saveKDDDetails,
  sectionDetails,
  subsections,
  fetchKDDData,
  fetchKDDSubSectionData,
  tableSelectedRows,
  onTableSelectionChange,
  searchText,
  setSearchText,
  fetchSections,
  generateData,
  updateSaveBtnFlag
}) => {
  const { id }: { id: string } = useParams();
  const [sectionData, setSectionData] = useState<any[]>([]);
  const [response, setResponse] = useState<string>('');
  const [highlights, setHighlights] = useState<string>('');
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [promptFieldValidated, setPromptFieldValidated] = useState<boolean>(false);
  const [generateForm] = Form.useForm<FormInstance>();

  useEffect(() => {
    if (id) {
      fetchKDDData && fetchKDDData({
        reqId: id,
        selectedSection: selectedItem,
        pageSize: 10
      })
    }
  }, [id]);

  useEffect(() => {
    const selectedItemData = subsections?.data?.find(
      (item: TSubSectionResponse<TKDDTable>) => item.subsection === onSelectedMenuItem,
    );
    if(selectedItemData && tableSelectedRows?.[selectedItemData?.data?.table?.pageNumber || -1]?.length && !saveBtnFlags?.[selectedItem]){
      updateSaveBtnFlag && updateSaveBtnFlag(selectedItem, true)
    }
    else if(selectedItemData && !tableSelectedRows?.[selectedItemData?.data?.table?.pageNumber || -1]?.length && (saveBtnFlags?.[selectedItem] || true)){
      updateSaveBtnFlag && updateSaveBtnFlag(selectedItem, false)
    }
  }, [tableSelectedRows])

  useEffect(() => {
    if (
      onSelectedMenuItem &&
      subsections?.data &&
      subsections?.data?.length
    ) {
      const selectedItemData = subsections.data.find(
        (item: any) => item.subsection === onSelectedMenuItem,
      );
      if (selectedItemData) {
        const { response, highlights } = selectedItemData.data;
        setResponse(response);
        setHighlights(highlights || '');
        if (!response || response.length === 0) {
          setSectionData(subsections.data);
        } else {
          setSectionData([]);
        }
      }
    }
  }, [onSelectedMenuItem, subsections]);

  // Change isGenerating Flag once data is back
  useEffect(() => {
    if(!subsections?.loading && isGenerating){
      setIsGenerating(false)
    }
  }, [subsections?.loading]);

  useEffect(() => {
    if(saveKDDDetails){
      if(saveKDDDetails?.loading){
        setIsSaving(true)
      }
      else{
        if(saveKDDDetails?.data){
          // Adding 15 seconds wait to make sure DB is updated and data is fetched after that
          setTimeout(() => {
            fetchKDDData && fetchKDDData({
              reqId: id,
              selectedSection: selectedItem,
              pageSize: 10
            })
            fetchSections && fetchSections(parseInt(id))
            setSearchText('');
            setIsSaving(false);
          }, 15000)
        }
        else{
          console.error('Error while saving data: ',saveKDDDetails?.error)
          setIsSaving(false);
        }
      }
    }
  }, [saveKDDDetails])

  const onChange = (paginationConfig:  TablePaginationConfig, selectedFilters: Record<string, FilterValue | null>, action: 'paginate' | 'sort' | 'filter') => {
    fetchKDDSubSectionData && fetchKDDSubSectionData({
      requestId: id,
      body: {
        selectedFilters: Object.keys(selectedFilters)
        .filter((k) => selectedFilters[k] != null)
        .reduce((a, k) => ({ ...a, [k]: selectedFilters[k] }), {}),
        page: paginationConfig.current || -1,
        pageSize: paginationConfig.pageSize || DEFAULT_PAGE_SIZE,
        section: selectedItem,
        subsection: onSelectedMenuItem,
        search: searchText?.length ? searchText : undefined
      }
    })
  }

  const onGenerate = () => {
    if(!promptFieldValidated){
      setPromptFieldValidated(_ => true);
    }
    if(generateData){
      generateForm
      .validateFields()
      .then((_: FormInstance) => {
        setIsGenerating(_ => true);
        generateData({
          section: selectedItem,
          subsection: onSelectedMenuItem,
          request_id: parseInt(id),
          prompt: generateForm.getFieldValue('prompt')
        })
        generateForm.resetFields();
      })
      .catch((error: any) => {
        console.warn(error);
      });
    }
  }

  const renderGenerateData = (): ReactElement => {
    return (
      <div className="empty-data-container">
        <div className="icon-container">
          <QuestionCircleOutlined className="no-data-icon" />
        </div>
        <div className="empty-data-text">No matches found</div>
        <div className="user-input-container">
          <DynamicForm
            fieldsConfig={GENERATE_KDD_USING_PROMPT_FIELDS_CONFIG}
            form={generateForm}
          />
          <Button className="submit-btn" onClick={onGenerate}>
            Generate
          </Button>
        </div>
      </div>
    );
  };

  const renderCustomLoader = (message: string): ReactElement => (
    <Spin tip={`${message}...`} size="small">
      {new Array(3).fill(1).map(_ => <Skeleton  />)}
      </Spin>
  )

  const renderEditableContent = (content: any, index: any) => {
    return (
      <>
        <TextArea
          autoSize={{ minRows: content.split('\n').length - 4 }}
          key={index}
          value={content}
          style={{ border: 'none', overflow: 'hidden' }}
          readOnly={true}
          onChange={(e) => {}}
        />
        <Divider style={{ margin: '0', padding: '0' }} />
      </>
    );
  };

  const renderContentWithDividers = (response: any, setResponse: any) => {
    if (typeof response !== 'string' || response.length === 0) {
      return null;
    }
    const parts = response.split('\n______\n');
    const renderedParts: any = [];
    parts.forEach((part: any, index: any) => {
      renderedParts.push(renderEditableContent(part, index));
    });
    return renderedParts;
  };
  const tableData = subsections?.data?.find((data: TSubSectionResponse<TKDDTable>) => data.subsection === SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.WORKSHOP_ENABLER_AND_KDD].OVERVIEW)

  const getCheckboxConfig = (record: Record<string, string>): CheckboxProps => {
    return {
    disabled: tableData?.data?.table?.colNames?.includes('show') ? Boolean(record?.['show']) : true 
}}

  return (
    sectionDetails?.subsections?.length && onSelectedMenuItem ?
    <div className="kdd-screen-container">
      {(onSelectedMenuItem === SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.WORKSHOP_ENABLER_AND_KDD].OVERVIEW || sectionData.length > 0) ? (
        <CustomTable
          loading={(isGenerating || isSaving) ? false : (subsections?.loading || false)}
          data={(!subsections?.loading && !isSaving) ? (tableData?.data?.table?.content || []) : []}
          tableName="kdd"
          filterCols={tableData?.data?.table?.filters}
          pageSize={tableData?.data?.table?.pageSize || DEFAULT_PAGE_SIZE}
          totalCount={tableData?.data?.table?.totalCount}
          onChange={onChange}
          pagination={true}
          colNames={tableData?.data?.table?.colNames?.filter((key: string) => key !== 'show') || []}
          pageNumber={tableData?.data?.table?.pageNumber}
          hasRowSelection={true}
          onRowSelection={onTableSelectionChange}
          checkboxConfig={getCheckboxConfig}
          selectedRows={tableSelectedRows?.[tableData?.data?.table?.pageNumber || -1] || []}
          emptytDataContent={isGenerating ? renderCustomLoader('Generating the data') : isSaving ? renderCustomLoader('Saving the data') : renderGenerateData()}
          scrollProps={{x: '0', y: `calc(100vh - ${hasPagination(tableData?.data?.table?.totalCount, tableData?.data?.table?.pageSize) ? '17.765rem' : '15.165rem'})`}}
          uniqueKey={'Issue Description'}
        />
      ) : <></>}
      <div className="kdd-text-area">
        {response && renderContentWithDividers(response, setResponse)}
      </div>
      {highlights !== null && highlights !== undefined && highlights !== '' && (
        <TextArea
          value={highlights}
          className="text-area-highlights"
          autoSize={{ minRows: 5 }}
          style={{ border: 'none', overflow: 'hidden' }}
        />
      )}
    </div> :
    <SelectSubsection />
  );
};

const mapStateToProps = (state: TRootState, {selectedItem}: Props) => ({
  subsections: state.kdd.subsections,
  saveBtnFlags: state.saveBtnFlagReducer.saveBtnFlag,
  saveKDDDetails: state.kdd.save,
  sectionDetails: state.sectionsById?.data?.find((section: TSectionDetails) => section.sectionConstant === selectedItem)
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchKDDData: (payload: TSectionPayload) => dispatch(fetchKDD(payload)),
  fetchKDDSubSectionData: (payload: TFetchSubSectionPayload) => dispatch(fetchKDDSubSection(payload)),
  generateData: (payload: TKDDGeneratePayload) => dispatch(generateKDDRequest(payload)),
  updateSaveBtnFlag: (section: string, flag: boolean) => dispatch(updateSaveValue(section, flag)),
  fetchSections: (id: number) => dispatch(fetchSectionsById(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(KDD);
