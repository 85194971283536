import axios, { AxiosResponse } from "axios";
import { TPromptResultPayload, TPromptResultResponse } from "../../models/ChatPrompt.model";
import { ROUTES } from "../../../constants";

export const generateChatResult = async (payload: TPromptResultPayload) => {
    try {
        const response: AxiosResponse<TPromptResultResponse> = await axios.post<TPromptResultPayload, AxiosResponse<TPromptResultResponse>>(
            ROUTES.GENERATE_PROMPT_RESULT('DocFlag=false'),
            payload
        );
        return response.data.response?.[0];
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const applyChatToAllSubsections = async (payload: TPromptResultPayload) => {
    try {
        const response: AxiosResponse<TPromptResultResponse> = await axios.post<TPromptResultPayload, AxiosResponse<TPromptResultResponse>>(
            ROUTES.GENERATE_PROMPT_RESULT('useClientInfo=false'),
            payload
        );
        return response.data.response?.[0];
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};