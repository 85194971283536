import { TPromptResultPayload, TSubsectionPromptResult } from "../libs/models/ChatPrompt.model";

export const getPayloadForGenerateAndApplyPrompt = (request_id: string, section: string, userPrompt: string, currentSubsectionName: string, currentSubsectionData: string, subsections: TSubsectionPromptResult[]): TPromptResultPayload => {
    return {
        request_id,
        section,
        subsections,
        currentSubsectionName,
        currentSubsectionData,
        userPrompt
    }
}

export const getLoadingText = (loadingDetails?: boolean, loadingOptimizedText?: boolean, saving?: boolean, applyingChatToAllSubsections?: boolean): string => {
    if(loadingDetails){
        return 'Fetching Data...';
    }
    else if(loadingOptimizedText){
        return 'Optimizing the data...';
    }
    else if(saving){
        return 'Saving the data...';
    }
    else if(applyingChatToAllSubsections){
        return 'Applying Changes To All Subsections...';
    }
    else{
        return 'Loading...'
    }
}