import { TExecutiveSummaryPayload } from '../../models/ExecutiveSummary.model';
import { TSubSectionResponse } from './LandingPageState';

export const REQUEST_EXECUTIVE_SUMMARY = 'REQUEST_EXECUTIVE_SUMMARY';
export const EXECUTIVE_SUMMARY_SUCCESS = 'EXECUTIVE_SUMMARY_SUCCESS';
export const EXECUTIVE_SUMMARY_FAILURE = 'EXECUTIVE_SUMMARY_FAILURE';


export type TExecutiveSummaryRequest = {
  type: 'REQUEST_EXECUTIVE_SUMMARY';
  payload: TExecutiveSummaryPayload;
};

export type TExecutiveSummarySuccess = {
  type: 'EXECUTIVE_SUMMARY_SUCCESS';
  payload: TSubSectionResponse<null>[];
};

export type TExecutiveSummaryFailure = {
  type: 'EXECUTIVE_SUMMARY_FAILURE';
  payload: any;
};


export type TExecutiveSummaryActionTypes =
    | TExecutiveSummaryRequest
    | TExecutiveSummarySuccess
    | TExecutiveSummaryFailure;
