import { TChatPromptState } from "../../models/ChatPrompt.model";
import { TChatPromptActionTypes } from "../DmapPhase0State/ChatPrompt.state";

const initialState: TChatPromptState = {
    generateResult: null,
    applyChatToAll: null
};

export const chatPromptReducer = (
    state = initialState,
    action: TChatPromptActionTypes,
): TChatPromptState => {
    switch (action.type) {
        case "PROMPT_RESULT_REQUEST":
            return {
                ...state,
                generateResult: {
                    data: null,
                    loading: true,
                    error: null
                }
            }
        case "PROMPT_RESULT_SUCCESS":
            return {
                ...state,
                generateResult: {
                    data: action.payload,
                    loading: false,
                    error: null
                }
            }
        case 'PROMPT_RESULT_FAILURE':
            return {
                ...state,
                generateResult: {
                    data: null,
                    loading: false,
                    error: action.payload
                }
            }
        case "RESET_PROMPT_RESULT":
            return {
                ...state,
                generateResult: null
            }
        case 'APPLY_CHAT_TO_ALL_REQUEST':
            return {
                ...state,
                applyChatToAll: {
                    data: null,
                    loading: true,
                    error: null
                }
            }
        case 'APPLY_CHAT_TO_ALL_SUCCESS':
            return {
                ...state,
                applyChatToAll: {
                    data: action.payload,
                    loading: false,
                    error: null
                }
            }
        case 'APPLY_CHAT_TO_ALL_FAILURE':
            return {
                ...state,
                applyChatToAll: {
                    data: null,
                    loading: false,
                    error: action.payload
                }
            }
        case 'RESET_APPLY_CHAT_TO_ALL':
            return {
                ...state,
                applyChatToAll: null
            }
        default:
            return { ...state };
    }
};