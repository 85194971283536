import { AxiosError } from "axios";
import { TPromptResultPayload, TSubsectionPromptResult } from "../../models/ChatPrompt.model";
import { APPLY_CHAT_TO_ALL_FAILURE, APPLY_CHAT_TO_ALL_REQUEST, APPLY_CHAT_TO_ALL_SUCCESS, PROMPT_RESULT_FAILURE, PROMPT_RESULT_REQUEST, PROMPT_RESULT_SUCCESS, RESET_APPLY_CHAT_TO_ALL, RESET_PROMPT_RESULT, TApplyChatToAllFailure, TApplyChatToAllRequest, TApplyChatToAllSuccess, TPromptResultFailure, TPromptResultRequest, TPromptResultSuccess, TResetApplyChatToAll, TResetPromptResult } from "../DmapPhase0State/ChatPrompt.state";

export const fetchPromptResult = (payload: TPromptResultPayload): TPromptResultRequest => ({
    type: PROMPT_RESULT_REQUEST,
    payload,
});

export const fetchPromptResultSuccess = (payload: TSubsectionPromptResult): TPromptResultSuccess => ({
    type: PROMPT_RESULT_SUCCESS,
    payload
})

export const fetchPromptResultFailure = (error: AxiosError): TPromptResultFailure => ({
    type: PROMPT_RESULT_FAILURE,
    payload: error
})

export const resetPromptResult = (): TResetPromptResult => ({
    type: RESET_PROMPT_RESULT
})

export const applyChatToAllRequest = (payload: TPromptResultPayload): TApplyChatToAllRequest => ({
    type: APPLY_CHAT_TO_ALL_REQUEST,
    payload,
});

export const applyChatToAllSuccess = (payload: TSubsectionPromptResult): TApplyChatToAllSuccess => ({
    type: APPLY_CHAT_TO_ALL_SUCCESS,
    payload
})

export const applyChatToAllFailure = (error: AxiosError): TApplyChatToAllFailure => ({
    type: APPLY_CHAT_TO_ALL_FAILURE,
    payload: error
})

export const resetApplyChatToAll = (): TResetApplyChatToAll => ({
    type: RESET_APPLY_CHAT_TO_ALL
})
