import { FormInstance } from 'antd';
import { SECTION_DOC__REQUIRED_MAPPING } from 'libs/constants/NewRqst.const';
import { TFormField } from 'libs/models/NewRqst.model';

export const getNewRqstPayload = (fields: TFormField[], form: FormInstance) => {
  const projectTypeId: number = parseInt(form.getFieldValue('projectType') || '');
  const sections = form.getFieldValue('sectionDropdown')?.join(',');
  const fileFields = fields.filter(
    (field: TFormField) => field.type === 'upload' && form.getFieldValue(field.name),
  );
  const prefixes =
    fileFields?.map((field: TFormField) => {
      if (field.key === 'RFP') {
        return `RFP_${form.getFieldValue(field.name)?.[0]?.originFileObj?.name?.split('.')?.[0]}`;
      }
      return field.key;
    }) || [];
  const files: Record<string, any> = {};
  fileFields?.forEach((field: TFormField) => {
    files[`${field.key}_${form.getFieldValue(field.name)?.[0]?.originFileObj?.name}`] =
      form.getFieldValue(field.name)?.[0]?.originFileObj;
  });

  return {
    rqstName: form.getFieldValue('requestName'),
    projectTypeId,
    sections,
    prefixes,
    files,
  };
};

/**
 *  Method gives N Dynamic fields
 * @param renderField which return one whole fields array that need to be replicated
 * @param count
 * @returns
 */
export const getNDynamicFields = (
  renderField: (index: number) => TFormField[],
  count: number,
): TFormField[] => {
  if (!count) {
    return [];
  }
  return new Array(count)
    .fill(1)
    .map((_: number, index: number) => renderField(index + 1))
    .flat(1);
};

/**
 * This method returns Promise based on upload field is valid or not
 * @param getFieldValue
 * @param depFieldName
 * @param fieldVal
 * @param key
 * @param errMsg
 * @returns
 */
export const uploadFieldDynamicValidation = (
  getFieldValue: (field: string) => any,
  depFieldName: string,
  fieldVal: any,
  key: string,
  errMsg: string,
): Promise<any> => {
  const requiredDocs: string[] = getFieldValue(depFieldName)
    ?.map((section: string) => (SECTION_DOC__REQUIRED_MAPPING as any)[section])
    ?.flat(1);

  if (requiredDocs?.includes(key) && !fieldVal) {
    return Promise.reject(new Error(errMsg));
  }
  return Promise.resolve();
};
