import { Form, FormInstance, Spin } from 'antd';
import DynamicForm from 'Components/DynamicForm/DynamicForm';
import ReusableModal from 'Components/FormModal/ReusableModal';
import {
  NEW_RQST_FIELDS_1,
  NEW_RQST_FIELDS_2,
  getRFPDocFields,
} from 'libs/constants/NewRqst.const';
import { TProjectType, TSectionDetails } from 'libs/models/landingPageModels';
import { TCreateRqstPayload } from 'libs/models/NewRqst.model';
import {
  createNewRequest,
  fetchAllSectionDetailsRequest,
  fetchProjectTypeRequest,
} from 'libs/store/DmapPhase0Actions/LandingPageActions';
import {
  TAllSectionDetailsPayload,
  TAllSectionDetailsRequest,
  TFetchProjectTypesRequest,
  TRootState,
} from 'libs/store/DmapPhase0State/LandingPageState';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getNDynamicFields, getNewRqstPayload } from 'utils/newRqst.util';
import './CreatenewRequest.less';

interface Props {
  visible: boolean;
  onCancel: () => void;

  sectionDropdownData?: TSectionDetails[] | null;
  projectTypes?: TProjectType[] | null;

  fetchProjectTypes?: () => TFetchProjectTypesRequest;
  fetchAllSectionDetails?: (payload: TAllSectionDetailsPayload) => TAllSectionDetailsRequest;
  createRqst?: ({ rqstName, sections, filesPayload, operation }: TCreateRqstPayload) => any;
}

const CreateNewRequest: React.FC<Props> = ({
  visible,
  onCancel,
  sectionDropdownData,
  projectTypes,
  fetchProjectTypes,
  fetchAllSectionDetails,
  createRqst,
}) => {
  const [form1] = Form.useForm<FormInstance>();
  let [rfpForm] = Form.useForm<FormInstance>();

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [rfpFormModalVisible, setRfpFormModalVisible] = useState<boolean>(false);
  const [form1ModalVisible, setForm1ModalVisible] = useState<boolean>(visible);
  const [rfpCount, setRfpCount] = useState<number>(1);

  const projectTypeId = Form.useWatch('projectType', form1);

  useEffect(() => {
    fetchProjectTypes && fetchProjectTypes();
  }, []);

  useEffect(() => {
    projectTypeId && fetchAllSectionDetails && fetchAllSectionDetails({ projectTypeId });
  }, [projectTypeId]);

  useEffect(() => {
    setForm1ModalVisible(visible);

    if (!visible && !rfpFormModalVisible) {
      form1.resetFields();
      rfpForm.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  /**
   * Method to cancel create new reuquest
   */
  const cancelNewRqst = () => {
    setForm1ModalVisible(false);
    setRfpFormModalVisible(false);
    onCancel();
  };
  /**
   * Method to open rfp Docs Form if first form details are valid
   */
  const handleNext = () => {
    form1
      .validateFields()
      .then((_: FormInstance) => {
        setForm1ModalVisible(false);
        setRfpFormModalVisible(true);
        onCancel();
      })
      .catch((error: any) => {
        console.warn(error);
      });
  };

  /**
   * Method to close RFP modal on cancel
   */
  const handleRFPModalBack = () => {
    setRfpFormModalVisible(false);
    setForm1ModalVisible(true);
    onCancel();
  };

  /**
   * Method to handle create new request form submission
   */
  const handleSubmit = () => {
    rfpForm
      .validateFields()
      .then(async (_: FormInstance) => {
        setShowLoader(true);
        const payload1 = getNewRqstPayload(NEW_RQST_FIELDS_1, form1);
        const payload2 = getNewRqstPayload(
          [...NEW_RQST_FIELDS_2, ...getNDynamicFields(getRFPDocFields, rfpCount)],
          rfpForm,
        );
        const prefixes = [...payload1.prefixes, ...payload2.prefixes];

        createRqst &&
          createRqst({
            rqstName: payload1.rqstName,
            projectTypeId: payload1.projectTypeId,
            sections: payload1.sections,
            filesPayload: {
              files: {
                ...(payload1.files || {}),
                ...(payload2.files || {}),
              },
              prefixes: prefixes.join(','),
            },
            operation: 'create',
          });

        setShowLoader(false);
        setRfpFormModalVisible(false);
        form1.resetFields();
        rfpForm.resetFields();
        onCancel();
      })
      .catch((error: any) => {
        console.warn(error);
      });
  };

  return (
    <>
      <ReusableModal
        visible={form1ModalVisible}
        title="Create New Request"
        onCancel={cancelNewRqst}
        cancelButtonText={'Cancel'}
        submitButtonText={'Next'}
        onSubmit={handleNext}
        closable={true}
      >
        <Spin spinning={showLoader}>
          <DynamicForm
            fieldsConfig={NEW_RQST_FIELDS_1}
            form={form1}
            options={{
              'sectionDropdown': sectionDropdownData?.map((section: TSectionDetails) => ({
                label: section.section,
                value: section.sectionConstant,
              })),
              'projectType': projectTypes?.map((type: TProjectType) => ({
                label: type.projectType,
                value: type.id,
              })),
            }}
          />
        </Spin>
      </ReusableModal>
      <ReusableModal
        visible={rfpFormModalVisible}
        title="Create New Request"
        onCancel={handleRFPModalBack}
        cancelButtonText={'Back'}
        submitButtonText={'Submit'}
        onSubmit={handleSubmit}
        closable={true}
      >
        <Spin spinning={showLoader}>
          <DynamicForm
            fieldsConfig={NEW_RQST_FIELDS_2}
            dynamicFieldCount={rfpCount}
            updateDynamicCount={(count: number) => {
              setRfpCount(count);
            }}
            getDynamicField={getRFPDocFields}
            form={rfpForm}
          />
        </Spin>
      </ReusableModal>
    </>
  );
};

const mapStateToProps = (state: TRootState) => ({
  sectionDropdownData: state.allSections.data,
  projectTypes: state.createFormProjectTypes.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAllSectionDetails: (payload: TAllSectionDetailsPayload) =>
    dispatch(fetchAllSectionDetailsRequest(payload)),
  fetchProjectTypes: () => dispatch(fetchProjectTypeRequest()),
  createRqst: ({
    rqstName,
    projectTypeId,
    sections,
    filesPayload,
    operation,
  }: TCreateRqstPayload) =>
    dispatch(createNewRequest(rqstName, projectTypeId, sections, filesPayload, operation)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewRequest);
