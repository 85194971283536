import React, { useEffect, useState } from 'react';
import { Layout, Col, Row, Tag, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './Reviewscreen.less';
import '../../themes/damp-phase-constants/theme.less';
import Editor from '../../Components/editor/Editor';
import ReviewSideNav from './ReviewSideNav/Reviewsidenav';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  fetchRequestByIdRequest,
  fetchRequestByIdReset,
  putEditedContentToSaveRequest,
  updateSaveValue,
} from '../../libs/store/DmapPhase0Actions/LandingPageActions';
import { connect } from 'react-redux';
import contentConfig, { ComponentConfig } from './reviewScreen.config';
import SaveChangesModal from './SaveConfirmationModal';
import { fetchProcessSimplificationDetails } from '../../libs/store/DmapPhase0Actions/ProcessSimplification.actions';
import { getStatusColor } from '../../utils/ReviewScreen.util';
import { PutEditedContentToSaveRequestAction, ResetRequestById, TFetchRequestById, TRootState, TSaveFlagAction, TSubSectionResponse } from '../../libs/store/DmapPhase0State/LandingPageState';
import SelectSubsection from '../../Components/SelectSubsection/SelectSubsection';
import { Dispatch } from 'redux';
import { TProcessSimpDetailsPayload } from '../../libs/models/ProcessSimplification.model';
import { SaveApiPayload } from '../../libs/models/ReviewScreenModels';
import { TProcessSimpDetailsRequest } from '../../libs/store/DmapPhase0State/ProcessSimplification.state';
import { fetchSectionsById, resetSectionsById } from '../../libs/store/DmapPhase0Actions/ReviewScreen.actions';
import { TResetSectionsById, TSectionsByIdRequest } from '../../libs/store/DmapPhase0State/ReviewScreen.state';

const { Header, Content } = Layout;

interface Props{
  processSimplicationData?: TSubSectionResponse<string>[] | null;
  processSimplEditedData?: Record<string, string> | null;
  saveBtnData?: Record<string, boolean>;
  requestDetails?: any;

  fetchRequestDetails?: (reqId: string) => TFetchRequestById;
  resetRequestDetails?: () =>  ResetRequestById;
  updateSaveBtnFlag?: (section: string, flag: boolean) => TSaveFlagAction;
  saveEditedContent?:  (reqId: number, selectedItem: string, payload: SaveApiPayload[]) =>  PutEditedContentToSaveRequestAction;
  getProcessSimpDetails?: (payload: TProcessSimpDetailsPayload) => TProcessSimpDetailsRequest;
  fetchSidenavData?: (reqId: string) => TSectionsByIdRequest;
  resetSidenavData?: () =>  TResetSectionsById;

}

const ReviewScreen: React.FC<Props> = ({processSimplicationData, processSimplEditedData, saveBtnData, requestDetails, fetchRequestDetails, resetRequestDetails, updateSaveBtnFlag, saveEditedContent, getProcessSimpDetails, fetchSidenavData, resetSidenavData}) => {
  const history = useHistory();
  const excludedComponents = ['Project Plan', 'Architecture'];
  const { id }: { id: string } = useParams();
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [selectedMenuItem, setSelectedMenuItem] = useState<string | null>(null);
  const [selectedHeader, setSelectedHeader] = useState<string | null>(null);
  const [showSaveAllModal, setShowSaveAllModal] = useState(false);
  const [tempsection, settempsection] = useState<string>('');
  const [editorKey, setEditorKey] = useState(0);
  const [sideNavKey, setSideNavKey] = useState(0);
  const [isPolling, setIsPolling] = useState(false);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>('');
  const [tableSelectedRows, setTableSelectedRows] = useState<Record<number, Record<string, string>[]>>({});
  let intervalId: NodeJS.Timeout;
  

  useEffect(() => {
    fetchRequestDetails && fetchRequestDetails(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    intervalId=setInterval(() => {
      fetchRequestDetails && fetchRequestDetails(id);
    }, 5000);

   return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    resetSidenavData && resetSidenavData();
      fetchSidenavData && fetchSidenavData(id);
      fetchRequestDetails && fetchRequestDetails(id);
  }, [id, sideNavKey]);

  const stopPolling = () => {
    setIsPolling(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          isPolling &&
          (requestDetails.status !== null ||
          requestDetails.status !== 'In Progress') 
        ) {
          stopPolling();
        }
        if (!isPolling && requestDetails?.status === 'Completed' && selectedItem !== '') {
          fetchSidenavData && fetchSidenavData(id);
          getProcessSimpDetails && getProcessSimpDetails({
            reqId: id,
            selectedItem, pageSize: 0
          })
        } else if (!isPolling && requestDetails?.status) {
          fetchSidenavData && fetchSidenavData(id);
        }
          await new Promise((resolve) => setTimeout(resolve, 5000));
      } catch (error) {
        message.warn('Something went wrong');
        console.error('Error rendering data:', error);
      }
    };
    fetchData();
  }, [isPolling, requestDetails?.status]);

  const handleIconClick = () => {
    resetRequestDetails && resetRequestDetails();
    setIsPolling(false);
    history.push('/');
  };
  const handleItemSelect = (item: string) => {
    if(!item){
      setSelectedItem('');
      setSelectedMenuItem('');
    }
    settempsection(item);
    if (selectedItem !== null && saveBtnData?.[selectedItem]) {
      setShowSaveAllModal(true);
    } else {
      setSelectedItem(item);
      setSelectedMenuItem('');
    }
  };
  const handleMenuItemClick = (key: string) => {
    setSelectedMenuItem(key);
  };
  const handleHeaderName = (key: string) => {
    setSelectedHeader(key);
  };
  const updateSideNav = () => {
    setSideNavKey((prevKey) => prevKey + 1);
    fetchSidenavData && fetchSidenavData(id);
  };
  const startPolling = () => {  
    setIsPolling(true);
  };

  const onTableSelectionChange = (pageNumber: number, selectedRows: Record<string, string>[]) => {
    setTableSelectedRows((prevTableRows: Record<number, Record<string, string>[]>): Record<number, Record<string, string>[]> => ({
      ...prevTableRows,
      [pageNumber]: selectedRows
    }))
  }

  const handleSave = async () => {
    if (selectedItem !== null) {
      let sectionName = '';
      if (processSimplicationData && processSimplicationData?.length > 0) {
        sectionName = selectedItem;
        if (processSimplicationData && Array.isArray(processSimplicationData)) {
          const payload = processSimplicationData
          .filter((detail: TSubSectionResponse<string>) => detail.subsection && processSimplEditedData?.[detail.subsection])
            .map((detail: any) => ({
              subsection: detail.subsection,
              editedContent:
              detail.subsection === processSimplEditedData?.[detail.subsection] ? processSimplEditedData?.[detail.subsection] : detail.data.response
            }));
            saveEditedContent && saveEditedContent(parseInt(id), sectionName, payload)
          await new Promise((resolve) => setTimeout(resolve, 1000));
          getProcessSimpDetails && getProcessSimpDetails({
            reqId: id,
            selectedItem,
            pageSize: 0
          })
        }
      }
      setShowSaveAllModal(false);
    }
  };

  const onCancel = () => {
    setShowSaveAllModal(false);
    if (selectedItem !== tempsection && selectedItem !== null) {
      updateSaveBtnFlag && updateSaveBtnFlag(selectedItem, false);
      setSelectedMenuItem('');
    }
    setSelectedItem(tempsection);
  };

  return (
    <Layout className="layout-background">
      <Header className="header-container">
        <div className="header-title">
          <div className="request-details">
            <CloseOutlined className="close-icon" onClick={handleIconClick} />
            <span>Request Details </span>
            {requestDetails?.status && (
              <span className="request-status">
                <Tag
                  className="review-screen-tag"
                  color={getStatusColor(requestDetails?.status)}
                >
                  {requestDetails?.status}
                </Tag>
              </span>
            )}
          </div>
          {requestDetails?.projectType?.projectType && (
            <div className="project-type-container">
              Project Type : {requestDetails.projectType.projectType}
            </div>
          )}
        </div>
      </Header>
      <Layout className="review-container">
        <Row>
          <Col
            span={4}
            className="left-column"
            style={{ minWidth: !collapsed ? '31.25rem' : '20rem' }}
          >
            {/* <Sider className="left-column"> */}
            <ReviewSideNav
              onRequestSelect={handleItemSelect}
              requestId={id}
              onSubsectionSelect={handleMenuItemClick}
              requestheader={handleHeaderName}
              selectedItem={selectedItem}
              selectedSubsection={selectedMenuItem}
              setCollapsed={setCollapsed}
              collapsed={collapsed}
            />
            {/* </Sider> */}
          </Col>
          <Col span={16}>
            <Content className="right-column" style={{ width: collapsed ? '75vw' : '63vw' }}>
              <Editor
                key={excludedComponents.includes(selectedItem || '') ? null : editorKey}
                selectedItem={selectedItem}
                onSelectedMenuItem={selectedMenuItem}
                tableSelectedRows={tableSelectedRows}
                setTableSelectedRows={setTableSelectedRows}
                searchText={searchText}
                setSearchText={setSearchText}
              >
                {selectedItem && contentConfig[selectedItem] ? (
                  <>
                    <RenderComponentAndSubcomponents
                      config={contentConfig[selectedItem]}
                      parentProps={{
                        ...contentConfig[selectedItem].parentProps,
                        selectedItem: selectedItem,
                        onSelectedMenuItem: selectedMenuItem,
                        setSelectedSubsection: setSelectedMenuItem,
                        startPolling: startPolling,
                        stopPolling: stopPolling,
                        updateSideNav : updateSideNav,
                        tableSelectedRows: tableSelectedRows,
                        onTableSelectionChange: onTableSelectionChange,
                        searchText: searchText,
                        setSearchText: setSearchText
                      }}
                      
                    />
                    <SaveChangesModal
                      visible={showSaveAllModal}
                      onCancel={onCancel}
                      onSave={handleSave}
                      selectedItem={selectedItem}
                    />
                  </>
                ) : 
                (
                  <SelectSubsection />
                )
              }
              </Editor>
            </Content>
          </Col>
        </Row>
      </Layout>
    </Layout>
  );
};

const RenderComponentAndSubcomponents: React.FC<{ config: ComponentConfig; parentProps: any }> = ({
  config,
  parentProps,
}) => {
  const Component = config.component;
  const subcomponents = config.subcomponents;

  return (
    <>
      <Component {...parentProps} />
      {subcomponents &&
        Object.entries(subcomponents).map(([subcomponentName, subcomponentConfig]) => (
          <RenderComponentAndSubcomponents
            key={subcomponentName}
            config={subcomponentConfig}
            parentProps={parentProps}
          />
        ))}
    </>
  );
};

const mapStateToProps = (state: TRootState) => ({
  processSimplicationData: state.processSimplification.existingData?.data,
  processSimplEditedData: state.processSimplification.editedData,
  saveBtnData: state.saveBtnFlagReducer.saveBtnFlag,
  requestDetails: state.fetchRequestId?.data
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRequestDetails: (reqId: string) => dispatch(fetchRequestByIdRequest(parseInt(reqId))),
  resetRequestDetails: () => dispatch(fetchRequestByIdReset()),
  updateSaveBtnFlag: (section: string, flag: boolean) => dispatch(updateSaveValue(section, flag)),
  saveEditedContent:  (reqId: number, selectedItem: string, payload: SaveApiPayload[]) => dispatch(putEditedContentToSaveRequest(reqId, selectedItem, payload)),
  getProcessSimpDetails: (payload: TProcessSimpDetailsPayload) =>
    dispatch(fetchProcessSimplificationDetails(payload)),
  fetchSidenavData: (reqId: string) => dispatch(fetchSectionsById(parseInt(reqId))),
  resetSidenavData: () => dispatch(resetSectionsById())
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewScreen);
