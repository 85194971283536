import { TDataState, TSubSectionResponse } from '../DmapPhase0State/LandingPageState';
import {
  EXECUTIVE_SUMMARY_FAILURE,
  EXECUTIVE_SUMMARY_SUCCESS,
  REQUEST_EXECUTIVE_SUMMARY,
  TExecutiveSummaryActionTypes,
} from '../DmapPhase0State/ExecutiveSummary.state';

const initialState: TDataState<TSubSectionResponse<null>[]> = {
  data: null,
  loading: false,
  error: null,
};

export const executiveSummaryReducer = (
  state = initialState,
  action: TExecutiveSummaryActionTypes,
): TDataState<TSubSectionResponse<null>[]> => {
  switch (action.type) {
    case REQUEST_EXECUTIVE_SUMMARY:
      return {
        ...state,
        loading: true,
      };
    case EXECUTIVE_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case EXECUTIVE_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };    
    default:
      return { ...state };
  }
};
