import React, { ReactNode } from 'react';
import { Modal, Button, Form, Tooltip } from 'antd';
import './ReusableModal.less'; 
import { CloseOutlined } from '@ant-design/icons';

interface ReusableModalProps {
  visible: boolean;
  closable?: boolean;
  onCloseClick?: () => void;
  onCancel: () => void;
  title: ReactNode;
  showCancelBtn?: boolean;
  cancelButtonText?: ReactNode;
  submitButtonText?: ReactNode;
  onSubmit?: (values: any) => void;
  submitDisabled?: boolean;
  errMsg?: string

  extraBtnText?: ReactNode;
  extraBtnClick?: () => void;
  extraBtnDisabled?: boolean;
}

const ReusableModal: React.FC<ReusableModalProps> = ({
  visible,
  onCancel,
  title,
  children,
  cancelButtonText = 'Cancel',
  submitButtonText = 'Submit',
  onSubmit,
  closable,
  onCloseClick,
  showCancelBtn,
  submitDisabled,
  extraBtnText,
  extraBtnClick,
  extraBtnDisabled,
  errMsg
}) => {
    const [form] = Form.useForm();

    const handleFormSubmit = async () => {
      try {
        const values = await form.validateFields();
        if (onSubmit) {
          onSubmit(values);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

  return (
    <Modal
      className="reusable-modal"
      open={visible}
      centered={true}
      closable={closable || false}
      closeIcon={closable ? <CloseOutlined onClick={onCloseClick} /> : null}
      onCancel={onCancel}
      footer={null}
      width={600}
    >
      <div className="modal-header">{title}</div>
      <div className="modal-content">{children}</div>
      {/* <Divider className="modal-divider" /> */}
      <div className="modal-footer">
          <div className="modal-err-msg ant-form-item-explain-error">
            {errMsg}
          </div>
        <div className='modal-footer-btns'>
        {
          extraBtnClick &&  <Button disabled={extraBtnDisabled} className="cancel-btn" onClick={extraBtnClick}>
          {extraBtnText}
        </Button>
        }
        {!(showCancelBtn === false) && <Button className="cancel-btn" onClick={onCancel}>
          {cancelButtonText}
        </Button>}
        {onSubmit && (
          <Button className="submit-btn" disabled={submitDisabled} onClick={handleFormSubmit}>
            {submitButtonText}
          </Button>
        )}
        </div>
      </div>
    </Modal>
  );
};

export default ReusableModal;
