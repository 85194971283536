import { call, put } from "redux-saga/effects";
import { downloadFileFailure, downloadFileSuccess, fetchWorkshopEnablersError, fetchWorkshopEnablersSubSectionError, fetchWorkshopEnablersSubSectionSuccess, fetchWorkshopEnablersSuccess } from "../DmapPhase0Actions/WorkshopEnablers.actions";
import { getDownloadLinkForFile } from "../../services/DmapPhase0Services/WorkshopEnablers.service";
import { TDownloadFileResponse, TRequestDownloadFile, TWorkshopEnablersRequest, TWorkshopEnablersResponse, TWorkshopEnablersSubSectionRequest, TWorkshopEnablersTableCols } from "../DmapPhase0State/WorkshopEnablers.state";
import { fetchSectionDetails, fetchSubSectionDetails } from "../../services/DmapPhase0Services/ReviewPageService";
import { TSectionResponse, TSubSectionResponse } from "../DmapPhase0State/LandingPageState";

/**
 * Saga to fetch workshop enablers data
 * @param action 
 */
export function* fetchWorkshopEnablersSaga(action: TWorkshopEnablersRequest): Generator<any, void, any> {
    try {
      const { reqId, selectedSection, pageSize } = action.payload
      const data: TWorkshopEnablersResponse = yield call(fetchSectionDetails<TWorkshopEnablersResponse>, reqId, selectedSection, pageSize);
      yield put(fetchWorkshopEnablersSuccess(data.response));
    } catch (error) {
      yield put(fetchWorkshopEnablersError(error));
    }
  }

/**
 * Saga to fetch workshop enablers data subsection
 * @param action 
 */
export function* fetchWorkshopEnablersSubSectionSaga(action: TWorkshopEnablersSubSectionRequest): Generator<any, void, any> {
    try {
      const data: TSectionResponse<TWorkshopEnablersTableCols> = yield call(fetchSubSectionDetails<TSubSectionResponse<TWorkshopEnablersTableCols>>, action.payload);
      yield put(fetchWorkshopEnablersSubSectionSuccess(data.response?.[0]));
    } catch (error) {
      yield put(fetchWorkshopEnablersSubSectionError(error));
    }
  }

/**
 * Saga to get the URL for downloading the specific file
 * @param action 
 */
export function* downloadFileSaga(action: TRequestDownloadFile) {
  try {
    const { requestId, fileName } = action.payload;
    const response: TDownloadFileResponse  = yield call(getDownloadLinkForFile, requestId, fileName);
    yield put(downloadFileSuccess(response));
  } catch (error) {
    yield put(downloadFileFailure(error));
  }
}