import { TPlanEstimates, TProjectAveragePayload, TProjectAvgResponse, TProjectTimeline, TProjectVisualPayload, TSaveProjectTimelinePayload, TSaveProjectTimelineResponse } from "../../models/ProjectPlan.model";
import { TDataState } from "./LandingPageState";

export const REQUEST_PROJECT_TIMELINE = 'REQUEST_PROJECT_TIMELINE';
export const PROJECT_TIMELINE_SUCCESS = 'PROJECT_TIMELINE_SUCCESS';
export const PROJECT_TIMELINE_FAILURE = 'PROJECT_TIMELINE_FAILURE';
export const SET_PROJECT_TIMELINE_SELECTED_VALUES = 'SET_PROJECT_TIMELINE_SELECTED_VALUES';
export const RESET_PROJECT_TIMELINE = 'RESET_PROJECT_TIMELINE';
export const REQUEST_PROJECT_TIMELINE_AVERAGE = 'REQUEST_PROJECT_TIMELINE_AVERAGE';
export const PROJECT_TIMELINE_AVERAGE_SUCCESS = 'PROJECT_TIMELINE_AVERAGE_SUCCESS';
export const PROJECT_TIMELINE_AVERAGE_FAILURE = 'PROJECT_TIMELINE_AVERAGE_FAILURE';
export const REQUEST_SAVE_PROJECT_TIMELINE = 'REQUEST_SAVE_PROJECT_TIMELINE';
export const SAVE_PROJECT_TIMELINE_SUCCESS = 'SAVE_PROJECT_TIMELINE_SUCCESS';
export const SAVE_PROJECT_TIMELINE_FAILURE = 'SAVE_PROJECT_TIMELINE_FAILURE';
export const REQUEST_PROJECT_VISUALS = 'REQUEST_PROJECT_VISUALS';
export const PROJECT_VISUALS_SUCCESS = 'PROJECT_VISUALS_SUCCESS';
export const PROJECT_VISUALS_FAILURE = 'PROJECT_VISUALS_FAILURE';

export type TProjectPlan = {
    timeline: TDataState<TProjectTimeline[]> | null;
    saveTimeline: TDataState<TSaveProjectTimelineResponse> | null;
    visuals: TDataState<TPlanEstimates[]> | null;
    selectedTimelineValues: Record<string, Record<string, string>>;
}

export type TProjectTimelineRequest = {
    type: 'REQUEST_PROJECT_TIMELINE';
    payload: string;
  }

  export type TProjectTimelineSuccess = {
    type: 'PROJECT_TIMELINE_SUCCESS';
    payload: TProjectTimeline[];
  }

  export type TProjectTimelineFailure = {
    type: 'PROJECT_TIMELINE_FAILURE';
    payload: any;
  }

  export type TSetProjectTimelineSelectedValues= {
    type: 'SET_PROJECT_TIMELINE_SELECTED_VALUES';
    payload: Record<string, Record<string, string>>;
  }

export type TProjectTimelineAvgRequest = {
  type: 'REQUEST_PROJECT_TIMELINE_AVERAGE';
  payload: TProjectAveragePayload;
}

export type TProjectTimelineAvgSuccess = {
  type: 'PROJECT_TIMELINE_AVERAGE_SUCCESS';
  payload: TProjectAvgResponse;
}

export type TProjectTimelineAvgFailure = {
  type: 'PROJECT_TIMELINE_AVERAGE_FAILURE';
  payload: any;
}

export type TSaveProjectTimelinesRequest = {
  type: 'REQUEST_SAVE_PROJECT_TIMELINE';
  payload: TSaveProjectTimelinePayload;
}
export type TSaveProjectTimelineSuccess = {
  type: 'SAVE_PROJECT_TIMELINE_SUCCESS';
  payload: TSaveProjectTimelineResponse;
}
export type TSaveProjectTimelineFailure = {
  type: 'SAVE_PROJECT_TIMELINE_FAILURE';
  payload: any;
}
export type TResetProjectTimeline = {
  type: 'RESET_PROJECT_TIMELINE';
}


export type TProjectVisualsRequest = {
  type: 'REQUEST_PROJECT_VISUALS';
  payload: TProjectVisualPayload;
}
export type TProjectVisualsSuccess = {
  type: 'PROJECT_VISUALS_SUCCESS';
  payload: TPlanEstimates[];
}
export type TProjectVisualsFailure = {
  type: 'PROJECT_VISUALS_FAILURE';
  payload: TProjectVisualPayload;
}

  export type ProjectTimelineActionTypes = TProjectTimelineRequest | TProjectTimelineSuccess | TProjectTimelineFailure | TSetProjectTimelineSelectedValues | TResetProjectTimeline | TProjectTimelineAvgRequest | TProjectTimelineAvgSuccess | TProjectTimelineAvgFailure | TSaveProjectTimelinesRequest | TSaveProjectTimelineSuccess | TSaveProjectTimelineFailure | TProjectVisualsRequest | TProjectVisualsSuccess | TProjectVisualsFailure;