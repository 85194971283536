import React from 'react';
import AsIsArchitecture from './AsIsArchitecture/AsIsArchitecture';
import GenerateArchDiagram from './GenerateArchitectureDiagram/GenerateArchDiagram';
import { FileTextFilled } from '@ant-design/icons';
import { TRootState } from '../../../../libs/store/DmapPhase0State/LandingPageState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import SelectSubsection from '../../../../Components/SelectSubsection/SelectSubsection';
import { TSectionDetails } from '../../../../libs/models/landingPageModels';
import { SECTION_CONSTANT_MAPPING, SUB_SECTION_MAPPING } from '../../../../constants';

interface ArchitectureProps {
  selectedItem: string;
  onSelectedMenuItem: string;

  sectionDetails?: TSectionDetails | null;
}

const Architecture: React.FC<ArchitectureProps> = ({ selectedItem, onSelectedMenuItem, sectionDetails }) => {
  return (
    sectionDetails?.subsections?.length && onSelectedMenuItem ?
    <div>
      {selectedItem === SECTION_CONSTANT_MAPPING.ARCHITECTURE &&
        (onSelectedMenuItem === SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.ARCHITECTURE].AS_IS_ARCHITECTURE ? (
          <AsIsArchitecture
            selectedSection={selectedItem}
            selectedSubsection={onSelectedMenuItem}
          />
        ) : onSelectedMenuItem === SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.ARCHITECTURE].GENERATE_ARCHITECTURE_DIAGRAM ? (
          <GenerateArchDiagram section={selectedItem} subSection={onSelectedMenuItem} />
        ) : (
          <SelectSubsection />
        ))}
    </div>
    :
    <SelectSubsection />
  );
};

const mapStateToProps = (state: TRootState, {selectedItem}: ArchitectureProps) => ({
  sectionDetails: state.sectionsById?.data?.find((section: TSectionDetails) => section.sectionConstant === selectedItem)
})

const mapDispatchToProps = (dispatch: Dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Architecture);
