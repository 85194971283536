import { TFormField } from "../models/NewRqst.model";

export const GENERATE_KDD_USING_PROMPT_FIELDS_CONFIG: TFormField[] = [
    {
        id: 'prompt',
        key: 'prompt-area',
        type: 'textarea',
        name: 'prompt',
        placeholderText: 'Provide a user input to generate KDD using GenAI',
        canRemove: false,
        rules: [
            {
                required: true,
                message: 'Please provide a prompt to generate the data.'
            }
        ]
    },
]