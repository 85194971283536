import React, { useEffect, useState } from 'react';
import  skeletonimg  from '../../../../../assets/Images/archSkeleton.png';
import FileUploadBtn from '../../../../FileUploadBtn/FileUploadBtn';
import './GenerateArchDiagram.less';
import { Button, Image, TablePaginationConfig } from 'antd';
import CustomTable from '../../../../../Components/DynamicForm/components/CustomTable/CustomTable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE, SECTION_CONSTANT_MAPPING, SUB_SECTION_MAPPING } from '../../../../../constants';
import { TDataState, TFetchSubSectionPayload, TRootState, TSubSectionDataResponse, TSubSectionResponse } from '../../../../../libs/store/DmapPhase0State/LandingPageState';
import { TFetchFileData } from '../../../../../libs/models/ReviewScreenModels';
import { Dispatch } from 'redux';
import { useParams } from 'react-router';
import { fetchArchitectureImage, fetchArchitectureSubSection, generateDiagramRequest, resetArchitectureSubSection } from '../../../../../libs/store/DmapPhase0Actions/Architecture.actions';
import { TArchitectureSubSectionRequest, TGenerateDiagramRequest, TgetArchImage, TResetArchSubsection } from '../../../../../libs/store/DmapPhase0State/Architecture.state';
import { TArchGenericResponse, TArchImgPayload, TArchImgResponse, TArchTableData, TGenerateDiagramPayload, TResetArchSubsectionPayload } from '../../../../../libs/models/Architecture.model';
import { FilterValue } from 'antd/lib/table/interface';

interface Props {
  section: string;
  subSection: string;

  detailsLoading?: boolean;
  details?: TSubSectionDataResponse<TArchTableData> | null;
  existingFiles?: TDataState<TFetchFileData[]> | null;
  image?: TDataState<TArchImgResponse> | null;
  generateDiagramDetails?: TDataState<TArchGenericResponse> | null


  fetchSubSection?: (payload: TFetchSubSectionPayload) => TArchitectureSubSectionRequest;
  fetchImage?: (payload: TArchImgPayload) => TgetArchImage;
  generateDiagram: (payload: TGenerateDiagramPayload) => TGenerateDiagramRequest;
  resetArchitecture?:  (payload: TResetArchSubsectionPayload) => TResetArchSubsection;
}
const GenerateArchDiagram: React.FC<Props> = ({section, subSection, detailsLoading, details, existingFiles, image, generateDiagramDetails, fetchSubSection, fetchImage, generateDiagram, resetArchitecture}) => {

  const { id }: { id: string } = useParams();

  const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]>>({});

  useEffect(() => {
    // reset data on unmount
    return () => {
      resetArchitecture && resetArchitecture({subsection: subSection})
    }
  }, []);

  /**
   * Fetch sub section details if file is present
   */
  useEffect(() => {
    if(existingFiles?.data?.length){
      fetchSubSection && fetchSubSection({requestId: id, body: {
        selectedFilters: {},
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        section,
        subsection: subSection,
      }})

      fetchImage && fetchImage({
        requestId: id,
        sectionType: SECTION_CONSTANT_MAPPING.ARCHITECTURE,
        subsection: SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.ARCHITECTURE].GENERATE_ARCHITECTURE_DIAGRAM,
      })
  
    }
  }, [existingFiles?.data])

  useEffect(() => {
    if(generateDiagramDetails?.data && !generateDiagramDetails?.loading){
      fetchImage && fetchImage({
        requestId: id,
        sectionType: SECTION_CONSTANT_MAPPING.ARCHITECTURE,
        subsection: SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.ARCHITECTURE].GENERATE_ARCHITECTURE_DIAGRAM
      })
    }
  }, [generateDiagramDetails])


  const onChange = (
    paginationConfig: TablePaginationConfig,
    selectedFilters: Record<string, FilterValue | null>,
    action: 'paginate' | 'sort' | 'filter',
  ) => {
    const filters = Object.keys(selectedFilters)
    .filter((k) => selectedFilters[k] != null)
    .reduce((a, k) => ({ ...a, [k]: selectedFilters[k] }), {});

    if(action === 'filter'){
      setSelectedFilters(filters)
    }

    fetchSubSection &&
      fetchSubSection({
        requestId: id,
        body: {
          selectedFilters: filters,
          page: paginationConfig.current || -1,
          pageSize: paginationConfig.pageSize || DEFAULT_PAGE_SIZE,
          section,
          subsection: subSection,
        },
      });
  };

  const onClickGenerate = () => {
    generateDiagram && generateDiagram({
      requestId: id,
      selectedFilters,
      subsection: SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.ARCHITECTURE].GENERATE_ARCHITECTURE_DIAGRAM
    })
  }

  const generatingSkeleton = (generateMsg: string) => (
    <div className="skeleton-img-container">
      <Image src={skeletonimg} alt="" preview={false} className='skeleton-img'/>
      <p>{generateMsg}</p>
    </div>
  );

  const generatedimg = (
    image?.data?.url ?
    <div className="skeleton-img-container">
      <Image src={image.data.url} alt={''} className='arch-img' preview={false} placeholder={generatingSkeleton('Loading...')} />
    </div> : <></>
  );

  return (
    <div className="generate-arch-container">
      <FileUploadBtn subSection={subSection} />
      {details?.table?.content ? (
        <CustomTable
          loading={detailsLoading || false}
          data={details?.table?.content || []}
          tableName="gen-arch-table"
          filterCols={details?.table?.filters}
          pageSize={details?.table?.pageSize || DEFAULT_PAGE_SIZE}
          totalCount={details?.table?.totalCount}
          onChange={onChange}
          colNames={details?.table?.colNames || []}
          pagination={true}
          pageNumber={details?.table?.pageNumber}
        />
      ) : (
        <></>
      )}
      {details?.table?.content?.length ? (
        <div className="generate-btn">
          <Button className="generate-arch-btn" onClick={onClickGenerate}>
            Generate Architecture
          </Button>
        </div>
      ) : <></>}
      {generateDiagramDetails?.loading ? generatingSkeleton('Generating Architecture...') : generatedimg}
    </div>
  );
};

const mapStateToProps = (state: TRootState) => ({
  detailsLoading: state.architecture?.toBeArchitecture?.response?.loading,
  details: state.architecture.toBeArchitecture?.response?.data?.find((section: TSubSectionResponse<TArchTableData>) => section.subsection === 'Generate Architecture Diagram')?.data,
  existingFiles: state.architecture?.toBeArchitecture?.existingFiles,
  image: state.architecture.toBeArchitecture?.image,
  generateDiagramDetails: state.architecture.toBeArchitecture?.generateDiagram
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSubSection: (payload: TFetchSubSectionPayload) =>
    dispatch(fetchArchitectureSubSection(payload)),
  fetchImage: (payload: TArchImgPayload) => dispatch(fetchArchitectureImage(payload)),
  generateDiagram: (payload: TGenerateDiagramPayload) => dispatch(generateDiagramRequest(payload)),
  resetArchitecture:  (payload: TResetArchSubsectionPayload) => dispatch(resetArchitectureSubSection(payload))
});
export default connect(mapStateToProps, mapDispatchToProps)(GenerateArchDiagram);
