import { TFetchSubSectionPayload, TSectionPayload, TSubSectionResponse } from "./LandingPageState";

export const RESET_DOWNLOAD_FILE_DATA = 'RESET_DOWNLOAD_FILE_DATA';
export const REQUEST_DOWNLOAD_FILE = 'REQUEST_DOWNLOAD_FILE';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAILURE = 'DOWNLOAD_FILE_FAILURE';
export const REQUEST_WORKSHOP_ENABLERS = 'REQUEST_WORKSHOP_ENABLERS';
export const WORKSHOP_ENABLERS_SUCCESS = 'WORKSHOP_ENABLERS_SUCCESS';
export const WORKSHOP_ENABLERS_FAILURE = 'WORKSHOP_ENABLERS_FAILURE';
export const REQUEST_WORKSHOP_ENABLERS_SUBSECTION = 'REQUEST_WORKSHOP_ENABLERS_SUBSECTION';
export const WORKSHOP_ENABLERS_SUBSECTION_SUCCESS = 'WORKSHOP_ENABLERS_SUBSECTION_SUCCESS';
export const WORKSHOP_ENABLERS_SUBSECTION_FAILURE = 'WORKSHOP_ENABLERS_SUBSECTION_FAILURE';

export type TWorkshopEnablersTableCols = {
  'Change Description - TOBE(S/4 HANA)': string;
  'Process Area': string;
  'Mandatory/Optional': string;
  'Change Description - ASIS(ECC)': string;
  "What's transforming": string;
}

export type TDownloadFilePayload = {
    requestId: string,
    fileName: string
}

export type TDownloadFileResponse = {
  request_id: string;
  url: string;
  size: string;
}

export type TWorkshopEnablersResponse = {
  response: TSubSectionResponse<TWorkshopEnablersTableCols>[];
}

// Action Types

export type TResetDownloadFileData = {
  type: 'RESET_DOWNLOAD_FILE_DATA';
}

export type TRequestDownloadFile = {
  type: 'REQUEST_DOWNLOAD_FILE';
  payload: TDownloadFilePayload;
}

export type TDownloadFileSuccess = {
  type: 'DOWNLOAD_FILE_SUCCESS';
  payload: TDownloadFileResponse;
}

export type TDownloadFileFailure = {
  type: 'DOWNLOAD_FILE_FAILURE';
  payload: any;
}

export type TWorkshopEnablersRequest = {
    type: 'REQUEST_WORKSHOP_ENABLERS';
    payload: TSectionPayload;
  }

  export type TWorkshopEnablersSuccess = {
    type: 'WORKSHOP_ENABLERS_SUCCESS';
    payload: TSubSectionResponse<TWorkshopEnablersTableCols>[];
  }

  export type TWorkshopEnablersFailure = {
    type: 'WORKSHOP_ENABLERS_FAILURE';
    payload: any;
  }

export type TWorkshopEnablersSubSectionRequest = {
    type: 'REQUEST_WORKSHOP_ENABLERS_SUBSECTION';
    payload: TFetchSubSectionPayload;
  }

  export type TWorkshopEnablersSubSectionSuccess = {
    type: 'WORKSHOP_ENABLERS_SUBSECTION_SUCCESS';
    payload: TSubSectionResponse<TWorkshopEnablersTableCols>;
  }

  export type TWorkshopEnablersSubSectionFailure = {
    type: 'WORKSHOP_ENABLERS_SUBSECTION_FAILURE';
    payload: any;
  }

  
  export type WorkshopEnablersActionTypes = TWorkshopEnablersRequest | TWorkshopEnablersSuccess | TWorkshopEnablersFailure | TWorkshopEnablersSubSectionRequest | TWorkshopEnablersSubSectionSuccess | TWorkshopEnablersSubSectionFailure | TRequestDownloadFile | TDownloadFileSuccess | TDownloadFileFailure | TResetDownloadFileData;
