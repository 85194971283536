// store.ts
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../store/DmapPhase0Reducers/LandingPageReducer';
import rootSaga from '../store/DmapPhase0Sagas/LandingPageSaga';
import {  composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';


const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeWithDevToolsDevelopmentOnly(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);

export default store;

