import { AxiosError } from "axios";
import { TPromptResultPayload, TSubsectionPromptResult } from "../../models/ChatPrompt.model";

export const PROMPT_RESULT_REQUEST = 'PROMPT_RESULT_REQUEST';
export const PROMPT_RESULT_SUCCESS = 'PROMPT_RESULT_SUCCESS';
export const PROMPT_RESULT_FAILURE = 'PROMPT_RESULT_FAILURE';
export const RESET_PROMPT_RESULT = 'RESET_PROMPT_RESULT';
export const APPLY_CHAT_TO_ALL_REQUEST = 'APPLY_CHAT_TO_ALL_REQUEST';
export const APPLY_CHAT_TO_ALL_SUCCESS = 'APPLY_CHAT_TO_ALL_SUCCESS';
export const APPLY_CHAT_TO_ALL_FAILURE = 'APPLY_CHAT_TO_ALL_FAILURE';
export const RESET_APPLY_CHAT_TO_ALL = 'RESET_APPLY_CHAT_TO_ALL';

export type TPromptResultRequest = {
    type: 'PROMPT_RESULT_REQUEST';
    payload: TPromptResultPayload;
};

export type TPromptResultSuccess = {
    type: 'PROMPT_RESULT_SUCCESS',
    payload: TSubsectionPromptResult;
}

export type TPromptResultFailure = {
    type: 'PROMPT_RESULT_FAILURE',
    payload: AxiosError
}

export type TResetPromptResult = {
    type: 'RESET_PROMPT_RESULT',
}

export type TApplyChatToAllRequest = {
    type: 'APPLY_CHAT_TO_ALL_REQUEST';
    payload: TPromptResultPayload;
};

export type TApplyChatToAllSuccess = {
    type: 'APPLY_CHAT_TO_ALL_SUCCESS',
    payload: TSubsectionPromptResult;
}

export type TApplyChatToAllFailure = {
    type: 'APPLY_CHAT_TO_ALL_FAILURE',
    payload: AxiosError
}

export type TResetApplyChatToAll = {
    type: 'RESET_APPLY_CHAT_TO_ALL',
}

export type TPromptResultActionTypes = TPromptResultRequest | TPromptResultSuccess | TPromptResultFailure | TResetPromptResult;
export type TApplyChatToAllActionTypes = TApplyChatToAllRequest | TApplyChatToAllSuccess | TApplyChatToAllFailure | TResetApplyChatToAll;

export type TChatPromptActionTypes = 
TPromptResultActionTypes
| TApplyChatToAllActionTypes
