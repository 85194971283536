import { TSectionDetails } from "../../models/landingPageModels";
import { TExistingFilesPayload, TFetchFileData, TFetchFilesResponse } from "../../models/ReviewScreenModels";
import { TDataState } from "./LandingPageState";

// Get Sections by ID
export const SECTIONS_BY_ID_REQUEST = 'SECTIONS_BY_ID_REQUEST';
export const SECTIONS_BY_ID_SUCCESS = 'SECTIONS_BY_ID_SUCCESS';
export const SECTIONS_BY_ID_FAILURE = 'SECTIONS_BY_ID_FAILURE';

export const RESET_SECTIONS_BY_ID = 'RESET_SECTIONS_BY_ID';

export interface TSectionsByIdRequest {
    type: 'SECTIONS_BY_ID_REQUEST';
    payload: { reqId: number }
}

export interface TSectionsByIdSuccess {
    type: 'SECTIONS_BY_ID_SUCCESS';
    payload: TSectionDetails[];
}

export interface TSectionsByIdFailure {
    type: 'SECTIONS_BY_ID_FAILURE';
    payload: any;
}

export type TResetSectionsById = {
    type: 'RESET_SECTIONS_BY_ID'
}

export type TSectionsByIdActionTypes = TSectionsByIdRequest | TSectionsByIdSuccess | TSectionsByIdFailure | TResetSectionsById;

// Fetch Existing Files
export const FETCH_EXISTING_FILES = 'FETCH_EXISTING_FILES';
export const FETCH_EXISTING_FILES_SUCCESS = 'FETCH_EXISTING_FILES_SUCCESS';
export const FETCH_EXISTING_FILES_FAILURE = 'FETCH_EXISTING_FILES_FAILURE';

export type TReviewScreenState = {
    existingFiles: TDataState<TFetchFileData[]>;
}

export type TFetchFiles = {
    type: 'FETCH_EXISTING_FILES',
    payload: TExistingFilesPayload
}

export type TFetchFilesSuccess = {
    type: 'FETCH_EXISTING_FILES_SUCCESS',
    payload: TFetchFilesResponse
}

export type TFetchFilesFailure = {
    type: 'FETCH_EXISTING_FILES_FAILURE',
    payload: any
}

export type TFileActionTypes = TFetchFiles | TFetchFilesSuccess | TFetchFilesFailure;

export type TReviewScreenActionTypes = TSectionsByIdActionTypes | TFileActionTypes