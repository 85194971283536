import { call, put } from "redux-saga/effects";
import { fetchProjectTimelineService, fetchProjectTimelineServiceAverage, generateProjectVisualsService, saveProjectTimelineService } from "../../services/DmapPhase0Services/ProjectPlan.service";
import { fetchAvg, fetchProjectTimelineFailure, fetchProjectTimelineSuccess, generateVisualsFailure, generateVisualsSuccess, saveTimelineReportFailure, saveTimelineReportSuccess, updateProjectTimeline, updateProjectTimelineError } from "../DmapPhase0Actions/ProjectPlan.actions";
import { TPlanEstimates, TProjectAvgResponse, TSaveProjectTimelineResponse } from "../../models/ProjectPlan.model";
import { TProjectTimelineRequest, TProjectVisualsRequest, TSaveProjectTimelinesRequest } from "../DmapPhase0State/ProjectPlan.state";


export function* fetchProjectTimelineDataSaga(action: TProjectTimelineRequest): Generator<any, void, any> {
  try {
    const data = yield call(fetchProjectTimelineService, action.payload);
    yield put(fetchProjectTimelineSuccess(data));
  } catch (error) {
    yield put(fetchProjectTimelineFailure(error));
  }
}

export function* fetchProjectTimelineDataSagaAverage(action: ReturnType<typeof fetchAvg>): Generator<any, void, any> {
  try {
    const data: TProjectAvgResponse = yield call(fetchProjectTimelineServiceAverage, action.payload);
    yield put(updateProjectTimeline(data));
  } catch (error) {
    yield put(updateProjectTimelineError(error));
  }
}

export function* saveProjectTimelineReportSaga(action: TSaveProjectTimelinesRequest): Generator<any, void, any> {
  try {
    const data: TSaveProjectTimelineResponse = yield call(saveProjectTimelineService, action.payload);
    yield put(saveTimelineReportSuccess(data));
  } catch (error) {
    yield put(saveTimelineReportFailure(error));
  }
}


export function* generateProjectVisualsSaga(action: TProjectVisualsRequest): Generator<any, void, any> {
  try {
    const data: TPlanEstimates[] = yield call(generateProjectVisualsService, action.payload);
    yield put(generateVisualsSuccess(data));
  } catch (error) {
    yield put(generateVisualsFailure(error));
  }
}