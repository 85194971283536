import React from 'react'
import './PainPoints.less'
import RichTextEditor from '../../../../../Components/RichTextEditor/RichTextEditor'
import { TRootState, TSubSectionDataResponse, TSubSectionResponse } from '../../../../../libs/store/DmapPhase0State/LandingPageState'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { TWorkshopEnablersTableCols } from '../../../../../libs/store/DmapPhase0State/WorkshopEnablers.state'

interface Props{
    selectedSubsection: string

    data?: TSubSectionDataResponse<TWorkshopEnablersTableCols> | null;
    loading?: boolean; 
}

const PainPoints: React.FC<Props> = ({data, selectedSubsection, loading}) => {
  return (
    <div className={`text-editor-container`}>
      <RichTextEditor editorText={data?.response || ''} editable={false} selectedSubsection={selectedSubsection} loading={loading ? 'Fetching Data...' : undefined}/>
    </div>
  )
}

const mapStateToProps = (state: TRootState, {selectedSubsection}: Props) => ({
  data: state.workshopEnablers?.data?.find((details: TSubSectionResponse<TWorkshopEnablersTableCols>) => details.subsection === selectedSubsection )?.data,
  dataLoading: state.workshopEnablers?.loading
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PainPoints);
