import { TDataState, TSubSectionResponse } from '../DmapPhase0State/LandingPageState';
import {
  DOWNLOAD_FILE_FAILURE,
  DOWNLOAD_FILE_SUCCESS,
  REQUEST_DOWNLOAD_FILE,
  REQUEST_WORKSHOP_ENABLERS,
  REQUEST_WORKSHOP_ENABLERS_SUBSECTION,
  RESET_DOWNLOAD_FILE_DATA,
  TDownloadFileResponse,
  TWorkshopEnablersTableCols,
  WORKSHOP_ENABLERS_FAILURE,
  WORKSHOP_ENABLERS_SUBSECTION_FAILURE,
  WORKSHOP_ENABLERS_SUBSECTION_SUCCESS,
  WORKSHOP_ENABLERS_SUCCESS,
  WorkshopEnablersActionTypes,
} from '../DmapPhase0State/WorkshopEnablers.state';

const initialState: TDataState<TSubSectionResponse<TWorkshopEnablersTableCols>[]> & {fileData: TDataState<TDownloadFileResponse>} = {
  data: null,
  error: null,
  loading: false,
  fileData: {
    data: null,
    error: null,
    loading: false
  }
};

export const workshopEnablersReducer = (
  state = initialState,
  action: WorkshopEnablersActionTypes,
): TDataState<TSubSectionResponse<TWorkshopEnablersTableCols>[]> & {fileData: TDataState<TDownloadFileResponse>} => {
  switch (action.type) {
    case REQUEST_DOWNLOAD_FILE:
      return {
        ...state,
        fileData: {
          data: null,
          loading: true,
          error: null
        }
      };
    case DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        fileData: {
          data: action.payload,
          error: null,
          loading: false
        }
        
      };
    case DOWNLOAD_FILE_FAILURE:
      return {
        ...state,
        fileData: {
          data: null,
          loading: false,
          error: action.payload
        }
      };
    case RESET_DOWNLOAD_FILE_DATA:
      return {
        ...state,
        fileData: {
          data: null,
          loading: false,
          error: null
        }
      }
    case REQUEST_WORKSHOP_ENABLERS:
      return {
        ...state,
        loading: true,
      };
    case WORKSHOP_ENABLERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case WORKSHOP_ENABLERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REQUEST_WORKSHOP_ENABLERS_SUBSECTION:
      return {
        ...state,
        data: state.data?.filter((val: TSubSectionResponse<TWorkshopEnablersTableCols>) => val.subsection !== action.payload?.body?.subsection) || [],
        loading: true,
      };
    case WORKSHOP_ENABLERS_SUBSECTION_SUCCESS:
      const newData = action.payload;
      return {
        ...state,
        loading: false,
        data: [
          ...(state.data?.filter((val: TSubSectionResponse<TWorkshopEnablersTableCols>) => val.subsection !== newData.subsection) || []),
          newData
        ],
      };
    case WORKSHOP_ENABLERS_SUBSECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
