import { TFetchSubSectionPayload, TSectionPayload, TSubSectionResponse } from "../DmapPhase0State/LandingPageState";
import { DOWNLOAD_FILE_FAILURE, DOWNLOAD_FILE_SUCCESS, REQUEST_DOWNLOAD_FILE, REQUEST_WORKSHOP_ENABLERS, REQUEST_WORKSHOP_ENABLERS_SUBSECTION, RESET_DOWNLOAD_FILE_DATA, TDownloadFileResponse, TDownloadFileSuccess, TRequestDownloadFile, TResetDownloadFileData, TWorkshopEnablersFailure, TWorkshopEnablersRequest, TWorkshopEnablersSubSectionFailure, TWorkshopEnablersSubSectionRequest, TWorkshopEnablersSubSectionSuccess, TWorkshopEnablersSuccess, WORKSHOP_ENABLERS_FAILURE, WORKSHOP_ENABLERS_SUCCESS, WORKSHOP_ENABLERS_SUBSECTION_FAILURE, WORKSHOP_ENABLERS_SUBSECTION_SUCCESS, TWorkshopEnablersTableCols } from "../DmapPhase0State/WorkshopEnablers.state";

export const requestDownloadFile = (requestId: string, fileName: string): TRequestDownloadFile =>
({
  type: REQUEST_DOWNLOAD_FILE,
  payload: { requestId, fileName },
});

export const downloadFileSuccess = (data: TDownloadFileResponse): TDownloadFileSuccess =>
({
  type: DOWNLOAD_FILE_SUCCESS,
  payload: data,
});

export const downloadFileFailure = (error: any) =>
({
  type: DOWNLOAD_FILE_FAILURE,
  payload: error,
});

export const resetDownloadFileData = (): TResetDownloadFileData =>
  ({
    type: RESET_DOWNLOAD_FILE_DATA,
  });

export const fetchWorkshopEnablers = (payload: TSectionPayload): TWorkshopEnablersRequest => ({
  type: REQUEST_WORKSHOP_ENABLERS,
  payload
});

export const fetchWorkshopEnablersSuccess = (data: TSubSectionResponse<TWorkshopEnablersTableCols>[]): TWorkshopEnablersSuccess => ({
  type: WORKSHOP_ENABLERS_SUCCESS,
  payload: data,
});

export const fetchWorkshopEnablersError = (error: any): TWorkshopEnablersFailure => ({
  type: WORKSHOP_ENABLERS_FAILURE,
  payload: error,
});

export const fetchWorkshopEnablersSubSection = (payload: TFetchSubSectionPayload): TWorkshopEnablersSubSectionRequest => ({
  type: REQUEST_WORKSHOP_ENABLERS_SUBSECTION,
  payload
});

export const fetchWorkshopEnablersSubSectionSuccess = (data: TSubSectionResponse<TWorkshopEnablersTableCols>): TWorkshopEnablersSubSectionSuccess => ({
  type: WORKSHOP_ENABLERS_SUBSECTION_SUCCESS,
  payload: data,
});

export const fetchWorkshopEnablersSubSectionError = (error: any): TWorkshopEnablersSubSectionFailure => ({
  type: WORKSHOP_ENABLERS_SUBSECTION_FAILURE,
  payload: error,
});
