import { FormInstance } from "antd";
import { DOC_FILE_TYPE, DOC_PLCEHOLDERS } from "../libs/constants/NewRqst.const";
import { TCheckBoxGroup, TCheckBoxGroupSubValue, TFormField } from "../libs/models/NewRqst.model";
import { ModifyHeader, RegeneratePayload, SaveHeadersPayload, SaveModifiedHeader, Section, TFetchFileData, TFetchFilesResponse, TGenerateSectionPayload } from "../libs/models/ReviewScreenModels";
import { SECTIONS_FIELD } from "../libs/constants/ReviewScreen.const";
import { DEFAULT_PAGE_SIZE } from "../constants";
import { TSectionDetails, TSubsectionDetails } from "../libs/models/landingPageModels";

export const getPayloadForGenerateSection = (rqstId: number , data: Record<string, any>): TGenerateSectionPayload => {
    const sections: Section[] = []
    
    Object.entries(data)?.map((values: [key: string, value: boolean | string[]]) => {
        if (typeof values[1] === 'boolean' && values[1]){
            sections.push({section: values[0], subSection: []})
        }
        else if(Array.isArray(values[1]) && values[1]?.length){
            sections.push({section: values[0], subSection: values[1]})
        }
    })

    return {
        requestId: rqstId || -1,
        sections
    }
}

export const getGenerateModalErrMsg = (status: string, btnDisableConditions: string[], formValidated: boolean, selectedSections: Record<string, any>): string => {
    // Return error if request is pending or in progress
    if(btnDisableConditions.includes(status)){
        return 'Please wait for the request to complete !!!'
    }
    if(formValidated && !isGenSectionFormValid(selectedSections)){
        return 'Please select any section to generate !!!'
    }
    return ''
}

export const getGenerateSectionForm = (sections: TSectionDetails[]): TFormField[] => {
    return (
      sections?.map(
        (section: TSectionDetails): TFormField => ({
          id: section.sectionConstant,
          type: 'checkbox-group',
          name: section.sectionConstant || '',
          key: section.sectionConstant,
          canRemove: false,
          placeholderText: section.section || '',
          classes: 'checkbox-container',
          showArrow: (section.subsections?.length || 0) > 0,
        }),
      ) || []
    );
  };

  export const getGenerateSectionOptions = (sections: TSectionDetails[]): Record<string, TCheckBoxGroup> => {
    const dataObj: Record<string, TCheckBoxGroup> = {};
    sections?.map((section: TSectionDetails) => {
      dataObj[section.sectionConstant] = {
        disabled: !section.generated,
        subValues:
          section.subsections?.map((subSection: TSubsectionDetails, index: number) => ({
            disabled: false,
            value: subSection.subSection || '',
          })) || [],
      };
    });

    return dataObj;
  }

export const isGenSectionFormValid = (data: Record<string, any>): boolean => {
    if(!Object.values(data)?.length){
        return false;
    }
    const fieldsValid = Object.values(data)?.map((value: boolean | string[]) => {
        if (typeof value === 'boolean' && value){
            return true;
        }
        else if(Array.isArray(value) && value?.length){
            return true;
        }
    })
    return fieldsValid.includes(true);
}


export const getRegenerateSectionHeaderOptions = (headers: ModifyHeader[]): Record<string, TCheckBoxGroup> => {
    const dataObj: Record<string, TCheckBoxGroup> = {};
    if(!headers?.length){
        return dataObj;
    }

    dataObj['headers'] = {
        disabled: false,
        subValues: headers?.map((header: any) => 
            ({
                value: header?.businessProcess,
                disabled: false
            })) || []
    }
      return dataObj;
}

export const getSectionDetailsBySectionConstant = (selectedSection: string, headers: TSectionDetails[]): TSectionDetails | undefined => (
    headers?.find((section: TSectionDetails) => section.sectionConstant === selectedSection)
)

export const isRegenerateBtnDisabled = (loading: boolean, selectedSection: string, headers: TSectionDetails[]): boolean => {
    if(!selectedSection){
        return false
    }
    if(loading){
        return true;
    }
    const subSections: TSubsectionDetails[] = getSectionDetailsBySectionConstant(selectedSection, headers)?.subsections || []
    return subSections?.length > 0 ? false : true;
}

export const getPayloadForRegenerateHeaders = (request_id: string, formValues: Record<string, string>, headers: TSectionDetails[]): RegeneratePayload => {
    const sectionDetails: string[] = headers?.find(
        (item: any) => item.sectionConstant === formValues?.sections,
      )?.subsections?.map((subSection: TSubsectionDetails) => subSection.subSection as string) || [];

      return {
        request_id,
        section: formValues?.sections,
        userPrompt: formValues.promptarea,
        docFlag: false,
        headersList: sectionDetails ,
      };
}

export const getPayloadForRegenerateSubmit = (request_id: string, selectedSection: string, allHeaders: ModifyHeader[], selectedHeaders: string[]): SaveHeadersPayload => {
    const filteredHeaders: ModifyHeader[] =  allHeaders.filter((item: ModifyHeader) =>
        selectedHeaders?.includes(item.businessProcess),
      ) || [];
  
    const subSections: SaveModifiedHeader[] = filteredHeaders?.map((header: ModifyHeader) => ({
        ...(header || {}),
        isGenerated: 'false',
      })) || [];

      return {
        request_id,
        section: selectedSection,
        subSections,
      };
}

export const getRegenerateModalErrMsg = (selectedSection: string, headers: TSectionDetails[]): string => {
    if(!selectedSection){
        return ''
    }
    else{
        if((getSectionDetailsBySectionConstant(selectedSection, headers)?.subsections?.length || -1) > 0 ){
            return ''
        }
        else{
            return "Can't regenerate as no sub-sections present for the selected section !!!"
        }
    }
}

export const getFieldNameForUpdateFile = (type: string, index: number): string => {
    if(!type){
        return '';
    }
    if(type?.toLowerCase() === 'rfp'){
        return `${type?.toLowerCase()}Report-${index}`
    }
    else{
        return `${type?.toLowerCase()}Report`
    }
}

export const getFieldsConfigForUpdateFiles = (fieldsData: TFetchFilesResponse): TFormField[] => {
    const uploadFields: TFormField[] = fieldsData?.files?.map((data: TFetchFileData, index: number) => ({
        id: `update-${data.filetype}`,
        name:getFieldNameForUpdateFile(data.filetype, index),
        key: data.filetype,
        type: "upload",
         canRemove: false,
         forUpdateValue: true,
         accept: DOC_FILE_TYPE?.[data.filetype as keyof typeof DOC_FILE_TYPE] || '',
         placeholderText: DOC_PLCEHOLDERS?.[data.filetype as keyof typeof DOC_FILE_TYPE],
         uploadCount: 1,
         rules: [
             {
                 required: true,
                 message: `Please ${DOC_PLCEHOLDERS?.[data.filetype as keyof typeof DOC_PLCEHOLDERS] || ''}`
             }
         ]
     })) || [];
    return [
        ...uploadFields,
        SECTIONS_FIELD
    ]
}

export const getPayloadForFileUpdate = (fields: TFormField[], form: FormInstance) => {
    const fileFields = fields.filter((field: TFormField) => (field.type === 'upload' && form.getFieldValue(field.name)?.[0]?.originFileObj?.name));
    const prefixes = (fileFields?.map((field: TFormField) => {
      if(field.key === 'RFP'){
        return `RFP_${form.getFieldValue(field.name)?.[0]?.originFileObj?.name?.split('.')?.[0]}`
      }
      return field.key;
    })) || []
    const files = fileFields?.map((field: TFormField) => form.getFieldValue(field.name)?.[0]?.originFileObj) ?? []
  
    return {
      prefixes,
      files
    }
}

export const getStatusColor = (status: string): string => {
    switch (status.toLowerCase()) {
      case 'completed':
        return '#009E23';
      case 'in progress':
        return '#FCCB1D';
      case 'pending':
        return '#B7B7B7';
      case 'failed':
        return '#B30C0C';
      case 'partially failed':
        return '#C5511A';
      default:
        return 'blue';
    }
  };
  
export const hasPagination = (totalCount?: number, pageSize?: number): boolean => {
    return (totalCount || -1) > (pageSize || DEFAULT_PAGE_SIZE);
}
