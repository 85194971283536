import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import CustomTable from 'Components/DynamicForm/components/CustomTable/CustomTable';
import SelectSubsection from 'Components/SelectSubsection/SelectSubsection';
import { TSectionDetails } from 'libs/models/landingPageModels';
import { TProcessMaturityTable } from 'libs/models/ProcessMaturity.model';
import {
  fetchProcessMaturity,
  fetchProcessMaturitySubSection,
} from 'libs/store/DmapPhase0Actions/ProcessMaturity.actions';
import {
  TFetchSubSectionPayload,
  TRootState,
  TSubSectionDataResponse,
  TSubSectionResponse,
} from 'libs/store/DmapPhase0State/LandingPageState';
import {
  TProcessMaturityRequest,
  TProcessMaturitySubSectionRequest,
} from 'libs/store/DmapPhase0State/ProcessMaturity.state';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { hasPagination } from 'utils/ReviewScreen.util';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import './ProcessMaturity.less';

interface Props {
  selectedItem: string;
  onSelectedMenuItem: string;
  sectionDetails?: TSectionDetails | null;
  processMaturityData?: TSubSectionDataResponse<TProcessMaturityTable> | null;
  dataLoading?: boolean | undefined;
  fetchProcessMaturityData?: (
    reqId: string,
    selectedSection: string,
    pageSize: number,
  ) => TProcessMaturityRequest;
  fetchProcessMaturitySubSectionData?: (
    payload: TFetchSubSectionPayload,
  ) => TProcessMaturitySubSectionRequest;
}

const ProcessMaturity: React.FC<Props> = ({
  selectedItem,
  onSelectedMenuItem,
  sectionDetails,
  processMaturityData,
  dataLoading,
  fetchProcessMaturityData,
  fetchProcessMaturitySubSectionData,
}) => {
  const { id }: { id: string } = useParams();

  useEffect(() => {
    if (id) {
      fetchProcessMaturityData?.(id, selectedItem, 10);
    }
  }, [id]);

  useEffect(() => {
    if (processMaturityData?.filtered && id) fetchProcessMaturityData?.(id, selectedItem, 10);
  }, [onSelectedMenuItem]);

  const onChange = (
    paginationConfig: TablePaginationConfig,
    selectedFilters: Record<string, FilterValue | null>,
  ) => {
    fetchProcessMaturitySubSectionData?.({
      requestId: id,
      body: {
        selectedFilters: Object.keys(selectedFilters)
          .filter((k) => selectedFilters[k] != null)
          .reduce((a, k) => ({ ...a, [k]: selectedFilters[k] }), {}),
        page: paginationConfig.current || -1,
        pageSize: paginationConfig.pageSize || DEFAULT_PAGE_SIZE,
        section: selectedItem,
        subsection: onSelectedMenuItem,
      },
    });
  };

  return sectionDetails?.subsections?.length && onSelectedMenuItem ? (
    <div className="process-maturity-container">
      {processMaturityData?.response ? (
        <div className="response-text">{processMaturityData?.response}</div>
      ) : (
        <></>
      )}
      <div className="process-table">
        <CustomTable
          loading={dataLoading || false}
          key={onSelectedMenuItem}
          data={processMaturityData?.table?.content || []}
          tableName="process-maturity"
          filterCols={processMaturityData?.table?.filters}
          pageSize={processMaturityData?.table?.pageSize || 5}
          totalCount={processMaturityData?.table?.totalCount}
          onChange={onChange}
          colNames={
            processMaturityData?.table?.colNames?.filter((key: string) => key !== 'show') || []
          }
          pagination={true}
          pageNumber={processMaturityData?.table?.pageNumber}
          scrollProps={{
            x: 'none',
            y: `calc(100vh - ${
              hasPagination(
                processMaturityData?.table?.totalCount,
                processMaturityData?.table?.pageSize,
              )
                ? '17.765rem'
                : '15.165rem'
            })`,
          }}
        />
      </div>
    </div>
  ) : (
    <SelectSubsection />
  );
};

const mapStateToProps = (state: TRootState, { onSelectedMenuItem, selectedItem }: Props) => ({
  dataLoading: state.processMaturity?.loading,
  sectionDetails: state.sectionsById?.data?.find(
    (section: TSectionDetails) => section.sectionConstant === selectedItem,
  ),
  processMaturityData: state.processMaturity?.data?.find(
    (item: TSubSectionResponse<TProcessMaturityTable>) => item.subsection === onSelectedMenuItem,
  )?.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchProcessMaturityData: (reqId: string, selectedSection: string, pageSize: number) =>
    dispatch(fetchProcessMaturity({ reqId, selectedSection, pageSize })),
  fetchProcessMaturitySubSectionData: (payload: TFetchSubSectionPayload) =>
    dispatch(fetchProcessMaturitySubSection(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessMaturity);
