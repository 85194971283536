import { AxiosError } from 'axios';
import { TArchFailure, TArchGenericResponse, TArchImgPayload, TArchImgResponse, TArchSuccess, TArchTableData, TGenerateDiagramPayload, TResetArchSubsectionPayload, TUploadFilesPayload } from '../../models/Architecture.model';
import { TDataState, TFetchSubSectionPayload, TSectionPayload, TSubSectionResponse } from './LandingPageState';
import { TExistingFilesPayload, TFetchFileData, TFetchFilesResponse } from '../../models/ReviewScreenModels';

export const GET_ARCHITECTURE_DETAILS = 'GET_ARCHITECTURE_DETAILS';
export const GET_ARCHITECTURE_DETAILS_SUCCESS = 'GET_ARCHITECTURE_DETAILS_SUCCESS';
export const GET_ARCHITECTURE_DETAILS_ERROR = 'GET_ARCHITECTURE_DETAILS_ERROR';
export const GET_ARCHITECTURE_IMAGE = 'GET_ARCHITECTURE_IMAGE';
export const GET_ARCHITECTURE_IMAGE_SUCCESS = 'GET_ARCHITECTURE_IMAGE_SUCCESS';
export const GET_ARCHITECTURE_IMAGE_ERROR = 'GET_ARCHITECTURE_IMAGE_ERROR';
export const REQUEST_ARCHITECTURE_SUBSECTION = 'REQUEST_ARCHITECTURE_SUBSECTION';
export const ARCHITECTURE_SUBSECTION_SUCCESS = 'ARCHITECTURE_SUBSECTION_SUCCESS';
export const ARCHITECTURE_SUBSECTION_FAILURE = 'ARCHITECTURE_SUBSECTION_FAILURE';
export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
export const FETCH_FILES_REQUEST = 'FETCH_FILES_REQUEST';
export const FETCH_FILES_SUCCESS = 'FETCH_FILES_SUCCESS';
export const FETCH_FILES_FAILURE = 'FETCH_FILES_FAILURE';
export const GENERATE_DIAGRAM_REQUEST = 'GENERATE_DIAGRAM_REQUEST';
export const GENERATE_DIAGRAM_SUCCESS = 'GENERATE_DIAGRAM_SUCCESS';
export const GENERATE_DIAGRAM_FAILURE = 'GENERATE_DIAGRAM_FAILURE';
export const RESET_ARCHITECTURE_SUBSECTION = 'RESET_ARCHITECTURE_SUBSECTION';

export type TAsIsArchitectureState = {
  response?: TDataState<TSubSectionResponse<TArchTableData>[]> | null;
  image?: TDataState<TArchImgResponse> | null;
}

export type TToBeArchitectureState = {
  uploadFile?: TDataState<TArchGenericResponse> | null;
  existingFiles?: TDataState<TFetchFileData[]> | null;
  generateDiagram?: TDataState<TArchGenericResponse> | null;
  response?: TDataState<TSubSectionResponse<TArchTableData>[]> | null;
  image?: TDataState<TArchImgResponse> | null;
  save?: TDataState<any> | null;
}

export type TArchitectureState = {
  asIsArchitecture: TAsIsArchitectureState | null;
  toBeArchitecture: TToBeArchitectureState | null;
}

export type TgetArchDetails = {
  type: 'GET_ARCHITECTURE_DETAILS';
  payload: TSectionPayload;
};

export type TgetArchDetailsSuccess = {
  type: 'GET_ARCHITECTURE_DETAILS_SUCCESS';
  payload: TArchSuccess<TSubSectionResponse<TArchTableData>[]>;
};

export type TgetArchDetailsError = {
  type: 'GET_ARCHITECTURE_DETAILS_ERROR';
  payload: TArchFailure;
};

export type TgetArchImage = {
  type: 'GET_ARCHITECTURE_IMAGE';
  payload: TArchImgPayload;
};

export type TgetArchImageSuccess = {
  type: 'GET_ARCHITECTURE_IMAGE_SUCCESS';
  payload: TArchSuccess<TArchImgResponse>;
};

export type TgetArchImageError = {
  type: 'GET_ARCHITECTURE_IMAGE_ERROR';
  payload: TArchFailure;
};

export type TArchitectureSubSectionRequest = {
  type: 'REQUEST_ARCHITECTURE_SUBSECTION';
  payload: TFetchSubSectionPayload;
}

export type TArchitectureSubSectionSuccess = {
  type: 'ARCHITECTURE_SUBSECTION_SUCCESS';
  payload: TArchSuccess<TSubSectionResponse<TArchTableData>>;
}

export type TArchitectureSubSectionFailure = {
  type: 'ARCHITECTURE_SUBSECTION_FAILURE';
  payload: TArchFailure;
}

export type TUploadFileRequest = {
  type: 'UPLOAD_FILE_REQUEST';
  payload: TUploadFilesPayload;
}

export type TUploadFileSuccess = {
  type: 'UPLOAD_FILE_SUCCESS';
  payload: TArchSuccess<TArchGenericResponse>;
}

export type TUploadFileFailure = {
  type: 'UPLOAD_FILE_FAILURE';
  payload: TArchFailure;
}

export type TGenerateDiagramRequest = {
  type: 'GENERATE_DIAGRAM_REQUEST';
  payload: TGenerateDiagramPayload;
}

export type TGenerateDiagramSuccess = {
  type: 'GENERATE_DIAGRAM_SUCCESS';
  payload: TArchSuccess<TArchGenericResponse>;
}

export type TGenerateDiagramFailure = {
  type: 'GENERATE_DIAGRAM_FAILURE';
  payload: TArchFailure;
}

export type TFetchFilesRequest = {
  type: 'FETCH_FILES_REQUEST';
  payload: TExistingFilesPayload;
}

export type TFetchFilesSuccess = {
  type: 'FETCH_FILES_SUCCESS';
  payload: TArchSuccess<TFetchFileData[]>;
}

export type TFetchFilesFailure = {
  type: 'FETCH_FILES_FAILURE';
  payload: TArchFailure;
}

export type TResetArchSubsection = {
  type: 'RESET_ARCHITECTURE_SUBSECTION',
  payload: TResetArchSubsectionPayload
}

export type TgetArchDetailsActionTypes = TgetArchDetails | TgetArchDetailsSuccess | TgetArchDetailsError;
export type TgetArchImageActionTypes = TgetArchImage | TgetArchImageSuccess | TgetArchImageError;
export type TArchitectureSubSectionActionTypes = TArchitectureSubSectionRequest | TArchitectureSubSectionSuccess | TArchitectureSubSectionFailure;
export type TUploadFileActiontypes = TUploadFileRequest | TUploadFileSuccess | TUploadFileFailure;
export type TFetchFilesActionTypes = TFetchFilesRequest | TFetchFilesSuccess | TFetchFilesFailure;
export type TGenerateDiagramActionTypes = TGenerateDiagramRequest | TGenerateDiagramSuccess | TGenerateDiagramFailure;

export type ArchActionTypes =TgetArchDetailsActionTypes
  | TgetArchImageActionTypes
  | TArchitectureSubSectionActionTypes
  | TUploadFileActiontypes
  | TFetchFilesActionTypes
  | TGenerateDiagramActionTypes
  | TResetArchSubsection;
