import React from 'react';
import ReactQuill, { UnprivilegedEditor } from 'react-quill';
import { DeltaStatic, Sources, StringMap } from 'quill';
import SelectSubsection from '../SelectSubsection/SelectSubsection';
import { Spin } from 'antd';

interface Props {
  selectedSubsection: string;
  editorText: string;
  loading: string | undefined;
  editable: boolean;

  handleChange?: (content: string, delta: DeltaStatic, source: Sources, editor: UnprivilegedEditor) => void;
}

const RichTextEditor: React.FC<Props> = ({
  selectedSubsection,
  editorText,
  loading,
  editable,
  handleChange,
}) => {

  const modules: StringMap = {
    clipboard: {
      matchVisual: false,
    },
    toolbar: editable ? [
      ['bold', 'italic'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ] : false,
  };
  
  const formats = ['header', 'height', 'bold', 'italic', 'list', 'bullet'];

  const renderLoader = () => {
    return (
      <Spin spinning tip={loading}>
        <ReactQuill theme="snow" modules={modules} formats={formats} value={editorText} />
      </Spin>
    );
  };

  return !Boolean(selectedSubsection) ? (
    <SelectSubsection />
  ) : loading ? (
    renderLoader()
  ) : (
    <ReactQuill
      theme="snow"
      modules={modules}
      formats={formats}
      value={editorText}
      readOnly={!editable}
      onChange={handleChange}
    />
  );
};

export default RichTextEditor;
