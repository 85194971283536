import { AxiosError } from "axios";
import { TSectionDetails } from "../../models/landingPageModels";
import { TExistingFilesPayload, TFetchFilesResponse } from "../../models/ReviewScreenModels";
import { FETCH_EXISTING_FILES, FETCH_EXISTING_FILES_FAILURE, FETCH_EXISTING_FILES_SUCCESS, RESET_SECTIONS_BY_ID, SECTIONS_BY_ID_FAILURE, SECTIONS_BY_ID_REQUEST, SECTIONS_BY_ID_SUCCESS, TFetchFiles, TFetchFilesFailure, TFetchFilesSuccess, TResetSectionsById, TSectionsByIdFailure, TSectionsByIdRequest, TSectionsByIdSuccess } from "../DmapPhase0State/ReviewScreen.state";

//All Sections By Request ID
export const fetchSectionsById = (requestId: number): TSectionsByIdRequest => ({
    type: SECTIONS_BY_ID_REQUEST,
    payload: { reqId: requestId }
});

export const fetchSectionsByIdSuccess = (data: TSectionDetails[]): TSectionsByIdSuccess => ({
    type: SECTIONS_BY_ID_SUCCESS,
    payload: data
});

export const fetchSectionsByIdFailure = (error: AxiosError): TSectionsByIdFailure => ({
    type: SECTIONS_BY_ID_FAILURE,
    payload: error
});

export const resetSectionsById = (): TResetSectionsById =>
({
    type: RESET_SECTIONS_BY_ID,
});

// Existing files
export const fetchExistingFiles = (payload: TExistingFilesPayload): TFetchFiles => ({
    type: FETCH_EXISTING_FILES,
    payload
});

export const fetchExistingFilesSuccess = (data: TFetchFilesResponse): TFetchFilesSuccess => ({
    type: FETCH_EXISTING_FILES_SUCCESS,
    payload: data,
});

export const fetchExistingFilesFailure = (error: any): TFetchFilesFailure => ({
    type: FETCH_EXISTING_FILES_FAILURE,
    payload: error,
});