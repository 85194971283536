import React, { useEffect, useState } from 'react';
import './FileUploadBtn.less';
import { Skeleton, Spin, Tooltip, Upload, UploadFile } from 'antd';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { formatBytes } from '../../utils/DynamicForm.util';
import { TDataState, TRootState } from '../../libs/store/DmapPhase0State/LandingPageState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { fetchFilesRequest, uploadFileRequest } from '../../libs/store/DmapPhase0Actions/Architecture.actions';
import { TArchGenericResponse, TUploadFilesPayload } from '../../libs/models/Architecture.model';
import { TFetchFilesRequest, TUploadFileRequest } from '../../libs/store/DmapPhase0State/Architecture.state';
import { useParams } from 'react-router';
import { TFetchFileData } from '../../libs/models/ReviewScreenModels';

interface Props {
  subSection: string,

  uploadFileDetails?: TDataState<TArchGenericResponse> | null;
  existingFiles?: TDataState<TFetchFileData[]> | null;

  fetchFiles?: (reqId: string) => TFetchFilesRequest;
  uploadFile?: (payload: TUploadFilesPayload) => TUploadFileRequest;
}

const FileUploadBtn: React.FC<Props> =({ subSection, uploadFileDetails, existingFiles, fetchFiles, uploadFile}) => {

  const { id }: { id: string } = useParams();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  useEffect(() => {
    fetchFiles && fetchFiles(id);
  }, []);

  useEffect(() => {
    if(uploadFileDetails?.data && !uploadFileDetails?.loading){
      fetchFiles && fetchFiles(id);
      setIsUploading(false);
    }
  }, [uploadFileDetails])

  const onFileUpload = (options: any) => {
    const {file } = options
    setIsUploading(true);

    uploadFile && uploadFile({
      files: [file],
      requestId: id,
      prefixes: 'Architecture',
      uploadType: 'Architecture',
      subsection: subSection
    })
  }

  const renderUploading = () => (
    <div className='uploading-container' >
      <span className='uploading-text'>Uploading the file</span>
      <Spin className='uploading-spinner' />
    </div>
  )

  const uploadComponent = (
    <Upload
      accept=".xlsx, .xls, .csv"
      fileList={[{fileName: existingFiles?.data?.[0]?.filename, size: existingFiles?.data?.[0]?.fileSize} as UploadFile]}
      maxCount={1}
      showUploadList={false}
      customRequest={onFileUpload}
    >
      <button className="download-btn" type="button">
        <div>
          <span className='upload-file-icon'>
            <UploadOutlined />
          </span>
          <span className='upload-file-text'>
            {existingFiles?.data?.length
              ? 'Re-Upload file for Architecture'
              : 'Upload file for Architecture '}
          </span>
        </div>
      </button>
    </Upload>
  );

  const tooltipText = (
    <div>Please upload .xls or .xlsx or .csv file only</div>
  );

  const uploadButton = (
    <>
      <div className="file-details">{uploadComponent}</div>
      <div className="download-btn" >
        <Tooltip placement='left' title= {tooltipText} >
          <InfoCircleOutlined className='info-icon'/>
        </Tooltip>
      </div>
    </>
  );
  
  const fileName = (
    <>
      <div className="file-details">
        <span className="file-name">{existingFiles?.data?.[0]?.filename}</span>
        {existingFiles?.data?.[0]?.fileSize && (
          <>
            <span className="divider">|</span>
            <span className="file-size">{formatBytes(existingFiles?.data?.[0]?.fileSize)}</span>
          </>
        )}
      </div>
      <div className="download-btn">
        {uploadComponent}
      </div>
    </>
  );

    return (
      <div className="file-upload-container">
        <div className="file-container">
          {isUploading ? renderUploading() : existingFiles?.data?.length ? fileName : uploadButton}
        </div>
      </div>
    );
};

const mapStateToProps = (state: TRootState) => ({
  existingFiles: state.architecture?.toBeArchitecture?.existingFiles,
  uploadFileDetails: state.architecture?.toBeArchitecture?.uploadFile
});

const mapDispatchToProps = (dispatch: Dispatch, { subSection }: Props) => ({
  fetchFiles: (reqId: string) => dispatch(fetchFilesRequest({reqId, fileName: 'To-Be Architecture.csv', subSection, folderType: '/output/Architecture/'})),
  uploadFile: (payload: TUploadFilesPayload) => dispatch(uploadFileRequest(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadBtn);
