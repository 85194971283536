/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Button } from 'antd';
import './LandingPage.less';
import { MailOutlined, BellOutlined } from '@ant-design/icons';
import CreateNewRequest from './CreateNewRequest/CreateNewRequest';
import SideNavBar from '../../Components/SideNavBar/SideNavBar';
import AllRequests from '../../Components/AllRequests/AllRequests';

const LandingPage = () => {
  const [formModalOpen, setFormModalOpen] = useState(false);

  const toggleCreationModal = (open: boolean) => {
    setFormModalOpen(open);
  };

  return (
      <div className="landing-content">
        <SideNavBar />
        <div className='main-content'>
          <div className="header">
            <div className="header-content">
              <span>
                <MailOutlined />
              </span>
              <span>
                <BellOutlined />
              </span>
            </div>
          </div>
          <div className="top-content">
            <div>
              <p className="landing-left-heading">
                <b>IANEG (Dummy Client)</b>
              </p>
            </div>

            <div className="create-button">
              <span>
                <Button className="antd-create-btn" onClick={() => toggleCreationModal(true)}>
                  Create New Request
                </Button>
                <CreateNewRequest
                  visible={formModalOpen}
                  onCancel={() => toggleCreationModal(false)}
                />
              </span>
            </div>
          </div>
          <div className="all-requests-container">
            <AllRequests />
          </div>
        </div>
      </div>
  );
};

export default LandingPage;
